import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var DownloadFinancesBar = React.memo(React.forwardRef(function DownloadFinancesBar(props, ref) {
  return React.createElement("div", {
    "className": "_2b3a3921 _c8629901 _pub-c8629901 _pub-a97d53f3",
    "ref": ref,
    "onClick": props["clickDownloadButton"],
    "data-testid": "download-financial-summary",
  }, 
    React.createElement("div", {
      "className": "_e3b7fefb _c8629901 _pub-c8629901 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_9d604cf0 _c8629901 _pub-c8629901 _pub-a97d53f3" + " " + "_c8629901_text-body-medium _pub-c8629901_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
      }, 
        React.createElement("svg", {
          "className": "_5bfb394c _c8629901 _pub-c8629901 _pub-a97d53f3",
          "width": "25",
          "height": "24",
          "viewBox": "0 0 25 24",
          "fill": "none",
          "xmlns": "http://www.w3.org/2000/svg",
        }, 
          React.createElement("path", {
            "className": "_44e3ab46 _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M5.46429 1H14.4065C14.7555 1 15.0936 1.12168 15.3625 1.3441L20.831 5.86674C21.1755 6.1517 21.375 6.57551 21.375 7.02264V20.8917C21.375 22.8613 21.352 23 19.2857 23H5.46429C3.398 23 3.375 22.8613 3.375 20.8917V3.10833C3.375 1.13874 3.398 1 5.46429 1Z",
            "fill": "#29303D",
          }, null),
          React.createElement("path", {
            "className": "_33e49bd0 _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M7.40575 11.0665C7.4777 11.0665 7.54411 11.1052 7.57965 11.1677L8.46404 12.7245H8.50053L9.38942 11.1674C9.42501 11.105 9.49131 11.0665 9.56311 11.0665H10.2132C10.3677 11.0665 10.4639 11.2343 10.3857 11.3676L9.13682 13.4988L10.4127 15.6283C10.4926 15.7616 10.3966 15.9311 10.2412 15.9311H9.5739C9.50242 15.9311 9.43638 15.893 9.40066 15.8311L8.50053 14.2708H8.46404L7.56391 15.8311C7.52819 15.893 7.46215 15.9311 7.39067 15.9311H6.72855C6.57302 15.9311 6.47701 15.7614 6.55713 15.6281L7.83687 13.4988L6.58025 11.3681C6.50162 11.2348 6.59773 11.0665 6.75252 11.0665H7.40575Z",
            "fill": "#677283",
          }, null),
          React.createElement("path", {
            "className": "_aaedca6a _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M11.3871 15.9311C11.2766 15.9311 11.1871 15.8416 11.1871 15.7311V11.2665C11.1871 11.1561 11.2766 11.0665 11.3871 11.0665H11.9746C12.085 11.0665 12.1746 11.1561 12.1746 11.2665V15.0831H13.977C14.0874 15.0831 14.177 15.1727 14.177 15.2831V15.7311C14.177 15.8416 14.0874 15.9311 13.977 15.9311H11.3871Z",
            "fill": "#677283",
          }, null),
          React.createElement("path", {
            "className": "_ddeafafc _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M17.5465 12.4656C17.4434 12.4656 17.3611 12.3853 17.3264 12.2882C17.2876 12.1795 17.222 12.0897 17.1298 12.019C16.9914 11.9129 16.8037 11.8599 16.5665 11.8599C16.4053 11.8599 16.2692 11.8836 16.1582 11.9311C16.0473 11.977 15.9621 12.0412 15.9028 12.1235C15.845 12.2059 15.8162 12.2993 15.8162 12.4038C15.8131 12.4909 15.8306 12.5669 15.8686 12.6318C15.9081 12.6968 15.9621 12.753 16.0305 12.8005C16.0989 12.8464 16.178 12.8868 16.2677 12.9216C16.3574 12.9549 16.4532 12.9834 16.5551 13.0071L16.9747 13.1116C17.1784 13.1591 17.3654 13.2225 17.5357 13.3017C17.706 13.3808 17.8535 13.4782 17.9782 13.5938C18.1028 13.7094 18.1994 13.8456 18.2678 14.0024C18.3377 14.1591 18.3735 14.3389 18.375 14.5416C18.3735 14.8393 18.3005 15.0974 18.1561 15.3159C18.0131 15.5329 17.8064 15.7015 17.5357 15.8219C17.2666 15.9406 16.942 16 16.5619 16C16.1849 16 15.8564 15.9398 15.5767 15.8195C15.2985 15.6991 15.081 15.521 14.9244 15.285C14.8005 15.0952 14.7236 14.8694 14.6939 14.6077C14.6812 14.4963 14.7726 14.4038 14.8848 14.4038H15.4486C15.554 14.4038 15.6381 14.4868 15.6657 14.5885C15.6885 14.6727 15.7235 14.7473 15.7705 14.8124C15.8511 14.92 15.9583 15.0016 16.0921 15.057C16.2274 15.1108 16.3802 15.1378 16.5505 15.1378C16.7178 15.1378 16.863 15.1124 16.9861 15.0618C17.1108 15.0111 17.2073 14.9406 17.2757 14.8504C17.3442 14.7601 17.3784 14.6564 17.3784 14.5392C17.3784 14.4299 17.3472 14.3381 17.2849 14.2637C17.224 14.1892 17.1343 14.1259 17.0158 14.0736C16.8987 14.0214 16.755 13.9739 16.5847 13.9311L16.0761 13.7981C15.6824 13.6983 15.3714 13.5424 15.1434 13.3302C14.9153 13.118 14.802 12.8321 14.8036 12.4727C14.802 12.1781 14.8773 11.9208 15.0293 11.7007C15.1829 11.4806 15.3935 11.3088 15.6611 11.1853C15.9287 11.0618 16.2327 11 16.5733 11C16.92 11 17.2225 11.0618 17.481 11.1853C17.741 11.3088 17.9432 11.4806 18.0876 11.7007C18.1974 11.868 18.2668 12.0555 18.2958 12.2631C18.3112 12.3737 18.2197 12.4656 18.108 12.4656H17.5465Z",
            "fill": "#677283",
          }, null)
        ),
        React.createElement("div", {
          "className": "_c2f268f6 _c8629901 _pub-c8629901 _pub-a97d53f3",
        }, 
          props["companyName"]
        )
      ),
      React.createElement("div", {
        "className": "_4691d4a _c8629901 _pub-c8629901 _pub-a97d53f3" + " " + "_c8629901_text-body-medium _pub-c8629901_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
      }, 
        React.createElement("svg", {
          "className": "_c0b4d6af _c8629901 _pub-c8629901 _pub-a97d53f3" + " " + "_c8629901_download-icon _pub-c8629901_download-icon _pub-a97d53f3_download-icon download-icon",
          "width": "25",
          "height": "24",
          "viewBox": "0 0 25 24",
          "fill": "none",
          "xmlns": "http://www.w3.org/2000/svg",
        }, 
          React.createElement("path", {
            "className": "_1056fcf _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M21.3751 15V19C21.3751 19.5304 21.1644 20.0391 20.7893 20.4142C20.4143 20.7893 19.9056 21 19.3751 21H5.37512C4.84469 21 4.33598 20.7893 3.96091 20.4142C3.58584 20.0391 3.37512 19.5304 3.37512 19V15",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null),
          React.createElement("path", {
            "className": "_76025f59 _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M7.37512 10L12.3751 15L17.3751 10",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null),
          React.createElement("path", {
            "className": "_ef0b0ee3 _c8629901 _pub-c8629901 _pub-a97d53f3",
            "d": "M12.3751 15V3",
            "stroke": "#7E899A",
            "strokeWidth": "2",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
          }, null)
        )
      )
    ),
    props["isDownloadingFile"]&&React.createElement("div", {
      "className": "_f57a1088 _c8629901 _pub-c8629901 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_a9c78fe1 _c8629901 _pub-c8629901 _pub-a97d53f3" + " " + "_c8629901_line-progress-bar _pub-c8629901_line-progress-bar _pub-a97d53f3_line-progress-bar line-progress-bar",
      }, null),
      React.createElement("div", {
        "className": "_dec0bf77 _c8629901 _pub-c8629901 _pub-a97d53f3" + " " + "_c8629901_text-body-medium _pub-c8629901_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
      }, 
        "Preparing data...\n    "
        
      )
    )
  )
}));
export { DownloadFinancesBar };


