import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { CreditBoxRow } from './CreditBoxRow';
import {
  currency,
  decimal,
  percentage,
} from '../../FinancialInsights/FinancialInsightsDocument/utils';
import { CreditBoxTableProps } from '../../FinancialInsights/FinancialInsightsDocument/IcmEvaluation/CreditBoxTable';

export const CreditBoxTable = ({
  creditBoxes,
  metrics,
}: CreditBoxTableProps) => {
  const {
    arr,
    ebitda,
    revenueGrowth,
    ebitdaMargin,
    ebitdaMarginGrowth,
    runway,
    zoominfoIndustry,
  } = metrics;

  return (
    <section className="p-4 md:py-8 container mx-auto overflow-x-auto">
      <table className="table-fixed">
        <thead>
          <tr>
            <th scope="col" className="bg-[#0A168E] p-2 w-56"></th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                Revenue
              </Text>
            </th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                EBITDA
              </Text>
            </th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                Growth
              </Text>
            </th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                EBITDA margin
              </Text>
            </th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                EBITDA margin expansion
              </Text>
            </th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                Runway
              </Text>
            </th>
            <th scope="col" className="bg-[#0A168E] p-2 w-36">
              <Text type={TextType.BODY_M} className="text-white text-left">
                Industry
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="col" className="bg-[#ECF1FE] p-2">
              <Text
                type={TextType.BODY_M}
                className="bold text-black text-left"
              >
                Company Data
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {arr === null || isNaN(arr) ? '-' : currency.format(arr)}
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {ebitda === null || isNaN(ebitda)
                  ? '-'
                  : currency.format(ebitda)}
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {percentage.format(revenueGrowth)}
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {ebitdaMargin === null || isNaN(ebitdaMargin)
                  ? '-'
                  : percentage.format(ebitdaMargin)}
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {ebitdaMarginGrowth === null || isNaN(ebitdaMarginGrowth)
                  ? '-'
                  : percentage.format(ebitdaMarginGrowth)}
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {runway === null || isNaN(runway)
                  ? '-'
                  : decimal.format(runway)}
              </Text>
            </td>
            <td scope="col" className="p-2">
              <Text type={TextType.BODY_M} className="text-black text-left">
                {zoominfoIndustry}
              </Text>
            </td>
          </tr>
          {Object.entries(creditBoxes).map(([name, creditBox]) => {
            return (
              <CreditBoxRow key={name} label={name} creditBox={creditBox} />
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
