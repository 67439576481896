import React, { memo, useEffect, useState } from 'react';
import { TextType, Text } from '@hum/ui-library';

export const ErrorBoundary = memo(() => {
  // Don't show for production
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  const [error, setError] = useState<Error | null>(null);

  const onClick = () => setError(null);

  useEffect(() => {
    const onError = (event: ErrorEvent) => setError(event.error);
    window.addEventListener('error', onError);
    return () => window.removeEventListener('error', onError);
  }, []);

  return (
    <>
      {error && (
        <div
          className="fixed w-full h-full z-[999999] bg-red-400 opacity-50"
          onClick={onClick}
        >
          <div className="absolute left-[50%] top-[30%] translate-x-[-50%] translate-y-[-50%]">
            <Text type={TextType.BODY_L}>
              Whoopsie-daisy, looks like something went wrong!
            </Text>
            <div className="my-3 mx-0">
              <Text type={TextType.BODY_S}>
                {renderStack(String(error.stack))}
              </Text>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
ErrorBoundary.displayName = 'ErrorBoundary';

const renderStack = (stack: string) => {
  return stack.split('\n').map((line, i) => {
    return [<span key={i}>{line}</span>, <br key={`${i}-br`} />];
  });
};
