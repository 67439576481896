import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deleteConnector, disconnectConnector, useApiMutation } from '@hum/api';

import { confirm } from '@hum/common/src/modules/confirm';
import {
  AirbyteConnectorType,
  CodatConnectorType,
  Connector,
  ConnectorSource,
  ConnectorType,
} from '@hum/icm-app/src/state';
import {
  apiConnectorDisconnected,
  companyDashboardServiceStartDataPullButtonClicked,
} from '@hum/icm-app/src/actions';

import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { AVAILABLE_SERVICES } from '@hum/icm-app/src/pages/connectors/Integrations/Services';

// TODO: find if we can add type to serviceType
export const getServiceByType = (serviceType: string) => {
  return AVAILABLE_SERVICES.find((service) => service.type === serviceType);
};

export const getServiceByTypeAndSource = (
  type: CodatConnectorType | ConnectorType,
  source: ConnectorSource
) => {
  return AVAILABLE_SERVICES.find(
    (service) => service.type === type && service.source === source
  );
};

export const useDisconnectService = ({
  connector,
  displayName,
}: {
  connector: Connector;
  displayName?: string;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: deleteConnectorById } = useApiMutation(deleteConnector);
  const { mutateAsync: disconnectConnectorById } = useApiMutation(
    disconnectConnector
  );
  const message = `Are you sure you want to disconnect ${
    displayName || connector.name
  }?`;

  const handleDisconnectClick = useCallback(async () => {
    if (await confirm({ message, danger: true })) {
      setLoading(true);

      try {
        await disconnectConnectorById({ connectorId: connector.id });
      } catch (e) {
      } finally {
        await deleteConnectorById({ connectorId: connector.id });
      }

      // reload connectors
      dispatch(
        apiConnectorDisconnected({
          companyId: connector.companyId,
          connectorId: connector.id,
        })
      );

      setLoading(false);
    }
  }, [connector, loading]);

  return { handleDisconnectClick, loading };
};

export const useServiceDataPull = ({ connector }: { connector: Connector }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const flags = useFlags();
  const isAirbyteService =
    flags.enabled('show-airbyte') &&
    connector.connectorType in AirbyteConnectorType;

  const handleStartPullClick = async () => {
    setLoading(true);

    dispatch(
      companyDashboardServiceStartDataPullButtonClicked({
        companyId: connector.companyId,
        connectorId: connector.id,
        isAirbyteService, // if it's airbyte, it hits a different URL
      })
    );

    // reload connectors
    dispatch(
      apiConnectorDisconnected({
        companyId: connector.companyId,
        connectorId: connector.id,
      })
    );

    setLoading(false);
  };

  return { handleStartPullClick, loading };
};
