import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import {
  CRITERIA_VALUE_STYLES,
  NAME_TO_LABEL,
  CreditBoxProps,
} from '../../FinancialInsights/FinancialInsightsDocument/IcmEvaluation/CreditBoxRow';
import { Criteria } from '..';

export const CreditBoxRow = ({ label, creditBox }: CreditBoxProps) => {
  const {
    revenue,
    ebitda,
    growth,
    ebitdaMargin,
    ebitdaMarginExpansion,
    runway,
    industry,
  } = creditBox;

  return (
    <tr>
      <td scope="col" className="bg-[#ECF1FE] p-2">
        <Text type={TextType.BODY_M} className="bold text-black text-left">
          {NAME_TO_LABEL[label]}
        </Text>
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(revenue)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(revenue)]} />
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(ebitda)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(ebitda)]} />
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(growth)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(growth)]} />
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(ebitdaMargin)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(ebitdaMargin)]} />
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(ebitdaMarginExpansion)]
              .backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(ebitdaMarginExpansion)]} />
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(runway)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(runway)]} />
      </td>
      <td
        scope="col"
        className="p-2"
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(industry)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(industry)]} />
      </td>
    </tr>
  );
};
