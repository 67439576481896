import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@hum/icm-app/src/state';
import * as styles from './index.pc';

type DownloadFinancesProps = {
  companyName?: string;
  onClickDownloadButton: () => void;
};
export const DownloadFinances = ({
  companyName,
  onClickDownloadButton,
}: DownloadFinancesProps) => {
  const isDownloadingCompanyFinancialsFile = useSelector(
    (state: AppState) => state.isDownloadingCompanyFinancialsFile
  );
  return (
    <styles.DownloadFinancesBar
      companyName={
        companyName
          ? `${companyName} Company Financials`
          : 'Standardized financials and chartbook'
      }
      isDownloadingFile={isDownloadingCompanyFinancialsFile.status}
      clickDownloadButton={onClickDownloadButton}
    />
  );
};
