import { useEffect, useState } from 'react';

import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { CompanyContactStatus } from '@hum/types';

export type User = {
  id: string;
  name: string;
  email: string;
  status: CompanyContactStatus;
};

export type Company = {
  id: string;
  name: string;
};

export type Invite = {
  company: Company;
  user: User;
};

export const useInvite = (token: string) => {
  const [invite, setInvite] = useState<Invite>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getInvite = async () => {
    setLoading(true);

    try {
      const response = await backend.get(`invites/${token}`);
      if (response) {
        setInvite({
          user: normalize.user.in(response.user) as User,
          company: normalize.company.in(response.company) as Company,
        });
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvite();
    // eslint-disable-next-line
  }, [token]);

  return {
    loading,
    error,
    invite,
  };
};
