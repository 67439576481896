import { useEffect } from 'react';
import { principalInvestmentsHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

export const usePrincipalInvestments = (companyId: number) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    dispatch(principalInvestmentsHookUsed({ companyId }));
  }, [companyId]);

  return state.principalInvestments;
};
