import React from 'react';
import { Pagination as PaginationType } from '@hum/icm-app/src/state';
import * as styles from './styles.pc';
import { Toggle } from '@hum/legacy-ui';
import { Button, ButtonSize, ButtonType } from '@hum/ui-library';

type PageSection = number[];

const renderSection = (
  p: PageSection,
  currentPage: number,
  setPage: (page: string) => void
) => {
  if (p.length === 0) {
    return null;
  }

  const options = p.map((i) => ({ label: String(i), value: String(i) }));

  return (
    <Toggle
      value={String(currentPage)}
      options={options}
      onValueChange={setPage}
    />
  );
};

const generateRange = (start: number, end: number) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => i + start + 1);
};

// Generate middle section, filter out out of range numbers [1,pageCount], omit numbers from leading and trailing
const generateMain = (currentPage: number, pageCount: number) => {
  const mainSize = 5; // should be odd number

  const beforeCurrent = generateRange(
    currentPage - (mainSize + 1) / 2,
    currentPage - 1
  ).filter((i) => i > 1 && i < currentPage);
  const afterCurrent = generateRange(
    currentPage,
    currentPage + (mainSize - 1) / 2
  ).filter((i) => i < pageCount && i > currentPage);

  // Divide main section by half - 1, to get the size of each side that needs to be filled
  const beforeCurrentLacks = (mainSize - 1) / 2 - beforeCurrent.length;
  const afterCurrentLacks = (mainSize - 1) / 2 - afterCurrent.length;

  // Add extra numbers until there are
  const afterCurrentComplementRange = generateRange(
    afterCurrent[afterCurrent.length - 1],
    afterCurrent[afterCurrent.length - 1] + beforeCurrentLacks
  );
  const afterCurrentComplemented = [
    ...afterCurrent,
    ...afterCurrentComplementRange,
  ].filter((i) => i < pageCount);

  const beforeCurrentComplementRange = generateRange(
    beforeCurrent[0] - afterCurrentLacks - 1,
    beforeCurrent[0] - 1
  );
  const beforeCurrentComplemented = [
    ...beforeCurrentComplementRange,
    ...beforeCurrent,
  ].filter((i) => i > 1);

  if (currentPage === 1) {
    return [
      ...afterCurrentComplemented,
      afterCurrentComplemented[afterCurrentComplemented.length - 1] + 1,
    ];
  } else if (currentPage === pageCount) {
    return [beforeCurrentComplemented[0] - 1, ...beforeCurrentComplemented];
  } else {
    return [
      ...beforeCurrentComplemented,
      currentPage,
      ...afterCurrentComplemented,
    ];
  }
};

export const Pagination = ({
  pagination,
  setPage,
}: {
  pagination: PaginationType;
  setPage: (page: string) => void;
}) => {
  const { currentPage, pageCount } = pagination;
  const threshold = 7; // when to start breaking into multiple sections

  const leading = pageCount > threshold ? [1] : [];
  const trailing = pageCount > threshold ? [pageCount] : [];
  const main =
    pageCount > threshold
      ? generateMain(currentPage, pageCount)
      : [...Array(pageCount).keys()].map((i) => ++i);

  const zeroPagesButton =
    pageCount === 0 ? (
      <Button size={ButtonSize.SLIM} type={ButtonType.SECONDARY}>
        0
      </Button>
    ) : null;
  return (
    <>
      <styles.Container
        resultsText={
          pagination.results === 1
            ? `1 result`
            : `${pagination.results} results`
        }
      >
        {zeroPagesButton}
        {renderSection(leading, currentPage, setPage)}
        {renderSection(main, currentPage, setPage)}
        {renderSection(trailing, currentPage, setPage)}
      </styles.Container>
    </>
  );
};
