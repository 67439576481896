import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import { FileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import * as styles from './index.pc';
import { ModalContext } from '@hum/design-system';

type UploadProgressPopupProps = {
  fileManager: FileManager;
  hideUntilComplete?: boolean;
};

const CLOSE_TIMEOUT = 1000 * 3;

export const UploadProgressPopup = memo(
  ({
    fileManager,
    hideUntilComplete = false,
  }: UploadProgressPopupProps): any => {
    const [isOpen, setIsOpen] = useState(false);
    const uploadCount = Object.keys(fileManager.progress).reduce(
      (count, fileId) => {
        return count + (fileManager.progress[fileId] === 1 ? 0 : 1);
      },
      0
    );

    const modalMount = useContext(ModalContext);

    useEffect(() => {
      if (hideUntilComplete) {
        if (uploadCount !== 0) {
          setIsOpen(true);
        } else {
          setTimeout(() => {
            setIsOpen(false);
          }, CLOSE_TIMEOUT);
        }
      } else {
        if (uploadCount) {
          setIsOpen(true);
          setTimeout(() => {
            setIsOpen(false);
          }, CLOSE_TIMEOUT);
        }
      }
    }, [uploadCount]);

    const onCloseClick = useCallback(() => {
      setIsOpen(false);
    }, []);

    if (!modalMount) {
      return null;
    }

    return createPortal(
      <styles.UploadProgressPopup
        visible={isOpen}
        fixed
        onCloseClick={onCloseClick}
        label={
          uploadCount === 0
            ? `All items uploaded`
            : uploadCount === 1
            ? `Uploading 1 item`
            : `Uploading ${uploadCount} items`
        }
      >
        {/* Need to reverse map so that newer items show up at top */}
        {Object.keys(fileManager.progress)
          .reverse()
          .map((fileId) => {
            const progress = fileManager.progress[fileId];
            const file = fileManager.uploadingFiles[fileId] as File;
            return (
              <styles.Item fileName={file.name} progress={progress * 100} />
            );
          })}
      </styles.UploadProgressPopup>,
      modalMount
    );
  }
);

UploadProgressPopup.displayName = 'UploadProgressPopup';
