import React from 'react';
import { Text, Svg, Rect } from '@react-pdf/renderer';
import { tw, Color } from '../utils';
import { CRITERIA_VALUE_STYLES } from './CreditBoxRow';
import { Criteria, CriteriaProps } from './Criteria';
import { TD, TR, Table } from '@ag-media/react-pdf-table';

export const Legend = () => (
  <>
    <Text style={[tw('text-label', 'uppercase', 'ml-2')]}>Legend:</Text>
    <Table weightings={[0.2, 0.3, 0.2, 0.3]}>
      <TR>
        <TD style={tw('border-none')}>
          <Criteria {...(CRITERIA_VALUE_STYLES.true as CriteriaProps)} />
        </TD>
        <TD style={tw('border-none')}>
          <Text style={tw('text-body-xsmall')}>Meets criteria</Text>
        </TD>
        <TD style={tw('border-none')}>
          <Criteria {...(CRITERIA_VALUE_STYLES.false as CriteriaProps)} />
        </TD>
        <TD style={tw('border-none')}>
          <Text style={tw('text-body-xsmall')}>Doesn't meet criteria</Text>
        </TD>
      </TR>
      <TR style={{ alignItems: 'flex-start' }}>
        <TD style={[tw('border-none'), { transform: 'translateY(-70px)' }]}>
          <Criteria {...(CRITERIA_VALUE_STYLES.null as CriteriaProps)} />
        </TD>
        <TD style={tw('border-none')}>
          <Text style={tw('text-body-xsmall')}>
            Unable to calculate or missing data
          </Text>
        </TD>
        <TD style={tw('border-none')}>
          <Svg
            width={50}
            height={50}
            style={{
              position: 'relative',
              left: '40%',
            }}
          >
            <Rect x={0} y={0} width={50} height={50} fill={Color.Platinum} />
          </Svg>
        </TD>
        <TD style={tw('border-none')}>
          <Text style={tw('text-body-xsmall')}>Not applicable</Text>
        </TD>
      </TR>
    </Table>
  </>
);
