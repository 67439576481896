import { useEffect, useState } from 'react';
import Plotly, { Data, Layout } from 'plotly.js';

export type IUseChartDataUrl = {
  id: string;
  data: Data[];
  layout: Partial<Layout>;
  width: number;
  height: number;
  scale?: number;
  otherMarkers?: {
    x: any;
    y: any;
    marker: any;
    name?: string;
  };
};

export const useChartDataUrl = ({
  id,
  data,
  layout,
  width,
  height,
  scale = 1,
  otherMarkers,
}: IUseChartDataUrl): string | undefined => {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    const loadCharts = async () => {
      const chart = await Plotly.newPlot(id, data, layout, {
        displayModeBar: false,
      });
      if (otherMarkers) {
        await Plotly.addTraces(chart, {
          ...otherMarkers,
          type: 'scatter',
          mode: 'markers',
        });
      }
      const url = await Plotly.toImage(chart, {
        width,
        height,
        format: 'png',
        scale,
      });
      setSrc(url);
    };
    loadCharts();
  }, []);

  return src;
};
