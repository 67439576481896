import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { Button } from '@hum/ui-library';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="z-[1000] fixed top-4 right-5">
      <Transition
        show={isVisible}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <Button ref={buttonRef}>
          Download
          <ArrowDownTrayIcon width={20} className="ml-2" color="#FFF" />
        </Button>
      </Transition>
    </div>
  );
};

export default ScrollButton;
