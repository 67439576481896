import { useCallback, useEffect } from 'react';
import { useApiCall } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '../useFlags';
import {
  getNoticesWasSeen,
  setNoticeAsSeen,
  useApiMutation,
  useApiQuery,
} from '@hum/api';

export const useNotice = (
  noticeName?: string,
  companyId?: number
): {
  seen: boolean | null; // null if unknown as opposed to false
  loading: boolean;
  error: boolean;
  markAsSeen: () => void;
} => {
  const flags = useFlags();
  const { api, result, handleResponse, handleError } = useApiCall<boolean>();
  const { data: sagaData, error: sagaError, loading: sagaLoading } = result;
  const {
    data: getNotices,
    isLoading: noticeIsLoading,
    isError: noticeError,
  } = useApiQuery(getNoticesWasSeen, {
    params: {
      companyId,
      noticeName,
    },
  });
  const { mutate: noticeWasSeen } = useApiMutation(setNoticeAsSeen);

  const loading = flags.enabled('use-react-query')
    ? noticeIsLoading
    : sagaLoading;
  const error = flags.enabled('use-react-query') ? noticeError : sagaError;
  const data = flags.enabled('use-react-query') ? getNotices : sagaData;

  useEffect(() => {
    if (flags.enabled('use-react-query')) {
      if (companyId && noticeName) {
        api.notices
          .getNoticeWasSeen(companyId, noticeName)
          .then(handleResponse)
          .catch(handleError);
      }
    }
  }, [companyId, noticeName]);

  const markAsSeen = useCallback(async () => {
    if (!companyId || !noticeName) return;
    if (flags.enabled('use-react-query')) {
      return await noticeWasSeen({
        companyId,
        noticeName,
      });
    } else {
      return await api.notices.setNoticeAsSeen(companyId, noticeName);
    }
  }, [companyId, noticeName]);

  return {
    seen: loading || error ? null : data || false,
    error,
    loading: !companyId || !noticeName ? false : loading,
    markAsSeen,
  };
};
