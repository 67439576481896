import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CompanyChart } from '@hum/types';
import { AppState } from '@hum/icm-app/src/state';
import { apiSyndicationInsightsChartsStateChanged } from '@hum/icm-app/src/actions';
import { useApiCall } from '../useAPI';
import { createDataResult, createErrorResult } from '@hum/common';

export const useSyndicationInsightsCharts = (companyId: number) => {
  const { api } = useApiCall();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  // TODO: Update once data fetching architecture is re-evaluated. Hook is fetching data directly
  // circumnavigating saga, because the saga redux flow was broken. Since we are re-evaluating the
  // architecture, a simple implemenation was done.

  useEffect(() => {
    if (companyId) {
      if (!loading) setLoading(true);

      api.legacy
        .getSyndicationInsightsCharts(companyId, undefined)
        .then((res) => {
          dispatch(
            apiSyndicationInsightsChartsStateChanged({
              result: createDataResult<CompanyChart[]>(res),
            })
          );
          setLoading(false);
        })
        .catch((error) => {
          dispatch(
            apiSyndicationInsightsChartsStateChanged({
              result: createErrorResult(error),
            })
          );
          setLoading(false);
        });
    }
  }, [companyId]);

  const { syndicationInsightsCharts } = useSelector((state: AppState) => state);

  return { ...syndicationInsightsCharts, loading };
};
