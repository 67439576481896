import React from 'react';
import { TextInput, TextInputProps } from '@hum/legacy-ui';
import { TextType, Text } from '@hum/ui-library';

export const SubdomainInput = (props: TextInputProps) => {
  return (
    <div className="flex  items-stretch relative">
      <TextInput
        {...props}
        className="rounded-tr-none rounded-br-none"
        placeholder=""
      />
      <div className="p-4 bg-gray-500 rounded-tr-default rounded-br-default">
        <Text
          type={TextType.BODY_S}
          subdued
          forceCapitalization={false}
          className="leading-none"
        >
          .humcapital.com
        </Text>
      </div>
    </div>
  );
};
