import { useEffect, useState } from 'react';
import { omit } from 'lodash';
import {
  createBulkDownloadFromSelection,
  getBulkDownloadFileList,
  getBulkDownloadTaskStatus,
  useApiMutation,
  useApiQuery,
} from '@hum/api';
import { BulkDownloadStatus, BulkDownloadSelectedFileParams } from '@hum/types';

type Selection = Record<string, boolean>;

export const useBulkDownloadFromSelection = ({
  companyId,
}: {
  companyId: number;
}) => {
  const [fileFilter, setFileFilter] = useState('');
  const [polling, setPolling] = useState(false);
  const { data: fileList } = useApiQuery(getBulkDownloadFileList, {
    params: {
      companyId,
    },
  });
  const [selectAll, setSelectAll] = useState(true);
  const [selectedCustomerFiles, setSelectedCustomerFiles] = useState({});
  const [
    selectedSupportedFinancialDocuments,
    setSelectedSupportedFinancialDocuments,
  ] = useState({});

  const {
    mutate: downloadSelectedFiles,
    data: downloadTask,
    reset: resetMutation,
  } = useApiMutation(createBulkDownloadFromSelection);

  const cancelPolling = () => {
    setPolling(false);
    resetMutation();
  };

  const { data: taskStatus } = useApiQuery(getBulkDownloadTaskStatus, {
    params: {
      companyId: companyId,
      taskId: downloadTask?.taskId || '',
    },
    enabled: polling,
    refetchInterval: 3000,
    onError: cancelPolling,
  });

  const textSuccess =
    taskStatus?.state === BulkDownloadStatus.Success &&
    !polling &&
    'Download successful';
  const textCancelled =
    (taskStatus?.state === BulkDownloadStatus.Progress ||
      taskStatus?.state === BulkDownloadStatus.Pending ||
      taskStatus?.state === BulkDownloadStatus.Started) &&
    !polling &&
    'Download cancelled';
  const textPolling =
    (taskStatus?.state === BulkDownloadStatus.Progress ||
      taskStatus?.state === BulkDownloadStatus.Pending) &&
    polling &&
    'Preparing download...';
  const textStarted =
    taskStatus?.state === BulkDownloadStatus.Started && 'Download started';
  const textFailed =
    (taskStatus?.state === BulkDownloadStatus.Failure ||
      taskStatus?.state === BulkDownloadStatus.Revoked) &&
    !polling &&
    'Download failed';
  const fileName = polling && taskStatus?.fileName;

  const text =
    fileName ||
    textSuccess ||
    textCancelled ||
    textPolling ||
    textStarted ||
    textFailed;

  useEffect(() => {
    if (!fileList) {
      return;
    }

    setSelectedCustomerFiles(
      fileList.customerFiles.reduce(
        (files, file) => ({
          ...files,
          [file.id]: true,
        }),
        {}
      )
    );

    setSelectedSupportedFinancialDocuments(
      fileList.supportedFinancialDocuments.reduce(
        (files, file) => ({
          ...files,
          [`${file.connectorType}:${file.docType}:${file.connectorSource}`]: true,
        }),
        {}
      )
    );
  }, [fileList]);

  useEffect(() => {
    if (downloadTask?.taskId) {
      setPolling(true);
    }
    if (
      taskStatus?.fileUrl &&
      taskStatus?.state === BulkDownloadStatus.Success
    ) {
      cancelPolling();
      window.location.assign(taskStatus.fileUrl);
    }
    if (
      taskStatus?.state === BulkDownloadStatus.Failure ||
      taskStatus?.state === BulkDownloadStatus.Revoked
    ) {
      cancelPolling();
    }
  }, [downloadTask?.taskId, taskStatus?.fileUrl, taskStatus?.state]);

  const handleDownloadSelectedClicked = () => {
    if (!fileList) {
      return;
    }

    const selected: BulkDownloadSelectedFileParams = {
      customerFiles: [],
      supportedFinancialDocuments: [],
    };

    for (let key in selectedCustomerFiles) {
      if (selectedCustomerFiles[key]) {
        selected.customerFiles.push(Number(key));
      }
    }

    for (let key in selectedSupportedFinancialDocuments) {
      if (selectedSupportedFinancialDocuments[key]) {
        const [connectorType, docType, connectorSource] = key.split(':');
        const financialDocument = fileList.supportedFinancialDocuments.find(
          (file) =>
            file.connectorType === connectorType &&
            file.docType === docType &&
            file.connectorSource === connectorSource
        );
        if (financialDocument) {
          selected.supportedFinancialDocuments.push(
            omit(financialDocument, 'lastSuccessPullAt')
          );
        }
      }
    }

    downloadSelectedFiles(selected);
  };

  const handleSelectAllChange = (value: boolean) => {
    setSelectAll(value);
    const newCustomerFiles = Object.keys(selectedCustomerFiles).reduce(
      (values, key) => ({
        ...values,
        [key]: value,
      }),
      {}
    );

    setSelectedCustomerFiles(newCustomerFiles);
    const newSupportedFinancialDocuments = Object.keys(
      selectedSupportedFinancialDocuments
    ).reduce(
      (values, key) => ({
        ...values,
        [key]: value,
      }),
      {}
    );
    setSelectedSupportedFinancialDocuments(newSupportedFinancialDocuments);
  };

  const handleCustomerFileChange = (key: number) => (value: boolean) => {
    const newCustomerFiles: Selection = {
      ...selectedCustomerFiles,
      [key]: value,
    };
    setSelectedCustomerFiles(newCustomerFiles);
    const values = Object.values(newCustomerFiles).concat(
      Object.values(selectedSupportedFinancialDocuments)
    );
    if (values.every((value: boolean) => value)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSupportedFinancialDocumentsChange = (key: string) => (
    value: string
  ) => {
    const newSupportedFinancialDocuments: Selection = {
      ...selectedSupportedFinancialDocuments,
      [key]: value,
    };
    setSelectedSupportedFinancialDocuments(newSupportedFinancialDocuments);
    const values = Object.values(newSupportedFinancialDocuments).concat(
      Object.values(selectedCustomerFiles)
    );
    if (values.every((value: boolean) => value)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  return {
    cancelPolling,
    fileFilter,
    fileList,
    handleCustomerFileChange,
    handleDownloadSelectedClicked,
    handleSelectAllChange,
    handleSupportedFinancialDocumentsChange,
    polling,
    selectAll,
    selectedCustomerFiles,
    selectedSupportedFinancialDocuments,
    setFileFilter,
    taskStatus,
    text: text || '',
  };
};
