import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import {
  Text,
  TextType,
  Button,
  ButtonType,
  ProfileIcon,
  Icon,
} from '@hum/ui-library';
import { Routes, AppState } from '@hum/icm-app/src/state';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

type TopbarProfileMenuProps = {
  hideSettings?: boolean;
  showAuthSection?: boolean;
};

export default function TopbarProfileMenu({
  hideSettings = false,
  showAuthSection,
}: TopbarProfileMenuProps) {
  const history = useHistory();
  const {
    session: { data: currentUser },
  } = useSelector((state: AppState) => state);
  const ProfileMenu = () => (
    <>
      <Menu
        as="div"
        className="relative hidden lg:block"
        data-testid="user:open-more-menu-btn"
      >
        <Menu.Button className="cursor-pointer">
          <ProfileIcon>
            {currentUser?.firstName.charAt(0).toUpperCase()}
          </ProfileIcon>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={clsx(
              'absolute -left-[90px] z-[1000] bg-gray-600 shadow-default rounded-default',
              { '-bottom-[85px]': !hideSettings }
            )}
          >
            {!hideSettings && (
              <Menu.Item>
                <Button
                  type={ButtonType.QUIET}
                  onClick={() =>
                    history.push(`${Routes.SETTINGS}${history.location.search}`)
                  }
                  className="w-full py-2"
                >
                  <Text type={TextType.BODY_M} className="cursor-pointer">
                    Settings
                  </Text>
                </Button>
              </Menu.Item>
            )}

            <Menu.Item>
              <Button
                type={ButtonType.QUIET}
                onClick={() => {
                  history.push(Routes.LOGOUT);
                }}
                className="w-full py-2"
              >
                <Text type={TextType.BODY_M} className="cursor-pointer">
                  Logout
                </Text>
              </Button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <ProfileIcon className="block lg:hidden">
        {currentUser?.firstName.charAt(0).toUpperCase()}
      </ProfileIcon>
    </>
  );

  if (!currentUser) return <></>;

  if (showAuthSection) {
    return (
      <div className="flex items-center justify-end">
        <div className="mr-4 md:flex items-center hidden">
          <Icon.Padlock className="mr-1 fill-transparent" width="16" />
          <Text type={TextType.BODY_S} subdued>
            256-Bit Bank-Level Encryption
          </Text>
        </div>
        <ProfileMenu />
      </div>
    );
  }

  return <ProfileMenu />;
}
