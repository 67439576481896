import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { BookmarkedPage, PageHeader } from './common';

import { tw } from './utils';

export const Glossary = () => (
  <>
    <BookmarkedPage title="Glossary" id="Glossary" break>
      <PageHeader title="Glossary" />
      <View style={tw('flex-row', 'mt-2')}>
        <View style={tw('w-1/2')}>
          <Text style={tw('text-h4', 'mx-1')}>(Q)</Text>
          <Text style={tw('text-body', 'm-1')}>
            Pertaining to the quarter ending on the “Financial Statement data”
            date. For example, Revenue (Q) is revenue in the quarter ending on
            the financial statement date
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>(TTM)</Text>
          <Text style={tw('text-body', 'm-1')}>
            Pertaining to the 12-month period ending on the “Financial Statement
            date” date.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>75TH PERCENTILE PERFORMANCE</Text>
          <Text style={tw('text-body', 'm-1')}>
            When available, the levels of metrics needed to be in the top
            quartile versus similar peers. These are calculated both for the
            most recent values, and also for year over year growth rates.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>CASH</Text>
          <Text style={tw('text-body', 'm-1')}>
            Cash balances, as of the “Financial Statement data” date.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>COMPANY’S RANK</Text>
          <Text style={tw('text-body', 'm-1')}>
            When available, a ranking on a scale of 1-100 (100 is best) of a
            company’s metrics versus similar peers. These are calculated both
            for the most recent metric values, and for the year over year growth
            rates in those metrics.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>EBITDA % (Q)</Text>
          <Text style={tw('text-body', 'm-1')}>
            Earnings, excluding interest, taxes, depreciation, amortization,
            expressed as a percent of Revenues.
          </Text>
        </View>
        <View style={tw('w-1/2')}>
          <Text style={tw('text-h4', 'mx-1')}>EQUITY CAPITAL</Text>
          <Text style={tw('text-body', 'm-1')}>
            The total amount of equity capital a company has raised.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>EXPECTED RAISE AMOUNT</Text>
          <Text style={tw('text-body', 'm-1')}>
            The company’s self-reported desired fundraising amount.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>GROSS MARGIN</Text>
          <Text style={tw('text-body', 'm-1')}>
            Revenues minus Cost of Goods Sold, expressed as a percent of
            Revenues.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>GROWTH SCORE</Text>
          <Text style={tw('text-body', 'm-1')}>
            A Hum-proprietary score, on a scale of 1-100, with 100 being best,
            measuring a company’s revenue growth versus similar companies
            Inventory of goods for sale, as of the “Financial Statement data”
            date.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>MAX DEBT</Text>
          <Text style={tw('text-body', 'm-1')}>
            A Hum-proprietary estimate of the maximum amount of debt a company
            can sustain.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>MAX NEW DEBT</Text>
          <Text style={tw('text-body', 'm-1')}>
            The difference between max debt and existing total debt.
          </Text>
          <Text style={tw('text-h4', 'mx-1')}>OPERATING EFFICIENCY SCORE</Text>
          <Text style={tw('text-body', 'm-1')}>
            A Hum-proprietary score, on a scale of 1-100, with 100 being best,
            measuring a company’s profitability versus similar companies.
          </Text>
        </View>
      </View>
    </BookmarkedPage>
    <BookmarkedPage title="Glossary, continued." break>
      <PageHeader title="Glossary, continued." />
      <View style={tw('w-1/2')}>
        <Text style={tw('text-h4', 'mx-1')}>PAYABLES</Text>
        <Text style={tw('text-body', 'm-1')}>
          Accounts Payable, as of the “Financial Statement data” date.
        </Text>
        <Text style={tw('text-h4', 'mx-1')}>PRE-MARKETING EBITDA %</Text>
        <Text style={tw('text-body', 'm-1')}>
          Earnings, excluding interest, taxes, depreciation, amortization, and
          sales and marketing expenses, expressed as a percent of Revenues.
        </Text>
        <Text style={tw('text-h4', 'mx-1')}>RECEIVABLES</Text>
        <Text style={tw('text-body', 'm-1')}>
          Accounts Receivable, as of the “Financial Statement data” date.
        </Text>
        <Text style={tw('text-h4', 'mx-1')}>RUNWAY</Text>
        <Text style={tw('text-body', 'm-1')}>
          The ratio of Cash to EBITDA, for companies with negative EBITDA,
          measured in months. Not relevant for companies with positive EBITDA.
        </Text>
        <Text style={tw('text-h4', 'mx-1')}>S&M %</Text>
        <Text style={tw('text-body', 'm-1')}>
          Sales and Marketing expenses, expressed as a percent of Revenues.
        </Text>
        <Text style={tw('text-h4', 'mx-1')}>TOTAL DEBT</Text>
        <Text style={tw('text-body', 'm-1')}>
          Total short and long term debt, as of the “Financial Statement data”
          date.
        </Text>
        <Text style={tw('text-h4', 'mx-1')}>WC DAYS</Text>
        <Text style={tw('text-body', 'm-1')}>
          Working capital, expressed as a fraction of the past year’s revenues
          (shown in days). Formula: (Working Capital / Revenues) * 365 Working
          capital - Current assets minus current liabilities.
        </Text>
      </View>
    </BookmarkedPage>
  </>
);
