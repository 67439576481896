import { useEffect, useState } from 'react';
import { useApiCall } from '@hum/icm-app/src/hooks/useAPI';
import { OnboardingPrefill } from '@hum/icm-app/src/backend/api/models/onboardingModels';
import { isEmpty } from 'lodash';
import {
  getCountryCodeByName,
  getStateCodeByName,
} from '../../pages/syndication-v2/syndicationUtils';

export const useOnboardingPrefill = (form: any, email?: string) => {
  const { api, handleResponse, result } = useApiCall<OnboardingPrefill>();
  const [prefilled, setPrefilled] = useState<boolean>(false);

  useEffect(() => {
    if (email && !prefilled)
      api.onboarding.getOnboardingPrefill(email).then(handleResponse);
  }, [email]);

  useEffect(() => {
    if (!prefilled && result.data && !isEmpty(result.data)) {
      form.setFieldValue('companyName', result.data.companyName);
      form.setFieldValue('country', getCountryCodeByName(result.data.country));
      form.setFieldValue('website', result.data.website);
      form.setFieldValue('yearFounded', result.data.yearFounded);
      if (result.data.country === 'United States' && result.data.state)
        form.setFieldValue('state', getStateCodeByName(result.data.state));
      setPrefilled(true);
    }
  }, [result, prefilled]);

  return result;
};
