import React from 'react';
import { TocModal } from './TocModal';

type Props = {
  title: string;
  companyName: string | null;
  isOpen: boolean;
  onClose: () => void;
  agreementVersion: string;
  'data-testid': string;
};

const MatchTocModal: React.FC<Props> = ({
  title,
  companyName,
  isOpen,
  onClose,
  agreementVersion,
  'data-testid': dataTestId,
}: Props) => {
  const Content = require(`./Agreements/${agreementVersion}.md`);
  const content = (Content.default || '').replace(
    '<<NAME OF COMPANY>>',
    companyName
  );

  return (
    <TocModal
      isOpen={isOpen}
      data-testid={dataTestId}
      title={title}
      content={content}
      onClose={onClose}
      buttonText="Close"
    />
  );
};

export { MatchTocModal };
