import { useChartDataUrl } from './useChartDataUrl';
import { Color } from '../utils';

export type IUseBoxPlotDataUrl = {
  id: string;
  title: string;
  width: number;
  height: number;
  population: {
    name: string;
    y: number[];
  }[];
  sample: {
    name: string;
    x: string[];
    y: number[];
  };
  annotations: {
    x: string;
    y: number;
    text: string;
  }[];
};

export const useBoxPlotDataUrl = ({
  id,
  title,
  width,
  height,
  population,
  sample,
  annotations,
}: IUseBoxPlotDataUrl) => {
  return useChartDataUrl({
    id,
    width,
    height,
    data: population.map((p) => ({
      ...p,
      type: 'box',
      boxpoints: false,
      fillcolor: Color.BlueOrchid,
      marker: {
        color: Color.BlueOrchid,
      },
      showlegend: false,
    })),
    otherMarkers: {
      ...sample,
      marker: {
        showlegend: true,
        color: Color.GoldenrodYellow,
        size: 10,
        line: {
          color: 'rgba(223, 191, 3, 0.30)',
          width: 13,
        },
      },
    },
    layout: {
      title,
      font: {
        family: "'Inter', sans-serif",
      },
      yaxis: {
        separatethousands: true,
        showgrid: true,
        gridcolor: Color.LightPeriwinkle,
        zeroline: true,
        zerolinecolor: Color.LightPeriwinkle,
        zerolinewidth: 3,
      },
      xaxis: {
        showgrid: false,
      },
      plot_bgcolor: Color.GhostWhite,
      paper_bgcolor: Color.GhostWhite,
      margin: {
        pad: 10,
      },
      annotations: annotations.map((a) => ({
        ...a,
        ay: 0,
        ayref: 'pixel',
        ax: 50,
        axref: 'pixel',
        arrowcolor: Color.GoldenrodYellow,
        arrowhead: 0,
        arrowwidth: 1,
        bgcolor: Color.GoldenrodYellow,
        font: {
          color: 'black',
        },
      })),
    },
  });
};
