import React from 'react';

import { Text, View, Image } from '@react-pdf/renderer';
import { tw } from './utils';

export type PageHeaderProps = {
  title: string;
};

export const PageHeader = ({ title }: PageHeaderProps) => (
  <View style={tw('flex-row', 'pr-1')}>
    <Text style={tw('text-h1', 'mb-2', 'mx-1', 'grow')}>{title}</Text>
    <Image
      style={{ width: 160, height: 68 }}
      src="https://assets.humcapital.com/hum-logo-rgb.png"
    />
  </View>
);

export type BookmarkedPageProps = React.ComponentProps<typeof View> & {
  title: string;
};

export const BookmarkedPage = ({ title, ...props }: BookmarkedPageProps) => (
  <View
    style={tw('p-2', 'grow')}
    {...{ bookmark: { title, expanded: true, fit: true } }}
    {...props}
  />
);
