import { useEffect } from 'react';
import { signupIsInvitationRedeemedHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useFlags } from '../useFlags';
import { getIsInvitationRedeemed, useApiQuery } from '@hum/api';

export const useInvitationRedemption = (invitationHash: string) => {
  const flags = useFlags();
  const { state, dispatch } = useAppStore();
  const { data: invitationRedemption } = useApiQuery(getIsInvitationRedeemed, {
    params: { invitationHash },
    enabled: flags.enabled('use-react-query'),
  });

  useEffect(() => {
    if (invitationHash) {
      if (!flags.enabled('use-react-query')) {
        dispatch(signupIsInvitationRedeemedHookUsed({ invitationHash }));
      }
    }
  }, []);

  return flags.enabled('use-react-query')
    ? invitationRedemption
    : state.invitationRedemption;
};
