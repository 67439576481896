import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { InvestorPreferences } from '@hum/types';

import { BookmarkedPage, PageHeader } from '../common';
import { tw } from '../utils';
import { Legend } from './Legend';
import { CreditBoxTable } from './CreditBoxTable';

export const CREDIT_BOX_ORDER = [
  'growthFinancingFromBankSponsorBacked',
  'growthFinancingFromNonbank',
  'growthFinancingFromNonbankSponsorBacked',
  'directLendingFromBank',
  'directLendingFromBankSponsorBacked',
  'directLendingFromNonbank',
  'directLendingFromNonbankSponsorBacked',
  'ablFromBank',
  'ablFromNonbank',
];

export type IcmEvaluationProps = InvestorPreferences & {
  newPage: boolean;
};

export const IcmEvaluation = ({
  header,
  creditBox,
  newPage,
}: IcmEvaluationProps) => {
  // we don't have values for strategicCapital yet, so we peel it off
  const { strategicCapital, ...creditBoxes } = creditBox;
  const { companyMetrics } = header;

  return (
    <>
      <BookmarkedPage title="ICM Evaluation" break={newPage}>
        <PageHeader title="Hum’s Intelligent Capital Marketplace Evaluation" />
        <View wrap>
          <View style={tw('flex-row', 'mx-1', 'justify-between')}>
            <View style={tw('w-3/5')}>
              <Text style={tw('mb-1', 'text-body')}>
                Hum utilizes your company’s connected accounting data to assess
                your financials against various non-dilutive capital
                opportunities available through Hum’s ICM, today. This
                evaluation is not a representation of your ability to receive
                funding in the broader Investor market. Learn more about the
                investors on Hum’s ICM and how we evaluate your business [here].
              </Text>
              <Text style={tw('mb-0.5', 'text-body')}>
                If any of the criteria are not met within any of the categories,
                stay connected to Hum. As your business continues to grow, Hum
                will automatically track your progress towards accessing
                nondilutive capital on our platform and notify you of new
                opportunities.
              </Text>
            </View>
            <View style={[tw('w-1/3'), { transform: 'translateY(-50px)' }]}>
              <Legend />
            </View>
          </View>
          <CreditBoxTable
            metrics={companyMetrics}
            creditBoxes={CREDIT_BOX_ORDER.slice(
              0,
              CREDIT_BOX_ORDER.length / 2
            ).reduce(
              (boxes, name) => ({
                ...boxes,
                [name]: creditBoxes[name],
              }),
              {}
            )}
          />
        </View>
      </BookmarkedPage>
      <BookmarkedPage title="ICM Evaluation, continued" break>
        <PageHeader title="Hum’s Intelligent Capital Marketplace Evaluation, continued" />
        <View wrap>
          <CreditBoxTable
            metrics={companyMetrics}
            creditBoxes={CREDIT_BOX_ORDER.slice(
              CREDIT_BOX_ORDER.length / 2
            ).reduce(
              (boxes, name) => ({
                ...boxes,
                [name]: creditBoxes[name],
              }),
              {}
            )}
          />
        </View>
      </BookmarkedPage>
    </>
  );
};
