import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '@hum/icm-app/src/state';
import { connectorsHookUsed } from '@hum/icm-app/src/actions';

export const useConnectors = (companyId?: number) => {
  const dispatch = useDispatch();
  const { connectors } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(connectorsHookUsed({ companyId }));
  }, [companyId]);

  return connectors;
};
