import { companyContactHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';

export const useCompanyContact = (companyId?: number) => {
  const { state, dispatch } = useAppStore();
  useEffect(() => {
    if (companyId) {
      dispatch(companyContactHookUsed({ companyId }));
    }
  }, [companyId]);
  return state.currentCompanyUser;
};
