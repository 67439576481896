import * as Yup from 'yup';

import { useField, useForm } from '@hum/common/src/modules/form';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { OnboardingV3Payload } from '@hum/icm-app/src/state';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

export enum OnboardingPath {
  AccountType = 'account-type',
  CompanyDetails = 'company-details',
  FinancialDetails = 'financial-details',
  InsightRequests = 'insight-requests',
  Privacy = 'privacy',
}

const initialValues = {
  requireMutualNda: false,
  unwantedInvestors: [],
};

export const useEntitySettings: any = ({
  defaultInitialValues,
}: {
  defaultInitialValues?: any;
}) => {
  const { dispatch } = useAppStore();
  const company = useCurrentCompany();
  const api = useAPI();

  const form = useForm({
    initialValues: defaultInitialValues ? defaultInitialValues : initialValues,
    validationSchema: Yup.object().shape({}),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (payload: OnboardingV3Payload) => {
      // TODO: drop all of v3, migrate this section to user settings
      await api.onboarding.saveOnboardingV3(dispatch)(
        company.data!.id,
        payload
      );
    },
  });

  const requireMutualNda = useField('requireMutualNda', 'checkbox', form).input;
  const unwantedInvestors = useField('unwantedInvestors', 'select', form).input;

  return {
    form,
    initialValues,
    fields: {
      requireMutualNda,
      unwantedInvestors,
    },
  };
};
