import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Routes, AppState } from '@hum/icm-app/src/state';
import { getAuthRedirectUrl } from '@hum/icm-app/src/sagas/auth';
import { useSession } from '@hum/icm-app/src/hooks/useSession';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useIsWhitelabeled } from '@hum/icm-app/src/hooks/useIsWhitelabeled';
import { Icon, Text, TextType, Topbar } from '@hum/ui-library';
import TopbarProfileMenu from '@hum/icm-app/src/components/TopbarProfileMenu';
import { TopbarProps } from './Topbar';
import { getQuery } from '../../utils/router';
import { CompanyType } from '@hum/types';

export const StandardTopbar = memo(
  ({
    title,
    fixedBar,
    links,
    background = 'bg-gray-900',
    flow,
  }: TopbarProps) => {
    const history = useHistory();
    const isBranded = useIsWhitelabeled();
    const flags = useFlags();
    const { session, whitelabeling } = useSelector((state: AppState) => state);
    const { isCompany } = useSession();
    const currentUser = session.data;

    const navigationLinks: any[] = [];

    const isInAdminPage = history.location.pathname.includes(Routes.ADMIN);
    const companyId = getQuery(history.location.search).company_id;
    const isAdmin = currentUser?.roles.includes(CompanyType.Admin);

    const getRootRedirectUrl = () => {
      if (flags.enabled('remove-get-started') && isCompany) {
        return Routes.COMPANY_ACTIVATION_GET_STARTED;
      }
      if (currentUser) {
        return getAuthRedirectUrl(currentUser, Routes.ROOT) || Routes.ROOT;
      }

      return Routes.ROOT;
    };

    const rootRedirectUrl = getRootRedirectUrl();

    if (isAdmin) {
      navigationLinks.push(
        <a className="navlink-item" href={`${process.env.V2_BASE_URL}/deals`}>
          <Text type={TextType.BODY_S} className="cursor-pointer" subdued>
            Deals
          </Text>
        </a>
      );
    }

    if (!isInAdminPage && !links && isCompany) {
      navigationLinks.push(
        <NavLink
          className="navlink-item"
          activeClassName="active"
          to={Routes.COMPANY_ACTIVATION_CONNECTORS + history.location.search}
          isActive={() =>
            history.location.pathname.startsWith(Routes.ONBOARDING_INDEX) ||
            (history.location.pathname.startsWith(
              Routes.COMPANY_ACTIVATION_ROOT
            ) &&
              !history.location.pathname.includes(
                Routes.COMPANY_ACTIVATION_GET_STARTED
              ))
          }
        >
          <Text type={TextType.BODY_S} className="cursor-pointer" subdued>
            Data Sharing
          </Text>
        </NavLink>
      );

      navigationLinks.push(
        <NavLink
          className="navlink-item"
          activeClassName="active"
          to={Routes.INSIGHTS_FINANCIAL_ANALYSIS + history.location.search}
          isActive={() => history.location.pathname.startsWith(Routes.INSIGHTS)}
        >
          <Text type={TextType.BODY_S} className="cursor-pointer" subdued>
            Insights
          </Text>
        </NavLink>
      );

      navigationLinks.push(
        <a
          className="navlink-item"
          //eslint-disable-next-line
          href={
            isAdmin
              ? `${process.env.V2_BASE_URL}/company/${companyId}/profile`
              : `${process.env.V2_BASE_URL}/profile`
          }
        >
          <Text type={TextType.BODY_S} className="cursor-pointer" subdued>
            Your Profile
          </Text>
        </a>
      );
    }

    const logo = isBranded ? (
      <a href={rootRedirectUrl}>
        <Icon.Hum />
        {whitelabeling?.data?.editedLogoFileUrl && (
          <>
            <div className="bg-[#393939] w-[1px] h-full my-0 mx-[28px]"></div>
            <img
              className="object-contain"
              src={whitelabeling.data?.editedLogoFileUrl}
            />
          </>
        )}
      </a>
    ) : (
      <a href={rootRedirectUrl}>
        <Icon.Hum />
      </a>
    );

    return (
      <Topbar
        title={title}
        navigationLinks={links || navigationLinks}
        userLinks={<TopbarProfileMenu />}
        logo={logo}
        fixedBar={fixedBar}
        background={background}
        flow={flow}
      />
    );
  }
);

StandardTopbar.displayName = 'StandardTopbar';
