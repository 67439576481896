import React, { useRef } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { countries, Country } from 'countries-list';
import { FileIcon } from '@hum/legacy-ui';
import {
  Button,
  ButtonSize,
  ButtonType,
  DescriptionList,
  Text,
  TextType,
} from '@hum/ui-library';
import {
  BUSINESS_TYPES,
  ECONOMIC_TIER_OPTIONS,
  MONTHS_OF_RUNWAY_OPTIONS,
  REQUIRED_INSIGHTS,
  US_STATES,
} from '@hum/icm-app/src/components/constants';
import { useCompanySegment } from '@hum/icm-app/src/hooks/useCompanySegment';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import {
  Company,
  CompanyContact,
  CompanyFileDocumentType,
  NAICS_INDUSTRY_GROUP_OPTIONS,
} from '@hum/types';
import { MndaOptions } from '@hum/icm-app/src/components/types';
import { money } from '../../utils';

// Channel is how the company/investor account was created
const getChannel = (company: Company) => {
  const channel =
    (company.application && company.application.channel) || 'other';

  const channelOptions = {
    icm_organic: 'Self sign-up',
    icm_sales: 'Added via admin page',
    icm_company_invitation: 'Company invited',
    icm_investor_invitation: 'Investor invited',
    social: 'Social media',
    events: 'Events',
    paid_list: 'Paid list',
    paid_ads: 'Paid ads',
    referral: 'Referral',
    outbound_sales: 'Outbound sales',
    webform: 'Webform',
    unknown: 'Unknown',
    other: '–',
  };

  return channelOptions[channel];
};

export const CompanyInfo: React.FC<{
  company: Company;
  contact: CompanyContact;
}> = ({ company, contact }) => {
  const { segment } = useCompanySegment();
  return (
    <DescriptionList>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Website
        </Text>
      </dt>
      <dd data-testid="company-info:website">
        <Text type={TextType.BODY_S}>
          {company.website ? (
            <a
              href={
                company.website.startsWith('http://') ||
                company.website.startsWith('https://')
                  ? company.website.replace('http://', 'https://')
                  : `https://${company.website}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="text-green hover:underline ui-link"
            >
              {company.website}
            </a>
          ) : (
            '–'
          )}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Contact name
        </Text>
      </dt>
      <dd data-testid="company-info:contact:full-name">
        <Text type={TextType.BODY_S}>
          {`${contact.firstName} ${contact.lastName}` || '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Contact email
        </Text>
      </dt>
      <dd data-testid="company-info:contact:email">
        <Text type={TextType.BODY_S}>
          <a
            href={`mailto:${contact.email}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-green hover:underline ui-link"
          >
            {contact.email}
          </a>
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Contact phone number
        </Text>
      </dt>
      <dd data-testid="company-info:contact:phone">
        <Text type={TextType.BODY_S}>{contact.phone || '–'}</Text>{' '}
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Country
        </Text>
      </dt>
      <dd data-testid="company-info:country">
        <Text type={TextType.BODY_S}>
          {(company.country &&
            countries[company.country] &&
            (countries[company.country] as Country).name) ||
            '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          State
        </Text>
      </dt>
      <dd data-testid="company-info:state">
        <Text type={TextType.BODY_S}>
          {company.state && Boolean(US_STATES[company.state])
            ? US_STATES[company.state]
            : 'N/A'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Added via
        </Text>
      </dt>
      <dd data-testid="company-info:channel">
        <Text type={TextType.BODY_S}>{getChannel(company) || '-'}</Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Yearly Revenue
        </Text>
      </dt>
      <dd data-testid="company-info:yearly-revenue">
        <Text type={TextType.BODY_S}>
          {(company.application &&
            company.application.yearlyRevenue &&
            ECONOMIC_TIER_OPTIONS[company.application.yearlyRevenue]) ||
            '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Months of Runway
        </Text>
      </dt>
      <dd data-testid="company-info:months-of-runway">
        <Text type={TextType.BODY_S}>
          {(company.application &&
            company.application.monthsOfRunway &&
            MONTHS_OF_RUNWAY_OPTIONS[company.application.monthsOfRunway]) ||
            '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Business Type
        </Text>
      </dt>
      <dd data-testid="company-info:business-type">
        <Text type={TextType.BODY_S}>
          {(company &&
            company.businessType &&
            BUSINESS_TYPES[company.businessType]) ||
            '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Industry Vertical
        </Text>
      </dt>
      <dd data-testid="company-info:user-industry">
        <Text type={TextType.BODY_S}>
          {company?.application?.industryVertical || '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Self-reported NAICS Industry
        </Text>
      </dt>
      <dd data-testid="company-info:self-reported-industry">
        <Text type={TextType.BODY_S}>
          {(company?.application?.selfReportedIndustry &&
            NAICS_INDUSTRY_GROUP_OPTIONS[
              company?.application?.selfReportedIndustry
            ]) ||
            '–'}
        </Text>
      </dd>

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Target raise amount
        </Text>
      </dt>
      <dd data-testid="company-info:business-type">
        <Text type={TextType.BODY_S}>
          {(company &&
            company.application.investmentAmount &&
            money(company.application.investmentAmount)) ||
            '–'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Accounting System
        </Text>
      </dt>
      <dd data-testid="company-info:accounting-system">
        <Text type={TextType.BODY_S}>
          {(company && company.application.recordSystem) || '–'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Payment Processor
        </Text>
      </dt>
      <dd data-testid="company-info:payment-processor">
        <Text type={TextType.BODY_S}>
          {(company && company.application.paymentProcessor) || '–'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Public Description
        </Text>
      </dt>
      <dd data-testid="company-info:public-description">
        <ReactMarkdown
          className={'whitespace-normal'}
          children={company.publicDescription || '–'}
          components={{
            p: ({ children }) => <Text type={TextType.BODY_S}>{children}</Text>,
          }}
        />
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Private Description
        </Text>
      </dt>
      <dd data-testid="company-info:private-description">
        <ReactMarkdown
          className={'whitespace-normal'}
          children={company.description || '–'}
          components={{
            p: ({ children }) => <Text type={TextType.BODY_S}>{children}</Text>,
          }}
        />
      </dd>
      {company.application.requiredInsights?.length ? (
        <>
          <dt>
            <Text type={TextType.BODY_S} subdued>
              Required insights
            </Text>
          </dt>
          <dd data-testid="company-info:unwanted-investors">
            <div>
              <ul>
                {company.application.requiredInsights.map((insight: any) => (
                  <li key={insight.value}>
                    <Text type={TextType.BODY_S}>
                      {REQUIRED_INSIGHTS[insight.value] || '-'}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          </dd>
        </>
      ) : (
        <></>
      )}
      {company.application.otherRequiredInsights?.length ? (
        <>
          <dt>
            <Text type={TextType.BODY_S} subdued>
              Other required insights
            </Text>
          </dt>
          <dd data-testid="company-info:unwanted-investors">
            <div>
              <ul>
                {company.application.otherRequiredInsights.map((insight) => (
                  <li key={insight.value}>
                    <Text type={TextType.BODY_S}>{insight.value || '-'}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </dd>
        </>
      ) : (
        <></>
      )}

      <dt>
        <Text type={TextType.BODY_S} subdued>
          Wants company analytics
        </Text>
      </dt>
      <dd data-testid="company-info:wants-company-analytics">
        <Text type={TextType.BODY_S}>
          {company && company.application.accountTypeAnalytics ? 'Yes' : 'No'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Wants syndication
        </Text>
      </dt>
      <dd data-testid="company-info:wants-syndication">
        <Text type={TextType.BODY_S}>
          {company && company.application.accountTypeSyndication ? 'Yes' : 'No'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Requires mutual NDA
        </Text>
      </dt>
      <dd data-testid="company-info:requires-mutual-nda">
        <Text type={TextType.BODY_S}>
          {company && company.application.requireMutualNda ? 'Yes' : 'No'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          MNDA Flow
        </Text>
      </dt>
      <dd data-testid="company-info:mnda-flow">
        <Text type={TextType.BODY_S}>
          {company && company.pendingMndaSignOffline === MndaOptions.Pending
            ? 'Custom'
            : 'Default'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Segment
        </Text>
      </dt>
      <dd data-testid="company-info:segment">
        <Text type={TextType.BODY_S}>{segment || '-'}</Text>
      </dd>
    </DescriptionList>
  );
};

// Returns `accepted on date` if there's a date or just accepted if not
const acceptanceText = (d?: string | null) =>
  d ? `Accepted on ${dayjs(d).format('MMM D, YYYY')}` : 'Accepted';

export const CompanySignatures: React.FC<{ company: Company }> = ({
  company,
}) => {
  const fileManager = useFileManager({
    companyId: company.id,
  });

  const { files, downloadFile, createFromLocalFile } = fileManager;

  const mndaFiles = files.filter(
    (file) => file.documentType === CompanyFileDocumentType.DocusignMnda
  );

  const feeAgreementFiles = files.filter(
    (file) => file.documentType === CompanyFileDocumentType.FeeAgreement
  );

  const dateTOS = acceptanceText(company.acceptedToSOn);
  const dateNDA = acceptanceText(company.acceptedNDAOn);
  const dateFeeToS = acceptanceText(company.acceptedFeeToSOn);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadFeeAgreement: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const selectedFiles = Array.from(e.target.files || []);

    // cleanup selection
    e.target.value = '';

    for (const file of selectedFiles) {
      createFromLocalFile({
        input: {
          name: file.name,
          type: file.type,
          documentType: CompanyFileDocumentType.FeeAgreement,
        },
        file,
      });
    }
  };

  const openLocalFileSelect = () =>
    fileInputRef.current && fileInputRef.current.click();

  return (
    <DescriptionList>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Has accepted ICM fee agreement
          <br />
          or upload a PDF ↓
          <br />
          <Button
            type={ButtonType.SECONDARY}
            size={ButtonSize.SLIM}
            onClick={openLocalFileSelect}
          >
            Upload custom fee agreement
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            accept="application/pdf"
            onChange={uploadFeeAgreement}
            className="hidden"
          />
        </Text>
      </dt>
      <dd
        className={classNames({
          'opacity-50': !company.acceptedFeeToS,
        })}
      >
        <Text type={TextType.BODY_S}>
          {company.acceptedFeeToS
            ? dateFeeToS
            : `Has not accepted ICM fee agreement`}

          {feeAgreementFiles.length ? (
            <>
              <br />
              But here's a custom agreement ↓<br />
            </>
          ) : null}
          {feeAgreementFiles.map((file) => (
            <div className="flex items-center block" key={file.id}>
              <FileIcon
                size={16}
                mimetype={file.type}
                className="flex-shrink-0 mr-2"
              />
              <a
                onClick={() => downloadFile(file.id)}
                rel="noopener noreferrer"
                className="flex items-center justify-start block text-left text-white ui-link"
              >
                {file.name}.{file.ext}
              </a>
            </div>
          ))}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Accepted TOS
        </Text>
      </dt>
      <dd
        className={classNames({
          'opacity-50': !company.acceptedToS,
        })}
      >
        <Text type={TextType.BODY_S}>
          {company.acceptedToS ? dateTOS : '–'}
        </Text>
      </dd>
      <dt>
        <Text type={TextType.BODY_S} subdued>
          Accepted MNDA
        </Text>
      </dt>
      <dd
        className={classNames({
          'opacity-50': !company.acceptedNDA,
        })}
      >
        <Text type={TextType.BODY_S}>
          {company.acceptedNDA ? dateNDA : '–'}
        </Text>
      </dd>
      {mndaFiles && mndaFiles.length > 0 ? (
        <>
          <dt>
            <Text type={TextType.BODY_S} subdued>
              MNDA files
            </Text>
          </dt>
          <dd
            className={classNames({
              'opacity-50': !company.acceptedNDA,
            })}
          >
            {mndaFiles.map((file) => (
              <div className="flex items-center block" key={file.id}>
                <FileIcon
                  size={16}
                  mimetype={file.type}
                  className="flex-shrink-0 mr-2"
                />
                <a
                  onClick={() => downloadFile(file.id)}
                  rel="noopener noreferrer"
                  className="flex items-center justify-start block text-left text-white ui-link"
                >
                  {file.name}.{file.ext}
                </a>
              </div>
            ))}
          </dd>
        </>
      ) : null}
    </DescriptionList>
  );
};
