import React from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarNav, Text, TextType } from '@hum/ui-library';
import { MenuItem } from '@hum/types';

export const SidebarNavItems = ({ items }: { items: [string, MenuItem][] }) => {
  return (
    <>
      {items.map(([path, menuItem]) => {
        return (
          <NavLink
            to={path + window.location.search}
            key={path}
            activeClassName="bg-onSurface-pressed"
            className="hover:no-underline"
          >
            <SidebarNav.Item testId={menuItem.testName}>
              <Text type={TextType.UI_ELEMENT}>{menuItem.title}</Text>
            </SidebarNav.Item>
          </NavLink>
        );
      })}
    </>
  );
};
