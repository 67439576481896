import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { docusignConnectorHookUsed } from '@hum/icm-app/src/actions';
import { useEffect } from 'react';

export const useDocusignConnector = () => {
  const {
    state: { docusignConnector },
    dispatch,
  } = useAppStore();

  useEffect(() => {
    dispatch(docusignConnectorHookUsed());
  }, []);

  return docusignConnector;
};
