import React from 'react';
import { USStates } from '@hum/types';
import { SelectInput, SelectInputProps } from '@hum/legacy-ui';
import {
  US_STATES_SELECT_OPTIONS,
  US_STATES,
} from '@hum/icm-app/src/components/constants';

export function SelectClearbitLocationInput({
  value,
  ...rest
}: SelectInputProps) {
  const selectedValue = value as string;
  const selectedLocationExists = !!US_STATES[selectedValue];
  return (
    <SelectInput
      {...rest}
      placeholder=""
      // NOTE: Display "Outside the US" if clearbit location is not recognized by Hum
      value={
        !selectedValue || selectedLocationExists ? value : USStates.OutsideTheUS
      }
      aria-label="Location"
      options={US_STATES_SELECT_OPTIONS}
    />
  );
}
