import { useSelector } from 'react-redux';

import { CompanySegment } from '@hum/types';
import { AppState } from '@hum/icm-app/src/state';

// Return if a company is considered a whale, boar, mice for onboarding v5
export const useCompanySegment = () => {
  const { currentCompany } = useSelector((state: AppState) => state);

  const isBoar = currentCompany.data?.segment === CompanySegment.Boar;
  const isWhale = currentCompany.data?.segment === CompanySegment.Whale;
  const isMice = currentCompany.data?.segment === CompanySegment.Mice;
  const isBoarWhale = isBoar || isWhale;
  const segment = currentCompany.data?.segment;

  return {
    isBoar,
    isWhale,
    isMice,
    isBoarWhale,
    segment,
  };
};
