import React from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';

import { Spinner, Icon, Text, TextType } from '@hum/ui-library';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { FinancialInsights } from '@hum/types';

import {
  FinancialInsightsDocument,
  FinancialInsightsDocumentSections,
} from './FinancialInsightsDocument';
import { useMounted } from './FinancialInsightsDocument/hooks';
import { Link } from 'react-router-dom';

export type FinancialInsightsSectionProps = {
  insights?: FinancialInsights;
  loading?: boolean;
};

export const DownloadLinkContainer = ({ title, children }) => (
  <div className="rounded-[8px] border border-borderColor mb-4">
    <div className="flex items-center p-4 select-none hover:bg-gray-700 justify-end">
      <div className="flex flex-1">
        <Icon.DefaultFile className="mr-4" />
        <Text type={TextType.UI_ELEMENT}>{title}</Text>
      </div>
      {children}
    </div>
  </div>
);

export const FinancialInsightsSection = ({
  insights,
  loading = false,
}: FinancialInsightsSectionProps) => {
  const isMounted = useMounted();
  const { profile, benchmarking } = insights || {};
  const { humsProprietaryScoringModels } = profile || {};
  const { models } = humsProprietaryScoringModels || {};
  const hasProprietaryScoringModels = ![
    models?.maxDebt.value,
    models?.maxNewDebt.value,
  ].every((x) => x === null);
  const hasBenchmarks =
    (benchmarking?.data && JSON.stringify(benchmarking?.data) !== '{}') ||
    false;

  return (
    <>
      {/* used to render charts which are later converted to images for use inside the PDF */}
      <div className="absolute ml-[-99999px] mt-[-99999px]">
        <div id="scaleOfBusiness" />
        <div id="growth" />
        <div id="marginProfile" />
        <div id="marginImprovement" />
        <div id="capitalStructure" />
        <div id="capitalStructureBenchmark" />
      </div>
      {insights && !loading && isMounted ? (
        <>
          <DownloadLinkContainer title="Manual AFI">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/mafi-connect?company_id=${insights.profile.companyInformation.icmId}`}
            >
              <ArrowTopRightOnSquareIcon
                width={24}
                height={24}
                className="mr-6 cursor-pointer"
                color="#7E899A"
              />
            </Link>
            <PDFDownloadLink
              document={
                <FinancialInsightsDocument
                  insights={insights}
                  hasProprietaryScoringModels={hasProprietaryScoringModels}
                  hasBenchmarks={hasBenchmarks}
                />
              }
              fileName={`${(
                insights?.profile?.companyInformation?.companyName ?? ''
              ).replace(/ /g, '_')}_financial_insights-${
                insights?.profile?.companyInformation?.createdAtDate ?? ''
              }.pdf`}
            >
              <Icon.Download className="mr-2" />
            </PDFDownloadLink>
          </DownloadLinkContainer>
          <DownloadLinkContainer title="Company Profile">
            <PDFDownloadLink
              document={
                <FinancialInsightsDocument
                  insights={insights}
                  section={FinancialInsightsDocumentSections.CompanyProfile}
                  hasProprietaryScoringModels={hasProprietaryScoringModels}
                  hasBenchmarks={hasBenchmarks}
                />
              }
              fileName={`${(
                insights?.profile?.companyInformation?.companyName ?? ''
              ).replace(/ /g, '_')}_financial_insights-${
                insights?.profile?.companyInformation?.createdAtDate ?? ''
              }.pdf`}
            >
              <Icon.Download className="mr-2" />
            </PDFDownloadLink>
          </DownloadLinkContainer>
          <DownloadLinkContainer title="ICM Evaluation">
            <PDFDownloadLink
              document={
                <FinancialInsightsDocument
                  insights={insights}
                  section={FinancialInsightsDocumentSections.IcmEvaluation}
                  hasProprietaryScoringModels={hasProprietaryScoringModels}
                  hasBenchmarks={hasBenchmarks}
                />
              }
              fileName={`${(
                insights?.profile?.companyInformation?.companyName ?? ''
              ).replace(/ /g, '_')}_financial_insights-${
                insights?.profile?.companyInformation?.createdAtDate ?? ''
              }.pdf`}
            >
              <Icon.Download className="mr-2" />
            </PDFDownloadLink>
          </DownloadLinkContainer>
          {hasProprietaryScoringModels && (
            <DownloadLinkContainer title="Proprietary Scoring Model">
              <PDFDownloadLink
                document={
                  <FinancialInsightsDocument
                    insights={insights}
                    section={
                      FinancialInsightsDocumentSections.ProprietaryScoringModels
                    }
                    hasProprietaryScoringModels={hasProprietaryScoringModels}
                    hasBenchmarks={hasBenchmarks}
                  />
                }
                fileName={`${(
                  insights?.profile?.companyInformation?.companyName ?? ''
                ).replace(/ /g, '_')}_financial_insights-${
                  insights?.profile?.companyInformation?.createdAtDate ?? ''
                }.pdf`}
              >
                <Icon.Download className="mr-2" />
              </PDFDownloadLink>
            </DownloadLinkContainer>
          )}
          {hasBenchmarks && (
            <DownloadLinkContainer title="Industry Benchmarks">
              <PDFDownloadLink
                document={
                  <FinancialInsightsDocument
                    insights={insights}
                    section={
                      FinancialInsightsDocumentSections.IndustryBenchmarks
                    }
                    hasProprietaryScoringModels={hasProprietaryScoringModels}
                    hasBenchmarks={hasBenchmarks}
                  />
                }
                fileName={`${(
                  insights?.profile?.companyInformation?.companyName ?? ''
                ).replace(/ /g, '_')}_financial_insights-${
                  insights?.profile?.companyInformation?.createdAtDate ?? ''
                }.pdf`}
              >
                <Icon.Download className="mr-2" />
              </PDFDownloadLink>
            </DownloadLinkContainer>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
