import React from 'react';
import { Spinner } from '@hum/ui-library';
import { useAppStore } from '../hooks/useAppStore';

export const withCobranding = (Component: any) => (props: any) => {
  const {
    state: { whitelabeling },
  } = useAppStore();

  if (!whitelabeling.loaded) {
    return <Spinner fullScreen />;
  }

  return (
    <div
      style={
        {
          '--primary-color': whitelabeling.data?.primaryColor,
        } as any
      }
    >
      <Component {...props} />
    </div>
  );
};
