import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { BookmarkedPage, PageHeader } from '../common';
import { BenchmarkRow } from './BenchmarkRow';
import { useBoxPlotDataUrl } from '../hooks';

import {
  IndustryBenchmarksProps,
  SCALE_OF_BUSINESS_VARIABLES,
  GROWTH_VARIABLES,
  MARGIN_PROFILE_VARIABLES,
  MARGIN_IMPROVEMENT_VARIABLES,
  BENCHMARKING_VARIABLE_NAMES,
} from '@hum/types';

import { tw, currency, percentage } from '../utils';

export const IndustryBenchmarks = ({
  companyName,
  industry,
  benchmarking,
  newPage,
}: IndustryBenchmarksProps) => {
  const { header, data, company } = benchmarking;

  const scaleOfBusinessUrl = useBoxPlotDataUrl({
    id: 'scaleOfBusiness',
    title: 'Scale of Business',
    width: 1400,
    height: 600,
    population: SCALE_OF_BUSINESS_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: SCALE_OF_BUSINESS_VARIABLES,
      y: SCALE_OF_BUSINESS_VARIABLES.map(
        (v) => company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: SCALE_OF_BUSINESS_VARIABLES.filter(
      (v) =>
        typeof company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]] ===
        'number'
    ).map((v) => ({
      x: v,
      y: company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]],
      text: currency.format(
        company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    })),
  });

  const growthUrl = useBoxPlotDataUrl({
    id: 'growth',
    title: 'Growth %',
    width: 1400,
    height: 600,
    population: GROWTH_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: GROWTH_VARIABLES,
      y: GROWTH_VARIABLES.map(
        (v) => company.growth[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: GROWTH_VARIABLES.filter(
      (v) => typeof company.growth[BENCHMARKING_VARIABLE_NAMES[v]] === 'number'
    ).map((v) => ({
      x: v,
      y: company.growth[BENCHMARKING_VARIABLE_NAMES[v]],
      text: percentage.format(company.growth[BENCHMARKING_VARIABLE_NAMES[v]]),
    })),
  });

  const marginProfileUrl = useBoxPlotDataUrl({
    id: 'marginProfile',
    title: 'Margin Profile %',
    width: 1400,
    height: 600,
    population: MARGIN_PROFILE_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: MARGIN_PROFILE_VARIABLES,
      y: MARGIN_PROFILE_VARIABLES.map(
        (v) => company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: MARGIN_PROFILE_VARIABLES.filter(
      (v) =>
        typeof company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]] ===
        'number'
    ).map((v) => ({
      x: v,
      y: company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]],
      text: percentage.format(
        company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    })),
  });

  const marginImprovementUrl = useBoxPlotDataUrl({
    id: 'marginImprovement',
    title: 'Margin Improvement %',
    width: 1400,
    height: 600,
    population: MARGIN_IMPROVEMENT_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: MARGIN_IMPROVEMENT_VARIABLES,
      y: MARGIN_IMPROVEMENT_VARIABLES.map(
        (v) => company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: MARGIN_IMPROVEMENT_VARIABLES.filter(
      (v) =>
        typeof company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]] ===
        'number'
    ).map((v) => ({
      x: v,
      y: company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]],
      text: percentage.format(
        company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    })),
  });

  return (
    <BookmarkedPage title="Benchmarking" break={newPage}>
      <PageHeader title="Industry Benchmarks" />
      <View style={tw('border-b-4', 'border-b-palatinate-blue', 'm-1', 'mt-0')}>
        <Text style={tw('text-body', 'mb-1', 'w-3/4')}>
          Your financial performance indicators are benchmarked against industry
          peers on Hum’s ICM over the same trailing-twelve-month(TTM) period.
        </Text>
        <Text style={tw('text-h4', 'mb-1')}>
          The following charts demonstrate how your business compares to{' '}
          {header.numberOfSimilarCompanies} peers in your industry, {industry},
          on Hum’s ICM.
        </Text>
        <Text style={tw('text-body')}>
          1. The percentile rank is determined by comparing your company to
          similar businesses (based on ZoomInfo Industry) in ICM using available
          data for the specific analysis date.
        </Text>
        <Text style={tw('text-body', 'mb-2')}>
          2. The percentile rank will only be calculated if there are more than
          10 peers available for the given time period.
        </Text>
      </View>
      {scaleOfBusinessUrl && (
        <BenchmarkRow
          title="Scale of Business"
          dataUrl={scaleOfBusinessUrl}
          description="Gain valuable insights into how your profitability (revenue, pre-marketing EBITDA, EBITDA), as well as the scale of your operations and investments (inventory, receivables, PPE), compare to your industry peers."
        />
      )}
      {growthUrl && (
        <BenchmarkRow
          title="Growth %"
          dataUrl={growthUrl}
          description="This chart provides a view of your growth over (TTM / the past Quarter). It will help you to identify operational strengths and weaknesses. "
          break
        />
      )}
      {marginProfileUrl && (
        <BenchmarkRow
          title="Margin Profile"
          dataUrl={marginProfileUrl}
          description="This compares the profitability of your business relative to peers. It provides a clear understanding of how efficiently your company operates compared the industry. Note: industry grouping does not consider the size of the business. "
        />
      )}
      {marginImprovementUrl && (
        <BenchmarkRow
          title="Margin Improvement"
          dataUrl={marginImprovementUrl}
          description="This chart compares your company’s ability to improve margins relative to your peers. It provides insights into your competitive position and highlights opportunities for enhancing profitability. "
        />
      )}
    </BookmarkedPage>
  );
};
