import { syndicationMatchOutReasonsHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';

export const useSyndicationMatchOutReasons = () => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    dispatch(syndicationMatchOutReasonsHookUsed());
  }, []);

  return state.syndicationMatchOutReasons;
};
