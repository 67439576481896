import { useCompany } from '@hum/icm-app/src/hooks/useCompany';
import { useAppStore } from '../useAppStore';
import { getUserCompanyId } from '../../state';

export const useCurrentCompany = () => {
  const { state } = useAppStore();
  const companyId = getUserCompanyId(state);

  return useCompany(companyId);
};
