import React, { useEffect } from 'react';
import { Glossary } from './Glossary/Glossary';
import { CompanyProfile } from './CompanyProfile';
import { IndustryBenchmarks } from './IndustryBenchmarks';
import { Financing } from './Financing';
import { IcmEvaluation } from './IcmEvaluation';
import { FinancialInsights } from '@hum/types';
import ScrollButton from './ScrollButton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FinancialInsightsDocument } from '../FinancialInsights';
import { ProprietaryScoringModels } from './ProprietaryScoringModels';
import { useMounted } from '../FinancialInsights/FinancialInsightsDocument/hooks';
import { Spinner } from '@hum/ui-library';

export const MafiConnect = ({ insights }: { insights: FinancialInsights }) => {
  const isMounted = useMounted();
  const { profile, investorPreferences, benchmarking } = insights || {};
  const { humsProprietaryScoringModels } = profile || {};
  const { models } = humsProprietaryScoringModels || {};
  const hasBenchmarks =
    JSON.stringify(insights?.benchmarking.data) !== '{}' || false;
  const hasProprietaryScoringModels = ![
    models?.maxDebt.value,
    models?.maxNewDebt.value,
  ].every((x) => x === null);

  useEffect(() => {
    document.body.classList.add('bg-white');
  }, []);

  return insights && isMounted ? (
    <>
      {/* used to render charts which are later converted to images for use inside the PDF */}
      <div className="absolute ml-[-99999px] mt-[-99999px]">
        <div id="scaleOfBusiness" />
        <div id="growth" />
        <div id="marginProfile" />
        <div id="marginImprovement" />
        <div id="capitalStructure" />
        <div id="capitalStructureBenchmark" />
      </div>
      <PDFDownloadLink
        document={
          <FinancialInsightsDocument
            insights={insights}
            hasProprietaryScoringModels={hasProprietaryScoringModels}
            hasBenchmarks={hasBenchmarks}
          />
        }
        fileName={`${(
          insights?.profile?.companyInformation?.companyName ?? ''
        ).replace(/ /g, '_')}_financial_insights-${
          insights?.profile?.companyInformation?.createdAtDate ?? ''
        }.pdf`}
      >
        <ScrollButton />
      </PDFDownloadLink>
      <CompanyProfile {...profile} />
      {humsProprietaryScoringModels && (
        <ProprietaryScoringModels {...profile} newPage />
      )}
      {hasBenchmarks && (
        <>
          <IndustryBenchmarks
            companyName={profile.companyInformation.companyName}
            industry={profile.companyInformation.industry}
            benchmarking={benchmarking}
            companyMetrics={investorPreferences.header.companyMetrics}
            newPage
          />
          <Financing
            companyName={profile.companyInformation.companyName}
            benchmarking={benchmarking}
          />
        </>
      )}
      <IcmEvaluation {...investorPreferences} newPage />
      <Glossary />
    </>
  ) : (
    <Spinner fullScreen />
  );
};
