import { useDispatch } from 'react-redux';
import { sendTransactionDocumentButtonClicked } from '@hum/icm-app/src/actions';

export const useTransactionDocuments = ({
  companyId,
  targetCompanyId,
  matchUuid,
}: {
  companyId: number;
  targetCompanyId: number;
  matchUuid: string;
}) => {
  const dispatch = useDispatch();

  return {
    onSendTransactionDocumentButtonClicked: () =>
      dispatch(sendTransactionDocumentButtonClicked({ companyId, matchUuid })),
    fileMeta: {
      transaction_document: true,
      target_company_id: targetCompanyId,
    },
  };
};
