import { syndicationMatchDataroomHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';

export const useSyndicationMatchDataroom = (companyId?: number) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (companyId) {
      dispatch(syndicationMatchDataroomHookUsed({ companyId }));
    }
  }, [companyId]);

  return state.syndicationMatchDataRoom;
};
