import React from 'react';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';

import { CompanyProfile as CompanyProfileType } from '@hum/types';
import { BookmarkedPage } from './common';

import { tw, currency, decimal, titleCase } from './utils';

export type CompanyProfileProps = CompanyProfileType;

export const CompanyProfile = ({
  companyInformation,
  currentDealDetails,
  historicalFundingInformation,
}: CompanyProfileProps) => {
  const investors =
    historicalFundingInformation.investors.length > 3
      ? historicalFundingInformation.investors
          .slice(0, 3)
          .map(titleCase)
          .join(', ') + '...'
      : historicalFundingInformation.investors.map(titleCase).join(', ');

  return (
    <BookmarkedPage title="Company Profile">
      <View style={tw('grow', 'flex-row')}>
        <View style={tw('p-1', 'bg-ghost-white', 'w-1/3')}>
          <Image
            style={[tw('mb-1'), { width: '304px', height: '128px' }]}
            src="https://assets.humcapital.com/hum-logo-rgb.png"
          />
          <Text style={tw('text-h3')}>
            Thank you for connecting to Hum Capital!
          </Text>
          <Link style={tw('text-link', 'mb-1')} src="https://humcapital.com">
            https://humcapital.com
          </Link>
          <Text style={tw('text-body', 'mb-1')}>
            By connecting your financial data to Hum Capital’s Intelligent
            Capital Market (ICM) you get access to how Hum Capital and the
            investors on the ICM evaluate at your business. You can leverage
            this data to understand how your company stacks up against your
            industry peers, which areas you excel, and which areas would
            increase your company’s potential to receive a non-dilutive funding
            opportunity.
          </Text>
          <Text style={tw('text-body')}>
            For more information on the terms within this document, see the{' '}
            <Link style={tw('text-action-cta-default')} src="#Glossary">
              glossary
            </Link>
            .
          </Text>
        </View>
        <View style={tw('p-1', 'w-2/3')}>
          <Text style={tw('text-h2', 'my-1')}>
            {companyInformation.companyName}
          </Text>
          <Link
            style={tw('text-link', 'my-0.5')}
            src={companyInformation.website}
          >
            {companyInformation.website}
          </Link>
          <Text style={tw('text-body-small', 'mb-1')}>
            ICM ID: {companyInformation.icmId}
          </Text>
          <Table
            style={tw(
              'border-none',
              'my-1',
              'border-b-2',
              'border-b-palatinate-blue'
            )}
            weightings={[0.3, 0.7]}
            tdStyle={tw('border-none', 'p-0.5')}
          >
            <TH
              style={tw('bg-indigo-dye', 'text-h4', 'text-white', 'uppercase')}
            >
              <TD weighting={1}>Company Information</TD>
            </TH>
            <TR>
              <TD style={tw('bg-alice-blue', 'text-label')}>Industry</TD>
              <TD style={tw('text-body')}>{companyInformation.industry}</TD>
            </TR>
            <TR>
              <TD style={tw('bg-alice-blue', 'text-label')}>
                Has recurring revenue
              </TD>
              <TD style={tw('text-body')}>
                {companyInformation.hasRecurringRevenu ? 'True' : 'False'}
              </TD>
            </TR>
            {companyInformation.runwayInQuarters !== null && (
              <TR _lastRow>
                <TD style={tw('bg-alice-blue', 'text-label')}>
                  Runway (Num of quarters)
                </TD>
                <TD style={tw('text-body')}>
                  {decimal.format(companyInformation.runwayInQuarters)}
                </TD>
              </TR>
            )}
          </Table>
          {Object.values(historicalFundingInformation).some((v) =>
            Array.isArray(v) ? v.length > 0 : !!v
          ) && (
            <Table
              style={tw(
                'border-none',
                'my-1',
                'border-b-2',
                'border-b-palatinate-blue'
              )}
              weightings={[0.3, 0.7]}
              tdStyle={tw('border-none', 'p-0.5')}
            >
              <TH
                style={tw(
                  'bg-indigo-dye',
                  'text-h4',
                  'text-white',
                  'uppercase'
                )}
              >
                <TD weighting={1}>Historical Funding Information</TD>
              </TH>
              {!!investors && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>Investors</TD>
                  <TD style={tw('text-body')}>{investors}</TD>
                </TR>
              )}
              {!!historicalFundingInformation.historicalRaiseTypes.length && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Historical raise types
                  </TD>
                  {historicalFundingInformation.historicalRaiseTypes.length >
                    0 && (
                    <TD style={tw('text-body')}>
                      {historicalFundingInformation.historicalRaiseTypes.join(
                        ', '
                      )}
                    </TD>
                  )}
                </TR>
              )}
              {historicalFundingInformation.totalCapitalRaised !== null && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Total capital raised
                  </TD>
                  <TD style={tw('text-body')}>
                    {currency.format(
                      historicalFundingInformation.totalCapitalRaised
                    )}
                  </TD>
                </TR>
              )}
              {historicalFundingInformation.totalEquityRaised !== null && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Total equity raised
                  </TD>
                  {historicalFundingInformation.totalEquityRaised !== null && (
                    <TD style={tw('text-body')}>
                      {currency.format(
                        historicalFundingInformation.totalEquityRaised
                      )}
                    </TD>
                  )}
                </TR>
              )}
              {historicalFundingInformation.totalDebtRaised !== null && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Total debt raised
                  </TD>
                  <TD style={tw('text-body')}>
                    {currency.format(
                      historicalFundingInformation.totalDebtRaised
                    )}
                  </TD>
                </TR>
              )}
              {historicalFundingInformation.latestRaiseDate !== null && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Latest raise date
                  </TD>
                  <TD style={tw('text-body')}>
                    {historicalFundingInformation.latestRaiseDate}
                  </TD>
                </TR>
              )}
              {historicalFundingInformation.latestRaiseType &&
                historicalFundingInformation.latestRaiseType !== null && (
                  <TR>
                    <TD style={tw('bg-alice-blue', 'text-label')}>
                      Latest raise type
                    </TD>
                    <TD style={tw('text-body')}>
                      {historicalFundingInformation.latestRaiseType}
                    </TD>
                  </TR>
                )}
              {historicalFundingInformation.latestRaiseAmount !== null && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Latest raise amount
                  </TD>
                  <TD style={tw('text-body')}>
                    {currency.format(
                      historicalFundingInformation.latestRaiseAmount
                    )}
                  </TD>
                </TR>
              )}
            </Table>
          )}
          {Object.values(currentDealDetails).some((v) => !!v) && (
            <Table
              style={tw(
                'border-none',
                'my-1',
                'border-b-2',
                'border-b-palatinate-blue'
              )}
              weightings={[0.3, 0.7]}
              tdStyle={tw('border-none', 'p-0.5')}
            >
              <TH
                style={tw(
                  'bg-indigo-dye',
                  'text-h4',
                  'text-white',
                  'uppercase'
                )}
              >
                <TD weighting={1}>Current Deal Details</TD>
              </TH>
              {!!currentDealDetails.dealType && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>Deal type</TD>
                  <TD style={tw('text-body')}>{currentDealDetails.dealType}</TD>
                </TR>
              )}
              {!!currentDealDetails.useOfProceeds && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Use of proceeds
                  </TD>
                  <TD style={tw('text-body')}>
                    {currentDealDetails.useOfProceeds}
                  </TD>
                </TR>
              )}
              {currentDealDetails.expectedRaiseAmount !== null && (
                <TR>
                  <TD style={tw('bg-alice-blue', 'text-label')}>
                    Expected raise amount
                  </TD>
                  <TD style={tw('text-body')}>
                    {currency.format(currentDealDetails.expectedRaiseAmount)}
                  </TD>
                </TR>
              )}
            </Table>
          )}
        </View>
      </View>
    </BookmarkedPage>
  );
};
