import { useCallback } from 'react';

import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { useEnvelope } from '@hum/icm-app/src/hooks/useEnvelope';
import { Result } from '@hum/common/src/ducks/state';
import {
  EnvelopeStatus,
  Envelope,
  DocusignMNDAStatus,
} from '@hum/icm-app/src/state';
import { CompanyFileDocumentType } from '@hum/types';
import { MndaOptions } from '@hum/icm-app/src/components/types';

export const getMNDADocumentStatus = ({
  acceptedNDA,
  acceptedNDAOn,
  envelope,
}: {
  acceptedNDA?: boolean;
  acceptedNDAOn?: string;
  envelope?: Result<Envelope>;
}) => {
  if (
    envelope &&
    [
      EnvelopeStatus.Signed,
      EnvelopeStatus.PendingDownload,
      EnvelopeStatus.Completed,
    ].includes(envelope.data?.status!)
  ) {
    return DocusignMNDAStatus.WaitingForFile;
  }
  if (acceptedNDA && acceptedNDAOn) {
    return DocusignMNDAStatus.AcceptedMarkdownV2;
  }
  if (acceptedNDA) {
    return DocusignMNDAStatus.AcceptedMarkdownV1;
  }
  return DocusignMNDAStatus.WaitingForSignature;
};

const getMndaFile = (files, company) => {
  if (company && company.pendingMndaSignOffline === MndaOptions.Pending) {
    return files.find(
      (file) => file.documentType === CompanyFileDocumentType.CompanyMnda
    );
  }

  return files.find(
    (file) => file.documentType === CompanyFileDocumentType.DocusignMnda
  );
};

export const useMNDA = () => {
  const company = useCurrentCompany();
  const fileManager = useFileManager({ companyId: Number(company.data?.id) });
  const envelope = useEnvelope(Number(company.data?.id));

  const mndaFile = getMndaFile(fileManager.files, company.data);
  const downloadMNDA = useCallback(() => {
    fileManager.downloadFile(mndaFile!.id);
  }, [fileManager, mndaFile]);

  return {
    loading: !company.data || fileManager.loading || !envelope.loaded,
    envelope,
    envelopeStatus: getMNDADocumentStatus({
      acceptedNDA: company.data?.acceptedNDA,
      acceptedNDAOn: company.data?.acceptedNDAOn,
      envelope,
    }),
    isMndaDocumentDownloaded:
      envelope.data?.status === EnvelopeStatus.Downloaded,
    hasEnvelope: [
      EnvelopeStatus.Created,
      EnvelopeStatus.Sent,
      EnvelopeStatus.Delivered,
    ].includes(envelope.data?.status!),
    downloadMNDA,
  };
};
