import React from 'react';
import { useApiQuery, getFinancialInsights } from '@hum/api';
import { useUrlQuery } from '@hum/common/src/hooks/useUrlQuery';
import { MafiConnect } from './MafiConnect';
import { Spinner } from '@hum/ui-library';

export const MafiConnectPage = () => {
  const [{ company_id }] = useUrlQuery();
  const companyId = Number(company_id) || 0;
  const { data: insights, isLoading: insightsLoading } = useApiQuery(
    getFinancialInsights,
    {
      params: {
        companyId: companyId,
      },
    }
  );

  return !insightsLoading && insights ? (
    <MafiConnect insights={insights} />
  ) : (
    <Spinner fullScreen />
  );
};
