import React from 'react';
import invert from 'lodash/invert';
import { SelectInput, SelectInputProps, SelectOption } from '@hum/legacy-ui';
import {
  NAICS_INDUSTRY_GROUP_SELECT_OPTIONS,
  NAICS_INDUSTRY_GROUP_OPTIONS,
} from '@hum/types';

export const NAICS_INDUSTRY_GROUP_OPTIONS_BY_LABEL = invert(
  NAICS_INDUSTRY_GROUP_OPTIONS
);
type IndustrySelectType = {
  showPlaceholder?: boolean;
};
export function IndustrySelect({
  value,
  showPlaceholder,
  ...rest
}: SelectInputProps & IndustrySelectType) {
  const selectedValue = value as string;
  const selectedIndustryExists = !!NAICS_INDUSTRY_GROUP_OPTIONS[selectedValue];
  const otherIndustryOptions: SelectOption[] = selectedIndustryExists
    ? []
    : showPlaceholder
    ? []
    : [{ label: selectedValue, value: selectedValue }];
  return (
    <SelectInput
      {...rest}
      placeholder=""
      value={value}
      aria-label="Industry"
      options={[
        ...NAICS_INDUSTRY_GROUP_SELECT_OPTIONS,
        ...otherIndustryOptions,
      ]}
    />
  );
}
