import { API, APIContext } from '../../backend';
import { useContext, useState } from 'react';
import { Response } from '@hum/common/src/api/response';

/**
 * Restored from deprecation. useApi should only be used inside
 * other hooks to provide decoupling from the presentation layer.
 */
type ApiCallState<T> = {
  data: T | null;
  error: boolean;
  loading: boolean;
};

export const useAPI = () => {
  return useContext(APIContext) as API;
};

export const useApiCall = <T>() => {
  const api = useContext(APIContext) as API;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const handleResponse = (response: Response<T>) => {
    if (response.ok) {
      setData(response.data);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  return {
    result: { data, error, loading } as ApiCallState<T>,
    handleResponse,
    handleError,
    api,
  };
};
