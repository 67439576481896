import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import "./insight.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var InsightCards = React.memo(React.forwardRef(function InsightCards(props, ref) {
  return React.createElement("div", {
    "className": "_884537d6 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_insight_cards _pub-348a5c93_insight_cards insight_cards " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { InsightCards };

var InsightCard = React.memo(React.forwardRef(function InsightCard(props, ref) {
  return React.createElement("div", {
    "className": "_612692e3 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_insight_card _pub-348a5c93_insight_card insight_card " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { InsightCard };

var InsightText = React.memo(React.forwardRef(function InsightText(props, ref) {
  return React.createElement("div", {
    "className": "_8f28f3cf _348a5c93 _pub-348a5c93" + " " + "_348a5c93_insight_text _pub-348a5c93_insight_text insight_text " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_2ffc9fb3 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_inside_container _pub-348a5c93_inside_container inside_container",
    }, 
      React.createElement("div", {
        "className": "_e87224ee _348a5c93 _pub-348a5c93" + " " + "_348a5c93_title _pub-348a5c93_title title",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_9f751478 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_description _pub-348a5c93_description description",
      }, 
        props["description"]
      )
    )
  )
}));
export { InsightText };

var InsightChart = React.memo(React.forwardRef(function InsightChart(props, ref) {
  return React.createElement("div", {
    "className": "_6890dec8 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_insight_chart _pub-348a5c93_insight_chart insight_chart " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_2562b2b9 _348a5c93 _pub-348a5c93",
    }, 
      React.createElement("div", {
        "className": "_57429a8f _348a5c93 _pub-348a5c93" + " " + "_348a5c93_title _pub-348a5c93_title title",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_2045aa19 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_chart _pub-348a5c93_chart chart",
      }, 
        props["children"]
      )
    )
  )
}));
export { InsightChart };

var DummyChart = React.memo(React.forwardRef(function DummyChart(props, ref) {
  return React.createElement("div", {
    "className": "_ded2bfe3 _348a5c93 _pub-348a5c93" + " " + "_348a5c93_dummy_chart_for_preview _pub-348a5c93_dummy_chart_for_preview dummy_chart_for_preview",
    "ref": ref,
  }, 
    "\n  should show a Chart\n"
    
  )
}));
export { DummyChart };


