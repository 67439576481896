// Libraries
import { useEffect } from 'react';
// Components
import {
  investorCompaniesHookUsed,
  investorAvailableCompaniesHookUsed,
} from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { SelectOption } from '@hum/legacy-ui';

export const useAvailableCompanies = (companyId: number): SelectOption[] => {
  const {
    state: { investorAvailableCompanies },
    dispatch,
  } = useAppStore();

  useEffect(() => {
    dispatch(investorAvailableCompaniesHookUsed({ companyId }));
  }, [companyId]);

  return investorAvailableCompanies.map(({ name, id }) => ({
    label: name,
    value: `${id}`,
  }));
};

export const usePortfolioCompanies = (companyId: number) => {
  const {
    state: { investorCompanies },
    dispatch,
  } = useAppStore();

  useEffect(() => {
    dispatch(investorCompaniesHookUsed({ companyId }));
  }, [companyId]);

  return investorCompanies;
};
