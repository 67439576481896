import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { syndicationLoadOutOfFeeFilesHookUsed } from '@hum/icm-app/src/actions';

export const useSyndicationOutOfFeeProofFiles = (
  companyId: number,
  matchId: number
) => {
  const { state, dispatch } = useAppStore();

  const loadOutOfFeeFiles = () => {
    dispatch(syndicationLoadOutOfFeeFilesHookUsed({ companyId, matchId }));
  };

  return {
    syndicationMatchOutOfFeeFiles: state.syndicationMatchOutOfFeeFiles,
    loadOutOfFeeFiles,
  };
};
