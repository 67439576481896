import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { Accordion } from './Accordion';

export const Glossary = () => {
  const glossaryData = [
    {
      title: '(Q)',
      description:
        'Pertaining to the quarter ending on the “Financial Statement data” date. For example, Revenue (Q) is revenue in the quarter ending on the financial statement date.',
    },
    {
      title: 'TTM',
      description:
        'Pertaining to the 12-month period ending on the “Financial Statement date” date.',
    },
    {
      title: '75TH PERCENTILE PERFORMANCE',
      description:
        'When available, the levels of metrics needed to be in the top quartile versus similar peers. These are calculated both for the most recent values, and also for year over year growth rates.',
    },
    {
      title: 'CASH',
      description: 'Cash balances, as of the “Financial Statement data” date.',
    },
    {
      title: 'COMPANY’S RANK',
      description:
        'When available, a ranking on a scale of 1-100 (100 is best) of a company’s metrics versus similar peers. These are calculated both for the most recent metric values, and for the year over year growth rates in those metrics.',
    },
    {
      title: 'EBITDA % (Q)',
      description:
        'Earnings, excluding interest, taxes, depreciation, amortization, expressed as a percent of Revenues.',
    },
    {
      title: 'EQUITY CAPITAL',
      description: 'The total amount of equity capital a company has raised.',
    },
    {
      title: 'EXPECTED RAISE AMOUNT',
      description: 'The company’s self-reported desired fundraising amount.',
    },
    {
      title: 'GROSS MARGIN',
      description:
        'Revenues minus Cost of Goods Sold, expressed as a percent of Revenues.',
    },
    {
      title: 'GROWTH SCORE',
      description:
        'A Hum-proprietary score, on a scale of 1-100, with 100 being best, measuring a company’s revenue growth versus similar companies Inventory of goods for sale, as of the “Financial Statement data” date.',
    },
    {
      title: 'MAX NEW DEBT',
      description: 'The difference between max debt and existing total debt.',
    },
    {
      title: 'OPERATING EFFICIENCY SCORE',
      description:
        'A Hum-proprietary score, on a scale of 1-100, with 100 being best, measuring a company’s profitability versus similar companies.',
    },
    {
      title: 'PAYABLES',
      description:
        'Accounts Payable, as of the “Financial Statement data” date.',
    },
    {
      title: 'PRE-MARKETING EBITDA %',
      description:
        'Earnings, excluding interest, taxes, depreciation, amortization, and sales and marketing expenses, expressed as a percent of Revenues.',
    },
    {
      title: 'RECEIVABLES',
      description:
        'Accounts Receivable, as of the “Financial Statement data” date.',
    },
    {
      title: 'RUNWAY',
      description:
        'The ratio of Cash to EBITDA, for companies with negative EBITDA, measured in months. Not relevant for companies with positive EBITDA.',
    },
    {
      title: 'S&M %',
      description:
        'Sales and Marketing expenses, expressed as a percent of Revenues.',
    },
    {
      title: 'TOTAL DEBT',
      description:
        'Total short and long term debt, as of the “Financial Statement data” date.',
    },
    {
      title: 'WC DAYS',
      description:
        'Working capital, expressed as a fraction of the past year’s revenues (shown in days). Formula: (Working Capital / Revenues) * 365 Working capital - Current assets minus current liabilities.',
    },
  ];

  return (
    <section className="p-4 container mx-auto">
      <div className="w-full md:w-1/3">
        <Text type={TextType.PAGE_HEADING} className="text-black mb-4">
          Glossary
        </Text>
      </div>
      <div className="grid grid-cols-2 gap-6">
        {glossaryData.map((item, index) => (
          <Accordion
            key={index}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
};
