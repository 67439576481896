import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  syndicationMatchHookUsed,
  syndicationInvestorV2MatchesHookUsed,
  syndicationMatchEventsHookUsed,
  syndicationMatchesHookUsed,
  syndicationConditionsNeedingAcceptanceHookUsed,
} from '@hum/icm-app/src/actions';
import { resourceHook } from '../utils';
import { useEffect } from 'react';
import { CompanyType } from '@hum/types';
import {
  getSyndicationMatchEventResult,
  getUserCompanyId,
  SyndicationSectionDeals,
} from '@hum/icm-app/src/state';

export const useSyndicationMatch = resourceHook(
  syndicationMatchHookUsed,
  (state) => state.syndicationMatch
);

export const useSyndicationMatches = () => {
  const { state, dispatch } = useAppStore();
  const companyId = getUserCompanyId(state);

  useEffect(() => {
    dispatch(syndicationMatchesHookUsed({ companyId }));
  }, [companyId]);

  return state.syndicationMatches;
};

export const useSyndicationInvestorV2Matches = (
  companyId: number,
  sectionDeals: SyndicationSectionDeals | undefined
) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (sectionDeals) {
      dispatch(
        syndicationInvestorV2MatchesHookUsed({ companyId, sectionDeals })
      );
    }
  }, [sectionDeals]);

  return state.syndicationMatches;
};

export const useSyndicationMatchEvents = (
  companyId: number,
  matchId: number
) => {
  const { state, dispatch } = useAppStore();

  const loadEvents = () => {
    dispatch(syndicationMatchEventsHookUsed({ companyId, matchId }));
  };

  return {
    ...getSyndicationMatchEventResult(state.syndicationMatchEvents, matchId),
    loadEvents,
  };
};

export const useSyndicationConditionsNeedingAcceptance = (
  companyId: number,
  companyType: CompanyType[]
) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (companyType.includes(CompanyType.SyndicationInvestor))
      dispatch(syndicationConditionsNeedingAcceptanceHookUsed({ companyId }));
  }, []);

  return state.syndicationConditionsNeedingAcceptance;
};
