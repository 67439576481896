import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pub9F4190D6 from "../../../../design-system/src/icons.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import _pub9C2Dcb51 from "../../../../design-system/src/Pipe.pc";
import _pub6Ad54177, {CoachMarkAnchorPreview as _pub6Ad54177_CoachMarkAnchorPreview} from "../../../../design-system/src/CoachMark.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_bc69a874 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3" + " " + "_8ac8c6b9_rebrand _pub-8ac8c6b9_rebrand _pub-a97d53f3_rebrand rebrand " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + " " + " _8ac8c6b9_v3 _pub-8ac8c6b9_v3 _pub-a97d53f3_v3 v3" + (props["slim"] ? " " + "_8ac8c6b9_slim _pub-8ac8c6b9_slim _pub-a97d53f3_slim slim" : "") + (props["primary"] ? " " + "_8ac8c6b9_primary _pub-8ac8c6b9_primary _pub-a97d53f3_primary primary" : "") + (props["fixedBar"] ? " " + "_8ac8c6b9_fixed-bar _pub-8ac8c6b9_fixed-bar _pub-a97d53f3_fixed-bar fixed-bar" : ""),
    "ref": ref,
  }, 
    props["slim"]&&React.createElement("div", {
      "className": "_5354751 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
    }, 
      props["logo"]||React.createElement(React.Fragment, {
      }, 
        React.createElement("img", {
          "className": "_c91b597e _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/logo.svg")),
        }, null),
        React.createElement("img", {
          "className": "_be1c69e8 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/hum-logo-circle.svg")),
        }, null)
      ),
      props["title"]&&React.createElement(_pub9C2Dcb51, {
        "class": "_9993005e _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
      }, null),
      React.createElement("div", {
        "className": "_b5f01b6 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
      }, 
        props["title"]
      )
    ),
    props["primary"]&&React.createElement("div", {
      "className": "_1c2e7610 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
    }, 
      props["logo"]
    ),
    props["leftControls"]&&React.createElement("div", {
      "className": "_536fe0d7 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
    }, 
      props["leftControls"]
    ),
    React.createElement("div", {
      "className": "_a41ea772 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
    }, 
      props["rightControls"]
    )
  )
}));
export default $$Default;

var TopbarNavList = React.memo(React.forwardRef(function TopbarNavList(props, ref) {
  return React.createElement("ul", {
    "className": "_5bd18573 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { TopbarNavList };

var TopbarNavListTab = React.memo(React.forwardRef(function TopbarNavListTab(props, ref) {
  return React.createElement("li", {
    "className": "_ec4e956b _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3" + (props["active"] ? " " + "_8ac8c6b9_active _pub-8ac8c6b9_active _pub-a97d53f3_active active" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { TopbarNavListTab };

var TopbarHelpButton = React.memo(React.forwardRef(function TopbarHelpButton(props, ref) {
  return React.createElement("button", {
    "className": "_240f447 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
    "ref": ref,
  }, null)
}));
export { TopbarHelpButton };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement($$Default, {
    "class": "_eb235172",
    "ref": ref,
    "slim": props["slim"],
    "title": props["title"],
    "primary": !props["slim"],
    "rebrand": props["rebrand"],
    "logo": React.createElement(React.Fragment, {
    }, 
      React.createElement("a", {
        "className": "_8e24e1f6 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
        "href": "#",
      }, 
        React.createElement("img", {
          "className": "_d2c1624d _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/hum-logo-circle.svg")),
        }, null)
      )
    ),
    "leftControls": React.createElement(React.Fragment, {
    }, 
      !props["slim"]&&React.createElement(TopbarNavList, {
        "class": "_f8e8bcbf",
      }, 
        React.createElement(TopbarNavListTab, {
          "class": "_e6a0cd84",
          "active": true,
        }, 
          React.createElement("a", {
            "className": "_fd107d5e _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
            "href": "#",
          }, 
            "Home"
            
          )
        ),
        props["showCoachmark"]&&React.createElement(_pub6Ad54177_CoachMarkAnchorPreview, {
          "class": "_7f30994 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
          "style": castStyle("left: 205px; top: 48px;"),
          "title": "Investor matches",
          "description": "This is where you can see Hum Capital generated analytics, the more data you upload and connect the more insights we can generate",
        }, 
          React.createElement(TopbarNavListTab, {
            "class": "_2bdb88f5",
          }, 
            React.createElement("a", {
              "className": "_cd824d8d _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
              "href": "#",
            }, 
              "Portfolio"
              
            )
          )
        )||React.createElement(TopbarNavListTab, {
          "class": "_7f30994",
        }, 
          React.createElement("a", {
            "className": "_2bdb88f5 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
            "href": "#",
          }, 
            "Portfolio"
            
          )
        ),
        React.createElement(TopbarNavListTab, {
          "class": "_8aeaca8",
        }, 
          React.createElement("a", {
            "className": "_fe94a930 _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3" + " " + "_8ac8c6b9_active _pub-8ac8c6b9_active _pub-a97d53f3_active active",
            "href": "#",
          }, 
            "Syndication"
            
          )
        )
      )
    ),
    "rightControls": React.createElement(React.Fragment, {
    }, 
      React.createElement(TopbarHelpButton, {
        "class": "_84baccfc",
      }, null)
    ),
  }, null)
}));
export { Preview };

var Preview2 = React.memo(React.forwardRef(function Preview2(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_52d305e _8ac8c6b9 _pub-8ac8c6b9 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Preview, {
      "class": "_45cb3de7",
      "slim": true,
      "title": "Topar",
    }, null),
    React.createElement(Preview, {
      "class": "_32cc0d71",
      "title": "Topbar",
    }, null)
  )
}));
export { Preview2 };


