import { PM_dashboardHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';
import { useFlags } from '../useFlags';
import { getPortfolioDashboard, useApiQuery } from '@hum/api';
import { PortfolioMonitoringDashboard } from '../../state';
import { Result } from '@hum/common';

export const usePortfolioDashboard = (companyIds: Array<number>) => {
  const flags = useFlags();
  const { data: portfolioMonitoringDashboard, isLoading } = useApiQuery(
    getPortfolioDashboard,
    {
      params: { companyIds },
      enabled: flags.enabled('use-react-query'),
    }
  );
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(PM_dashboardHookUsed({ companyIds: companyIds }));
    }
  }, companyIds);

  return flags.enabled('use-react-query')
    ? ({
        data: portfolioMonitoringDashboard,
        loaded: !isLoading,
      } as Result<PortfolioMonitoringDashboard>)
    : state.portfolioMonitoringDashboard;
};
