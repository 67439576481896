import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Mousetrap from 'mousetrap';
import { analytics } from '@hum/common';
import { TrackedAnalyticsEvent } from '@hum/common/src/analytics';

const KEY_COMBO = 'up up down down left right left right';

export const DebugTools = () => {
  const {
    visible,
    events,
    close,
    collapsed,
    toggleCollapsed,
    maximized,
    toggleMaximized,
  } = useDebugTools();
  if (!visible) {
    return null;
  }
  return (
    <div
      className={clsx(
        maximized ? 'h-2/4 w-2/4' : 'w-96 h-auto',
        'fixed left-3 top-3 z-[10000] bg-white border-gray-300 rounded-md overflow-y-auto'
      )}
    >
      <div className="pl-3 py-1 flex items-center justify-center bg-gray-100">
        <div
          className="h-3 w-3 bg-red-500 rounded-full border border-red-600 inline-block absolute left-3"
          onClick={close}
        ></div>
        <div
          className="h-3 w-3 bg-yellow-500 rounded-full border border-yellow-600 inline-block absolute left-7"
          onClick={toggleCollapsed}
        ></div>
        <div
          className="h-3 w-3 bg-green-500 rounded-full border border-green-600 inline-block absolute left-11"
          onClick={toggleMaximized}
        ></div>
        <p className="text-gray-900 font-bold text-sm">Tracked events</p>
      </div>
      <div className={clsx(collapsed ? 'h-0' : 'h-auto')}>
        {events
          .filter(
            (eventWithoutError) =>
              eventWithoutError.error === undefined && eventWithoutError
          )
          .map((eventTracked, index) => (
            <div
              className={clsx(
                index % 2 === 0 ? 'bg-white' : 'bg-gray-200',
                'py-2 px-3 whitespace-normal brake-words'
              )}
              key={eventTracked.id}
            >
              <p className="text-gray-600 text-xs">{eventTracked.name} ✅</p>
            </div>
          ))}
      </div>
    </div>
  );
};

const useDebugTools = () => {
  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [maximized, setMaximized] = useState(false);
  const [events, setTrackedEvents] = useState<TrackedAnalyticsEvent[]>([]);
  const close = () => setVisible(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const toggleMaximized = () => setMaximized(!maximized);
  // key bindings for enabling logger
  useEffect(() => {
    const listener = () => {
      setVisible(!visible);
    };

    Mousetrap.bind(KEY_COMBO, listener);
    return () => Mousetrap.unbind(KEY_COMBO, listener);
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      return () => {};
    }

    setTrackedEvents(analytics.debugAnalyticsTracker.all());
    return analytics.debugAnalyticsTracker.onEvent(() => {
      setTrackedEvents(analytics.debugAnalyticsTracker.all());
    });
  }, [visible]);

  return {
    visible,
    events,
    close,
    toggleCollapsed,
    collapsed,
    maximized,
    toggleMaximized,
  };
};
