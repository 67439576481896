import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state';
import { companyDetailActions } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { useEffect } from 'react';

// Note: rename this, this is not showing the visibility but rather downloading the file
export const useFinancialSummaryVisiblity = (companyId: number) => {
  const dispatch = useDispatch();
  const showCompanyFinancialSummary = useSelector(
    (state: AppState) => state.showCompanyFinancialSummary
  );

  useEffect(() => {
    dispatch(
      companyDetailActions.companyFinancialSummaryVisibilityHookUsed({
        companyId,
      })
    );
  }, [companyId]);

  return showCompanyFinancialSummary;
};
