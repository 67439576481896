import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetCompanyTeaserDataRequestSent } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { AppState } from '@hum/icm-app/src/state';

export const useCompanyPublicInfo = (token: string) => {
  const dispatch = useDispatch();
  const { teaserData } = useSelector((state: AppState) => state);
  useEffect(() => {
    if (token) {
      dispatch(apiGetCompanyTeaserDataRequestSent({ token }));
    }
  }, [token]);
  return teaserData.data;
};
