import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import { tw } from '../utils';

export type BenchmarkRowProps = {
  title: string;
  description: string;
  dataUrl: string;
  break?: boolean;
};

export const BenchmarkRow = ({
  title,
  description,
  dataUrl,
  ...props
}: BenchmarkRowProps) => {
  return (
    <View
      style={[tw('flex-row', 'm-1', 'pt-1.5', 'justify-between')]}
      break={props.break || false}
    >
      <View style={[tw('mr-1'), { width: 575 }]}>
        <Text style={tw('text-h4', 'uppercase', 'mb-1')}>{title}</Text>
        <Text style={tw('text-body')}>{description}</Text>
      </View>
      <View style={[tw('ml-1'), { height: 1100 }]}>
        <Image src={dataUrl} />
      </View>
    </View>
  );
};
