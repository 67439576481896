import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { AppState } from '@hum/icm-app/src/state';
import { useEffect } from 'react';

export const resourceHook = <TPayload extends {}, TState>(
  actionCreator: (payload: TPayload) => any,
  selectState: (state: AppState) => TState
) => {
  return (payload: TPayload): TState => {
    const { state, dispatch } = useAppStore();
    useEffect(() => {
      dispatch(actionCreator(payload));
    }, Object.values(payload));

    return selectState(state);
  };
};
