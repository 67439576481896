import React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { CompanyProfile } from '@hum/types';

import { tw } from './utils';

export type FooterProps = CompanyProfile;

export const Footer = ({ companyInformation }: FooterProps) => {
  return (
    <View style={tw('flex-row', 'mx-2', 'my-1')} fixed>
      <Text style={tw('text-body-small', 'grow')}>
        Data captured {companyInformation.createdAtDate} for{' '}
        {companyInformation.companyName} - {companyInformation.icmId} by Hum
        Capital.
      </Text>
      <Text
        style={tw('text-body-small')}
        render={({ pageNumber }) => pageNumber}
      />
    </View>
  );
};
