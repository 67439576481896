import React from 'react';
import { Disclosure } from '@headlessui/react';
import ChevronRightIcon from '@hum/ui-library/src/atoms/Icon/ChevronRightIcon';
import { Text, TextType } from '@hum/ui-library';

export const Accordion = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <Disclosure.Button className="my-2 p-2 w-full">
            <Text
              className="text-black mb-4 flex justify-between items-center"
              type={TextType.HEADING_L}
            >
              {title}
              <ChevronRightIcon className={open ? 'rotate-90 transform' : ''} />
            </Text>
          </Disclosure.Button>
          <Disclosure.Panel className="w-full">
            <Text className="text-black mb-4" type={TextType.BODY_M}>
              {description}
            </Text>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
