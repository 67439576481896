import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '@hum/icm-app/src/state';
import { Action } from '@hum/icm-app/src/actions';

/**
 * @deprecated use react-redux utils instead
 */
export const useAppStore = () => {
  const state: AppState = useSelector(identity);
  const dispatch: Dispatch<Action> = useDispatch();
  return useMemo(() => ({ state, dispatch }), [state, dispatch]);
};

const identity = (state) => state;
