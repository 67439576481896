import React from "react";
import _pub8C547Ae7 from "../../../../design-system/src/Select.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pub1170A9B6, {Container as _pub1170A9B6_Container} from "../../../../design-system/src/ButtonGroup.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_3708b8ef _f6b296a8 _pub-f6b296a8",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement("div", {
    "className": "_d0b095e8 _f6b296a8 _pub-f6b296a8",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_26ef86b5 _f6b296a8 _pub-f6b296a8",
    }, 
      props["children"]
    ),
    React.createElement("div", {
      "className": "_bfe6d70f _f6b296a8 _pub-f6b296a8",
    }, 
      props["resultsText"]
    )
  )
}));
export { Container };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Container, {
    "class": "_8b18beb7",
    "ref": ref,
    "resultsText": "52 results",
  }, 
    !props["hideEnds"]&&React.createElement(_pub2Dbb4493, {
      "class": "_9f70f980 _f6b296a8 _pub-f6b296a8",
    }, 
      "1"
      
    ),
    React.createElement(_pub1170A9B6_Container, {
      "class": "_b77065c6 _f6b296a8 _pub-f6b296a8",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_e7522ad3 _f6b296a8 _pub-f6b296a8",
      }, 
        "1"
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_90551a45 _f6b296a8 _pub-f6b296a8",
        "active": true,
      }, 
        "2"
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_95c4bff _f6b296a8 _pub-f6b296a8",
      }, 
        "3"
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_7e5b7b69 _f6b296a8 _pub-f6b296a8",
      }, 
        "4"
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_e03feeca _f6b296a8 _pub-f6b296a8",
      }, 
        "5"
        
      )
    ),
    !props["hideEnds"]&&React.createElement(_pub2Dbb4493, {
      "class": "_ad469b02 _f6b296a8 _pub-f6b296a8",
    }, 
      "99"
      
    )
  )
}));
export { Preview };

var PreviewOuter = React.memo(React.forwardRef(function PreviewOuter(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_6516df9b _f6b296a8 _pub-f6b296a8" + " " + "v3",
    "ref": ref,
    "padded": true,
    "humDark": props["humDark"],
  }, 
    React.createElement(Preview, {
      "class": "_b4f4b1a8",
      "hideEnds": props["hideEnds"],
      "resultsText": "52 results",
    }, null)
  )
}));
export { PreviewOuter };


