import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pubF37F1587, {Progress as _pubF37F1587_Progress} from "./ProgressCircle/index.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var UploadProgressPopup = React.memo(React.forwardRef(function UploadProgressPopup(props, ref) {
  return React.createElement("div", {
    "className": "_bd085e32 _cf1f37ce _pub-cf1f37ce _pub-a97d53f3" + (props["visible"] ? " " + "_cf1f37ce_visible _pub-cf1f37ce_visible _pub-a97d53f3_visible visible" : "") + (props["fixed"] ? " " + "_cf1f37ce_fixed _pub-cf1f37ce_fixed _pub-a97d53f3_fixed fixed" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_c8878c1a _cf1f37ce _pub-cf1f37ce _pub-a97d53f3",
    }, 
      React.createElement("span", {
        "className": "_640eb57d _cf1f37ce _pub-cf1f37ce _pub-a97d53f3",
      }, 
        props["label"]
      ),
      React.createElement("button", {
        "className": "_fd07e4c7 _cf1f37ce _pub-cf1f37ce _pub-a97d53f3",
        "onClick": props["onCloseClick"],
      }, null)
    ),
    React.createElement("ul", {
      "className": "_21e4292f _cf1f37ce _pub-cf1f37ce _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { UploadProgressPopup };

var Checkmark = React.memo(React.forwardRef(function Checkmark(props, ref) {
  return React.createElement("i", {
    "className": "_53063f1e _cf1f37ce _pub-cf1f37ce _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, null)
}));
export { Checkmark };

var Item = React.memo(React.forwardRef(function Item(props, ref) {
  return React.createElement("li", {
    "className": "_b4be1219 _cf1f37ce _pub-cf1f37ce _pub-a97d53f3" + (props["done"] ? " " + "_cf1f37ce_done _pub-cf1f37ce_done _pub-a97d53f3_done done" : "") + (props["uploading"] ? " " + "_cf1f37ce_uploading _pub-cf1f37ce_uploading _pub-a97d53f3_uploading uploading" : ""),
    "ref": ref,
    "data-progress": props["progress"],
  }, 
    React.createElement("div", {
      "className": "_589424c4 _cf1f37ce _pub-cf1f37ce _pub-a97d53f3" + " " + "_cf1f37ce_file-name _pub-cf1f37ce_file-name _pub-a97d53f3_file-name file-name",
    }, 
      props["fileName"]
    ),
    React.createElement("div", {
      "className": "_c19d757e _cf1f37ce _pub-cf1f37ce _pub-a97d53f3",
    }, 
      React.createElement(_pubF37F1587_Progress, {
        "class": "_a1fe587c _cf1f37ce _pub-cf1f37ce _pub-a97d53f3" + " " + "_cf1f37ce_progress _pub-cf1f37ce_progress _pub-a97d53f3_progress progress",
        "radius": "10px",
        "stroke": "2px",
        "value": props["progress"],
      }, null),
      React.createElement(Checkmark, {
        "class": "_38f709c6" + " " + "_cf1f37ce_checkmark _pub-cf1f37ce_checkmark _pub-a97d53f3_checkmark checkmark",
      }, null)
    )
  )
}));
export { Item };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(UploadProgressPopup, {
    "class": "_41c672ba",
    "ref": ref,
    "visible": true,
    "label": "Uploading 2 items",
  }, 
    React.createElement(Item, {
      "class": "_e70b6f58",
      "fileName": "super-loing-file-name-that-needs-to-be-trimed-and-padded.png",
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_900c5fce",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_9050e74",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_7e023ee2",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_e066ab41",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_97619bd7",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_e68ca6d",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_796ffafb",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null),
    React.createElement(Item, {
      "class": "_e9d0e76a",
      "fileName": "file.png",
      "uploading": true,
      "progress": "100",
    }, null)
  )
}));
export { Preview };


