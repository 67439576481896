import { useEffect } from 'react';

import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { NoticeName } from '@hum/icm-app/src/state';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '../useFlags';
import {
  getNoticesWasSeen,
  setNoticeAsSeen,
  useApiMutation,
  useApiQuery,
} from '@hum/api';

export const useCompanyNotice = (
  noticeName: NoticeName,
  companyId?: number
): [completed: boolean, loaded: boolean, complete: () => void] => {
  const flags = useFlags();
  const { dispatch, state } = useAppStore();
  const api = useAPI();
  const { data: getNotices } = useApiQuery(getNoticesWasSeen, {
    params: {
      companyId,
      noticeName,
    },
  });
  const { mutate: noticeWasSeen } = useApiMutation(setNoticeAsSeen);

  useEffect(() => {
    if (companyId && noticeName) {
      getNotice();
    }
  }, [companyId]);

  const complete = async () => {
    if (!companyId) return;

    return flags.enabled('use-react-query')
      ? noticeWasSeen({ companyId, noticeName })
      : await api.onboarding.setCompanyNoticeAsSeen(dispatch)(
          companyId,
          noticeName
        );
  };

  const getNotice = async () => {
    if (!companyId) return;

    return flags.enabled('use-react-query')
      ? getNotices
      : await api.onboarding.getCompanyNoticeExistence(dispatch)(
          companyId,
          noticeName
        );
  };

  return [
    !!state.companyNotices.data && state.companyNotices.data[noticeName],
    state.companyNotices.loaded,
    complete,
  ];
};
