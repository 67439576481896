import { useEffect } from 'react';
import { brandingHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

export const useBranding = (companyId: number) => {
  const { state, dispatch } = useAppStore();
  useEffect(() => {
    dispatch(brandingHookUsed({ companyId }));
  }, [companyId]);

  return state.branding;
};
