import { User, Company, FileStatus } from '@hum/types';

export enum MndaOptions {
  Default = 'default',
  Pending = 'pending',
}

export type DataDump = {
  id: number;
  dataType: DataDumpType;
  status: DataDumpStatus;
  sourceFileId: number;
  sourceFile: File;
  createdBy: User;
  updatedAt: string;
  location: string;
  error: string;
};

export enum DataDumpType {
  BankTransactions = 'bank_transactions',
  CustomerTape = 'customer_tape',
  MappedIncomeStatement = 'mapped_income_statement',
  MappedBalanceSheet = 'mapped_balance_sheet',
  QuickbooksBalanceSheet = 'quickbooks_balance_sheet',
  QuickbooksPnl = 'quickbooks_pnl',
  StripeTape = 'stripe_tape',
}

export enum DataDumpStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Success = 'success',
  Failed = 'failed',
}

export type CapitalMachineModel = {
  id: number;
  name: string;
  status: CapitalMachineModelStatus;
  createdBy: User;
  updatedAt: string;
  masterS3FileId: string;
  masterS3FileUrl: string;
  outputFileId: number;
  error: string;
  driveFileId: string;
};

export enum CapitalMachineModelStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Success = 'success',
  Failed = 'failed',
}

export enum DocumentType {
  Other = 'other',
  IncomeStatement = 'income_statement',
  BalanceSheet = 'balance_sheet',
  CustomerTape = 'customer_tape',
  CapTable = 'cap_table',
  FinancialStatementMapping = 'financial_statement_mapping',
  CapitalMachineModelOutput = 'capital_machine_model_output',
}

export type File = {
  id: number;
  name: string;
  ext: string;

  // File mimetype
  type: string;
  company: Company;
  // source: FileSource

  // Direct link to file
  location: string;

  // Resolved downloadable link to file
  download: string;
  documentType: DocumentType;
  isExternal: boolean;
  status: FileStatus;
  createBy: User;
  // meta: JSON

  // Retrieve a S3 presigned post for the file
  // filePresignedPost: FilePresignedPost
  // customerTapeMapping: CustomerTapeMapping
};

export enum RequiredInsights {
  RevenueBreakdownAndProjections = 'revenue_breakdown_and_projections',
  CustomerCohortAcquisitionCostAndLtv = 'customer_cohort_acquisition_cost_and_ltv',
  MarginProgression = 'margin_progression',
  SalesAndMarketingSpendEfficiency = 'sales_and_marketing_spend_efficiency',
  ScenarioBuilding = 'scenario_building',
  PublicCompanyAndPrivatePeerComparables = 'public_company_and_private_peer_comparables',
}

export enum MarginValues {
  LessThan0Percent = 'less_than_0_percent',
  Between0And25Percentage = 'between_0_and_25_percentage',
  Between25And50Percentage = 'between_25_and_50_percentage',
  Between50And75Percentage = 'between_50_and_75_percentage',
  Between75And100Percentage = 'between_75_and_100_percentage',
}

export enum EbitdaBurn {
  BetweenMinus50AndMinus25M = 'between_minus_50_and_minus_25_m',
  BetweenMinus25AndMinus15M = 'between_minus_25_and_minus_15_m',
  BetweenMinus15AndMinus5M = 'between_minus_15_and_minus_5_m',
  BetweenMinus5And0M = 'between_minus_5_and_0_m',
  Between0And5M = 'between_0_and_5_m',
  Between5And15M = 'between_5_and_15_m',
  Between15And25M = 'between_15_and_25_m',
  Between25And50M = 'between_25_and_50_m',
  MoreThan50M = 'more_than_50_m',
}

export enum OTPAction {
  SetPassword = 'SET_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
}

export type DocusignForm = {
  company: {
    name: string;
  };
  contact: {
    name: string;
    title: string;
  };
};

export enum DocusignEvent {
  Cancel = 'cancel',
  Decline = 'decline',
  Exception = 'exception',
  FaxPending = 'fax_pending',
  IDCheckFailed = 'id_check_failed',
  SessionTimeout = 'session_timeout',
  SigningComplete = 'signing_complete',
  TtlExpired = 'ttl_expired',
  ViewingComplete = 'viewing_complete',
}
