import { useEffect } from 'react';
import { companyHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useFlags } from '../useFlags';
import { getCompany, useApiQuery } from '@hum/api';
import { Result } from '@hum/common';
import { Company } from '@hum/types';

export const useCompany = (companyId?: number) => {
  const flags = useFlags();
  const { data: currentCompany, isLoading } = useApiQuery(getCompany, {
    params: { companyId: companyId! },
    enabled: flags.enabled('use-react-query') && !!companyId,
  });
  const { state, dispatch } = useAppStore();

  // only do this if companyId !== 0
  // companyId will be 0 if you're seeing your company by default on the 1st load of admin page
  // but in this case we don't want to fallback to current user's company
  // otherwise it'll be a defined companyId
  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      if (companyId) {
        dispatch(companyHookUsed({ companyId }));
      }
    }
  }, [companyId]);

  return flags.enabled('use-react-query')
    ? ({
        data: currentCompany,
        loaded: !isLoading,
      } as Result<Company>)
    : state.currentCompany;
};
