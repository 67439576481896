import { useEffect } from 'react';
import {
  companyAnalysesHookUsed,
  companyAnalysisWarningsHookUsed,
} from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

export const useCompanyAnalyses = (companyId: number) => {
  const {
    state: { companyAnalyses },
    dispatch,
  } = useAppStore();

  useEffect(() => {
    if (!companyId) {
      return;
    }

    dispatch(companyAnalysesHookUsed({ companyId }));
  }, [companyId]);

  return companyAnalyses;
};

export const useCompanyAnalysisWarnings = (
  companyId: number,
  companyAnalysisId: number | undefined
) => {
  const {
    state: { companyAnalysisWarnings },
    dispatch,
  } = useAppStore();

  useEffect(() => {
    if (!companyAnalysisId) {
      return;
    }

    dispatch(companyAnalysisWarningsHookUsed({ companyId, companyAnalysisId }));
  }, [companyAnalysisId]);

  return companyAnalysisWarnings;
};
