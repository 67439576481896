import React from 'react';
import { Document, Font, Page } from '@react-pdf/renderer';

import { FinancialInsights } from '@hum/types';
import { CompanyProfile } from './CompanyProfile';
import { ProprietaryScoringModels } from './ProprietaryScoringModels';
import { IndustryBenchmarks } from './IndustryBenchmarks';
import { Financing } from './Financing';
import { IcmEvaluation } from './IcmEvaluation';
import { Glossary } from './Glossary';
import { Footer } from './Footer';

Font.register({
  family: 'Supera Gothic',
  fonts: [
    {
      src:
        'https://assets.humcapital.com/fonts/Supera_Gothic/SuperaGothic-Black.otf',
      fontWeight: 900,
    },
  ],
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf',
    },
    {
      src:
        'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf',
      fontWeight: 500,
    },
    {
      src:
        'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf',
      fontWeight: 600,
    },
    {
      src:
        'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZg.ttf',
      fontWeight: 900,
    },
  ],
});

export enum FinancialInsightsDocumentSections {
  All,
  CompanyProfile,
  ProprietaryScoringModels,
  IndustryBenchmarks,
  IcmEvaluation,
}

export type FinancialInsightsDocumentProps = {
  insights: FinancialInsights;
  section?: FinancialInsightsDocumentSections;
  hasProprietaryScoringModels: boolean;
  hasBenchmarks: boolean;
};

export const FinancialInsightsDocument = ({
  insights,
  hasProprietaryScoringModels,
  hasBenchmarks,
  section = FinancialInsightsDocumentSections.All,
}: FinancialInsightsDocumentProps) => {
  const { profile, investorPreferences, benchmarking } = insights;

  const showProprietaryScoringModels =
    hasProprietaryScoringModels &&
    [
      FinancialInsightsDocumentSections.All,
      FinancialInsightsDocumentSections.ProprietaryScoringModels,
    ].includes(section);
  const showBenchmarks =
    hasBenchmarks &&
    [
      FinancialInsightsDocumentSections.All,
      FinancialInsightsDocumentSections.IndustryBenchmarks,
    ].includes(section);
  const showCompanyProfile = [
    FinancialInsightsDocumentSections.All,
    FinancialInsightsDocumentSections.CompanyProfile,
  ].includes(section);
  const showIcmEvaluation = [
    FinancialInsightsDocumentSections.All,
    FinancialInsightsDocumentSections.IcmEvaluation,
  ].includes(section);

  return (
    <>
      <Document>
        <Page size="LETTER" orientation="landscape" dpi={300}>
          {showCompanyProfile && <CompanyProfile {...profile} />}
          {showProprietaryScoringModels && (
            <ProprietaryScoringModels
              {...profile}
              newPage={
                section !==
                FinancialInsightsDocumentSections.ProprietaryScoringModels
              }
            />
          )}
          {showBenchmarks && (
            <>
              <IndustryBenchmarks
                companyName={profile.companyInformation.companyName}
                industry={profile.companyInformation.industry}
                benchmarking={benchmarking}
                companyMetrics={investorPreferences.header.companyMetrics}
                newPage={
                  section !==
                  FinancialInsightsDocumentSections.IndustryBenchmarks
                }
              />
              <Financing
                companyName={profile.companyInformation.companyName}
                benchmarking={benchmarking}
              />
            </>
          )}
          {showIcmEvaluation && (
            <IcmEvaluation
              {...investorPreferences}
              newPage={
                section !== FinancialInsightsDocumentSections.IcmEvaluation
              }
            />
          )}
          <Glossary />
          <Footer {...profile} />
        </Page>
      </Document>
    </>
  );
};
