import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InvestorName, InvestorTestRating, ReportType } from '@hum/types';
import { AppState } from '@hum/icm-app/src/state';
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  Text,
  TextType,
  Spinner,
} from '@hum/ui-library';
import {
  creditBoxDownloadButtonClicked,
  creditBoxRefreshButtonClicked,
} from '@hum/icm-app/src/pages/admin/tabs/Syndication/CompanySyndication/ducks/actions';

export const REPORT_TYPES = {
  [ReportType.TALCOTT]: 'Talcott',
  [ReportType.PEAK_ROCK]: 'Peak Rock',
};
export const INVESTOR_NAMES = {
  [InvestorName.TALCOTT]: 'Talcott',
  [InvestorName.PEAK_ROCK]: 'Peak Rock',
};

export const INVESTOR_RATINGS = {
  [InvestorTestRating.TRUE]: 'Pass',
  [InvestorTestRating.FALSE]: 'Fail',
};
export const RATING_COLOR = {
  TRUE: 'bg-green-500',
  FALSE: 'bg-red-400',
  NOT_DETERMINED: 'bg-gray-300',
};
export const RATING_TEXT = {
  TRUE: 'PASS',
  FALSE: 'FAIL',
  NOT_DETERMINED: 'NOT DETERMINED',
};

type CreditBoxSummaryProps = {
  companyId: number;
  reportId: string;
  reportType: ReportType;
  reportDate: string;
  reportStatus: string;
  reportRating: string | null;
};

export const CreditBoxSummary = ({
  companyId,
  reportId,
  reportType,
  reportDate,
  reportStatus,
  reportRating,
}: CreditBoxSummaryProps) => {
  const dispatch = useDispatch();
  const { data: company } = useSelector(
    (state: AppState) => state.currentCompany
  );
  const { data: session } = useSelector((state: AppState) => state.session);
  const onDownload = () => {
    // companyId, creditBoxReportId are required by the API
    // sending additional trackData properties for tracking purposes only
    const trackData = {
      companyName: company?.name,
      userEmail: session?.email,
      companyOnboardingChannel: company?.application.channel,
      companyUtmSource: company?.application.utmSource,
    };
    dispatch(
      creditBoxDownloadButtonClicked({
        companyId,
        reportId,
        ...trackData,
      })
    );
  };

  const onRefresh = () => {
    dispatch(
      creditBoxRefreshButtonClicked({
        companyId,
        reportType,
      })
    );
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center">
        <Text type={TextType.HEADING_M}>{REPORT_TYPES[reportType]}</Text>
        <div
          className="flex items-center ml-4"
          data-testid={`admin::credit-box::rating::${reportType}`}
        >
          <div
            className={`h-1.5 w-1.5 ${
              RATING_COLOR[reportRating || 'NOT_DETERMINED']
            } rounded-full mr-2`}
          />
          <Text type={TextType.LOZENGE} subdued>
            {RATING_TEXT[reportRating || 'NOT_DETERMINED']}
          </Text>
        </div>
      </div>
      <div className="flex items-center">
        <Text type={TextType.BODY_MICO} subdued className="px-5">
          {reportDate}
        </Text>
        {reportStatus === 'success' && (
          <Button
            type={ButtonType.QUIET}
            size={ButtonSize.SLIM}
            onClick={onDownload}
            testId={`admin::credit-box::download-report::${reportType}`}
          >
            <Icon.Download />
          </Button>
        )}
        {reportStatus !== 'success' && reportStatus !== 'failed' && (
          <div className="relative px-3 py-1">
            <Spinner />
          </div>
        )}
        <Button
          type={ButtonType.QUIET}
          size={ButtonSize.SLIM}
          onClick={onRefresh}
          testId={`admin::credit-box::refresh-report::${reportType}`}
        >
          <Icon.Refresh />
        </Button>
      </div>
    </div>
  );
};
