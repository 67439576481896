import { useEffect, useState } from 'react';
import {
  useApiMutation,
  useApiQuery,
  getFinancialDocumentTaskStatus,
  createFinancialDocumentDownloadTask,
} from '@hum/api';
import {
  CompanyFileDocumentType,
  FinancialDocumentTaskStates,
  ConnectorType,
  ConnectorSource,
} from '@hum/types';
import { toast } from '@hum/common/src/modules/toast';

export const useDownloadFinancialDocuments = ({
  companyId,
  connectorType,
  docType,
  connectorSource,
}: {
  companyId: number;
  docType: CompanyFileDocumentType;
  connectorType?: ConnectorType;
  connectorSource?: ConnectorSource;
}) => {
  const [polling, setPolling] = useState(false);

  const {
    mutate: downloadFinancialDocuments,
    data: downloadTask,
    reset: resetMutation,
  } = useApiMutation(createFinancialDocumentDownloadTask);

  const cancelPolling = () => {
    setPolling(false);
    resetMutation();
  };

  const { data: taskStatus } = useApiQuery(getFinancialDocumentTaskStatus, {
    params: {
      companyId: companyId,
      taskId: downloadTask?.taskId || '',
      docType: docType,
      connectorType: connectorType,
      connectorSource: connectorSource,
    },
    enabled: polling,
    refetchInterval: 3000,
    onError: cancelPolling,
  });

  useEffect(() => {
    if (downloadTask?.taskId) {
      setPolling(true);
    }
    if (taskStatus?.state === FinancialDocumentTaskStates.Success) {
      setPolling(false);
      window.location.assign(taskStatus?.fileUrl);
    }
    if (taskStatus?.state === FinancialDocumentTaskStates.Failure) {
      setPolling(false);
      toast.error(taskStatus.message!);
    }
  }, [downloadTask?.taskId, taskStatus?.fileUrl, taskStatus?.state]);

  return {
    taskStatus,
    polling,
    handleDownloadFinancialDocuments: () =>
      downloadFinancialDocuments({
        companyId,
        docType,
        connectorType,
        connectorSource,
      }),
  };
};
