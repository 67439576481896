import { useEffect, useState } from 'react';
import {
  useApiMutation,
  useApiQuery,
  createBulkDownload,
  getBulkDownloadTaskStatus,
} from '@hum/api';
import { BulkDownloadStatus } from '@hum/types';

export const useBulkDownload = ({ companyId }: { companyId: number }) => {
  const [polling, setPolling] = useState(false);

  const {
    mutate: downloadAllFiles,
    data: downloadTask,
    reset: resetMutation,
  } = useApiMutation(createBulkDownload);

  const cancelPolling = () => {
    setPolling(false);
    resetMutation();
  };

  const { data: taskStatus } = useApiQuery(getBulkDownloadTaskStatus, {
    params: {
      companyId: companyId,
      taskId: downloadTask?.taskId || '',
    },
    enabled: polling,
    refetchInterval: 3000,
    onError: cancelPolling,
  });

  const textSuccess =
    taskStatus?.state === BulkDownloadStatus.Success &&
    !polling &&
    'Download successful';
  const textCancelled =
    (taskStatus?.state === BulkDownloadStatus.Progress ||
      taskStatus?.state === BulkDownloadStatus.Pending ||
      taskStatus?.state === BulkDownloadStatus.Started) &&
    !polling &&
    'Download cancelled';
  const textPolling =
    (taskStatus?.state === BulkDownloadStatus.Progress ||
      taskStatus?.state === BulkDownloadStatus.Pending) &&
    polling &&
    'Preparing download...';
  const textStarted =
    taskStatus?.state === BulkDownloadStatus.Started && 'Download started';
  const textFailed =
    (taskStatus?.state === BulkDownloadStatus.Failure ||
      taskStatus?.state === BulkDownloadStatus.Revoked) &&
    !polling &&
    'Download failed';
  const fileName = polling && taskStatus?.fileName;

  const text =
    fileName ||
    textSuccess ||
    textCancelled ||
    textPolling ||
    textStarted ||
    textFailed;

  useEffect(() => {
    if (downloadTask?.taskId) {
      setPolling(true);
    }
    if (
      taskStatus?.fileUrl &&
      taskStatus?.state === BulkDownloadStatus.Success
    ) {
      cancelPolling();
      window.location.assign(taskStatus.fileUrl);
    }
    if (
      taskStatus?.state === BulkDownloadStatus.Failure ||
      taskStatus?.state === BulkDownloadStatus.Revoked
    ) {
      cancelPolling();
    }
  }, [downloadTask?.taskId, taskStatus?.fileUrl, taskStatus?.state]);

  return {
    taskStatus,
    text: text || '',
    polling,
    cancelPolling,
    handleDownloadAllFilesClick: () => downloadAllFiles({ companyId }),
  };
};
