import { PM_connectorsStatusHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';
import { useFlags } from '../useFlags';
import { getPMConnectorStatus, useApiQuery } from '@hum/api';
import { Result } from '@hum/common';
import { PortfolioMonitoringConnectorStatus } from '@hum/types';

export const usePortfolioConnectorsStatus = (companyId: number) => {
  const flags = useFlags();
  const { data: portfolioMonitoringConnectorStatus, isLoading } = useApiQuery(
    getPMConnectorStatus,
    {
      params: { companyId },
    }
  );

  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(PM_connectorsStatusHookUsed({ companyId }));
    }
  }, [companyId]);

  return flags.enabled('use-react-query')
    ? ({
        data: portfolioMonitoringConnectorStatus,
        loaded: !isLoading,
      } as Result<PortfolioMonitoringConnectorStatus[]>)
    : state.portfolioMonitoringCompaniesConnectorStatus;
};
