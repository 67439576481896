import React from "react";
import _pub63C09B0F from "../../../../design-system/src/__DEPRECATED__typography.pc";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var CobrandingLayout = React.memo(React.forwardRef(function CobrandingLayout(props, ref) {
  return React.createElement("div", {
    "className": "_5d6c30a4 _778aed3 _pub-778aed3 _pub-a97d53f3" + " " + "_778aed3_cobranding_layout _pub-778aed3_cobranding_layout _pub-a97d53f3_cobranding_layout cobranding_layout " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CobrandingLayout };

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_b3625188 _778aed3 _pub-778aed3 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    props["children"]
  )
}));
export { Wrapper };

var NavSidebar = React.memo(React.forwardRef(function NavSidebar(props, ref) {
  return React.createElement("div", {
    "className": "_54da7c8f _778aed3 _pub-778aed3 _pub-a97d53f3" + (props["narrow"] ? " " + "_778aed3_narrow _pub-778aed3_narrow _pub-a97d53f3_narrow narrow" : "") + (props["lighterBg"] ? " " + "_778aed3_lighter-bg _pub-778aed3_lighter-bg _pub-a97d53f3_lighter-bg lighter-bg" : ""),
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    props["title"]&&React.createElement("h1", {
      "className": "_20b56a66 _778aed3 _pub-778aed3 _pub-a97d53f3",
    }, 
      props["title"]
    ),
    props["children"]
  )
}));
export { NavSidebar };

var CobrandingRight = React.memo(React.forwardRef(function CobrandingRight(props, ref) {
  return React.createElement("div", {
    "className": "_584a20a5 _778aed3 _pub-778aed3 _pub-a97d53f3" + (props["darker"] ? " " + "_778aed3_darker _pub-778aed3_darker _pub-a97d53f3_darker darker" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CobrandingRight };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Wrapper, {
    "class": "_b6444189",
    "ref": ref,
  }, 
    React.createElement(CobrandingLayout, {
      "class": "_acb09cfc",
    }, 
      React.createElement(NavSidebar, {
        "class": "_69aa5d2",
        "title": "Sidebar",
      }, 
        "left sidebar"
        
      ),
      React.createElement(CobrandingRight, {
        "class": "_719d9544",
        "darker": props["darker"],
      }, null)
    )
  )
}));


