import React from 'react';

import { Link } from './Link';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { Text, TextType } from '@hum/ui-library';

declare type Props = {
  title: string;
  subtitle: string;
  backlink?: {
    to: string;
    label: string;
  };
};

export const Welcome: React.FC<Props> = ({
  title,
  subtitle,
  backlink,
  children,
}) => (
  <>
    <Topbar />
    <div className="relative w-full h-full flex items-center box-border">
      <div className="block mx-auto w-full max-w-md">
        {backlink && (
          <Link back to={backlink.to} label={backlink.label} className="mb-8" />
        )}
        <Text type={TextType.BODY_L}>{title}</Text>
        <Text type={TextType.BODY_M}>{subtitle}</Text>
        {children}
      </div>
    </div>
  </>
);
