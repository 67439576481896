import { useChartDataUrl } from './useChartDataUrl';
import { Color } from '../utils';

export type IUsePieDataUrl = {
  id: string;
  title: string;
  width: number;
  height: number;
  values: number[];
  labels: string[];
  colors: Color[];
};

export const usePieDataUrl = ({
  id,
  title,
  width,
  height,
  values,
  labels,
  colors,
}: IUsePieDataUrl) => {
  return useChartDataUrl({
    id,
    width,
    height,
    data: [
      {
        type: 'pie',
        values,
        labels,
        hole: 0.6,
        marker: {
          colors,
        },
      },
    ],
    layout: {
      title,
      font: {
        family: "'Inter', sans-serif",
      },
      yaxis: {
        separatethousands: true,
      },
      plot_bgcolor: Color.GhostWhite,
      paper_bgcolor: Color.GhostWhite,
      margin: {
        l: 200,
        r: 200,
        pad: 4,
      },
    },
  });
};
