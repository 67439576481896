import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { ChartBase } from '../../ChartBase';
import { Color } from '../../FinancialInsights/FinancialInsightsDocument/utils';
import { IUseBoxPlotDataUrl } from '../../FinancialInsights/FinancialInsightsDocument/hooks/useBoxPlotDataUrl';

export const BenchmarkRow = ({
  title,
  description,
  chart,
}: {
  title: string;
  description: string;
  chart: Partial<IUseBoxPlotDataUrl>;
}) => {
  return (
    <section className="p-4 md:py-8 container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 my-6 gap-4">
        <div className="md:col-span-1">
          <Text className="uppercase text-black mb-4" type={TextType.HEADING_L}>
            {title}
          </Text>
          <Text type={TextType.BODY_M} className="text-black mb-4">
            {description}
          </Text>
        </div>
        {chart.population && chart.annotations && chart.sample && (
          <div className="md:col-span-2">
            <ChartBase
              data={chart.population.map((p) => ({
                ...p,
                type: 'box',
                boxpoints: false,
                fillcolor: Color.BlueOrchid,
                marker: {
                  color: Color.BlueOrchid,
                },
                showlegend: false,
              }))}
              otherMarkers={{
                ...chart.sample,
                marker: {
                  showlegend: true,
                  color: Color.GoldenrodYellow,
                  size: 10,
                  line: {
                    color: 'rgba(223, 191, 3, 0.30)',
                    width: 13,
                  },
                },
              }}
              layout={{
                title,
                font: {
                  family: "'Inter', sans-serif",
                },
                yaxis: {
                  separatethousands: true,
                  showgrid: true,
                  gridcolor: Color.LightPeriwinkle,
                  zeroline: true,
                  zerolinecolor: Color.LightPeriwinkle,
                  zerolinewidth: 3,
                },
                xaxis: {
                  showgrid: false,
                },
                plot_bgcolor: Color.GhostWhite,
                paper_bgcolor: Color.GhostWhite,
                margin: {
                  pad: 10,
                },
                annotations: chart.annotations.map((a) => ({
                  ...a,
                  ay: 0,
                  ayref: 'pixel',
                  ax: 50,
                  axref: 'pixel',
                  arrowcolor: Color.GoldenrodYellow,
                  arrowhead: 0,
                  arrowwidth: 1,
                  bgcolor: Color.GoldenrodYellow,
                  font: {
                    color: 'black',
                  },
                })),
              }}
              config={{
                displayModeBar: false,
                responsive: true,
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};
