import React from 'react';

import { Link, Text, View } from '@react-pdf/renderer';

import { CompanyProfile as CompanyProfileType } from '@hum/types';
import { BookmarkedPage, PageHeader } from './common';

import { tw, currency, percentage } from './utils';

export type ProprietaryScoringModelsProps = CompanyProfileType & {
  newPage: boolean;
};

export const ProprietaryScoringModels = ({
  humsProprietaryScoringModels,
  newPage,
}: ProprietaryScoringModelsProps) => {
  const { models } = humsProprietaryScoringModels;
  const { maxDebt, maxNewDebt } = models;

  return (
    <BookmarkedPage title="How much can I raise?" break={newPage}>
      <View style={tw('grow')}>
        <View>
          <PageHeader title="How much non-dilutive capital can you raise?" />
          <Text
            style={tw(
              'text-body',
              'mx-1',
              'pb-2',
              'border-b-4',
              'border-b-palatinate-blue'
            )}
          >
            The Max Debt and Max New Debt values are calculated using Hum’s
            proprietary models, which consider company performance indicators
            such as revenue, EBITDA, and existing debt. For more details, please
            refer to this{' '}
            <Link src="https://info.humcapital.com/hum-help-center/what-is-the-max-raise-calculator">
              support article
            </Link>
            .
          </Text>
        </View>
        {maxDebt.value !== null && (
          <View
            style={tw(
              'flex-row',
              'border-b-4',
              'border-b-palatinate-blue',
              'p-1',
              'pl-0',
              'm-1',
              'justify-center',
              'align-center'
            )}
          >
            <View style={tw('w-1/3', 'mr-8')}>
              <Text style={tw('text-h4', 'uppercase', 'mb-1')}>
                Maximum Debt
              </Text>
              <Text style={tw('text-body', 'mb-0.5')}>
                Hum calculates your company’s estimated maximum debt capacity.
              </Text>
              <Text style={tw('text-body', 'mb-0.5')}>
                Year-over-year (YoY) growth reflects the additional or reduced
                debt your company can handle compared to the previous year.
              </Text>
            </View>
            <View style={tw('w-1/3', 'mr-8', 'align-center')}>
              <Text style={tw('text-display', 'mb-0.5')}>
                {maxDebt.value === null ? '-' : currency.format(maxDebt.value)}
              </Text>
              <Text style={tw('text-label', 'uppercase')}>Est. Max Debt</Text>
            </View>
            <View style={tw('w-1/3', 'mr-8', 'align-center')}>
              <Text style={tw('text-display', 'mb-0.5')}>
                {maxDebt.yoyGrowth === null
                  ? '-'
                  : percentage.format(maxDebt.yoyGrowth)}
              </Text>
              <Text style={tw('text-label', 'uppercase')}>YoY Growth%</Text>
            </View>
          </View>
        )}
        {maxNewDebt.value !== null && (
          <View
            style={tw(
              'flex-row',
              'border-b-4',
              'border-b-palatinate-blue',
              'p-1',
              'pl-0',
              'm-1',
              'justify-center',
              'align-center'
            )}
          >
            <View style={tw('w-1/3', 'mr-8')}>
              <Text style={tw('text-h4', 'uppercase', 'mb-1')}>
                Maximum New Debt
              </Text>
              <Text style={tw('text-body', 'mb-0.5')}>
                Based on your current financial performance, we estimated the
                total amount of new debt that your company can comfortably
                handle. This calculation takes into account any existing debt.
              </Text>
              <Text style={tw('text-body', 'mb-0.5')}>
                Year-over-year (YoY) growth reflects the additional or reduced
                new debt your company can handle compared to the previous year.
              </Text>
            </View>
            <View style={tw('w-1/3', 'mr-8', 'align-center')}>
              <Text style={tw('text-display', 'mb-0.5')}>
                {maxNewDebt.value === null
                  ? '-'
                  : currency.format(maxNewDebt.value)}
              </Text>
              <Text style={tw('text-label', 'uppercase')}>
                Est. Max New Debt
              </Text>
            </View>
            <View style={tw('w-1/3', 'mr-8', 'align-center')}>
              <Text style={tw('text-display', 'mb-0.5')}>
                {maxNewDebt.yoyGrowth === null
                  ? '-'
                  : percentage.format(maxNewDebt.yoyGrowth)}
              </Text>
              <Text style={tw('text-label', 'uppercase')}>YoY Growth%</Text>
            </View>
          </View>
        )}
      </View>
    </BookmarkedPage>
  );
};
