import { countries } from 'countries-list';
import {
  CompanyType,
  USStates,
  LastFundingRound,
  FundingType,
  CompanyBusinessType,
  ApplicationRunwayMonths,
  ApplicationRecordSystem,
  ApplicationPaymentProcessor,
  CompanyEquityRaised,
  CompanyYearOverYearGrowthRate,
  CompanyExistingDebt,
  CompanyTargetDebtRaise,
  CompanyHeadcount,
  FundraisingTimeFrameMonths,
  LeverageableAssets,
  ApplicationEconomicTiers,
  SyndicationInvestmentPriority,
  SyndicationInvestmentRanges,
  SyndicationInvestmentTypes,
} from '@hum/types';
import {
  MarginValues,
  EbitdaBurn,
  RequiredInsights,
  MndaOptions,
} from '@hum/icm-app/src/components/types';
import {
  selectOptionsFromMap,
  SelectOption,
  setToSelectOptions,
  GroupedOption,
} from '@hum/legacy-ui';
import {
  PorfolioMonitoringInviteStatus,
  CompanyTag,
} from '@hum/icm-app/src/state';

export const COUNTRY_SELECT_OPTIONS: SelectOption[] = Object.entries(
  countries
).map(([id, country]) => ({
  label: country.name,
  value: id,
}));
export const COUNTRY_SELECT_OPTIONS_GROUPED: GroupedOption[] = [
  {
    label: '',
    options: Object.entries(countries)
      .filter(([id]) => id == 'US')
      .map(([id, country]) => ({
        label: country.name,
        value: id,
      })),
  },
  {
    label: 'Other Countries',
    options: Object.entries(countries)
      .filter(([id]) => id != 'US')
      .map(([id, country]) => ({
        label: country.name,
        value: id,
      })),
  },
];

export const SENSITIVE_COUNTRIES = [
  'AF', // Afghanistan
  'BY', // Belarus
  'BA', // Bosnia and Herzegovina
  'AL', // Albania
  'XK', // Kosovo
  'BG', // Bulgaria
  'CD', // Democratic Republic of the Congo
  'CF', // Central African Republic
  'CN', // China
  'CU', // Cuba
  'ET', // Ethiopia
  'HK', // Hong Kong
  'HR', // Croatia
  'IR', // Iran
  'IQ', // Iraq
  'LB', // Lebanon
  'LY', // Libya
  'ML', // Mali
  'MM', // Myanmar
  'MK', // North Macedonia
  'ME', // Montenegro
  'NI', // Nicaragua
  'KP', // North Korea
  'RU', // Russia
  'RO', // Romania
  'RS', // Serbia
  'SI', // Slovenia
  'SO', // Somalia
  'SD', // Sudan
  'SY', // Syria
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
];

export const BUSINESS_TYPES = {
  [CompanyBusinessType.ConsumerProducts]: 'Consumer Product',
  [CompanyBusinessType.ConsumerFinance]: 'Consumer Finance',
  [CompanyBusinessType.App]: 'Mobile App',
  [CompanyBusinessType.Retail]: 'Retail or distribution',
  [CompanyBusinessType.Services]: 'Service',
  [CompanyBusinessType.SaasPositiveChurn]: 'SaaS',
  [CompanyBusinessType.CommercialFinance]: 'Specialty finance or lending',
  [CompanyBusinessType.Subscriptions]: 'Subscription',
  [CompanyBusinessType.Other]: 'Other',
  [CompanyBusinessType.Manufacturer]: 'Manufacturer',
  [CompanyBusinessType.Media]: 'Media',
  [CompanyBusinessType.Marketplace]: 'Marketplace',
  [CompanyBusinessType.Gaming]: 'Gaming',
  [CompanyBusinessType.ECommerce]: 'E-Commerce',
  [CompanyBusinessType.Health]: 'Health',
  [CompanyBusinessType.HealthIT]: 'Health IT',
};

export const REQUIRED_INSIGHTS = {
  [RequiredInsights.RevenueBreakdownAndProjections]:
    'Revenue breakdown and projections',
  [RequiredInsights.CustomerCohortAcquisitionCostAndLtv]:
    'Customer cohort acquisition cost and ltv',
  [RequiredInsights.MarginProgression]: 'Margin progression',
  [RequiredInsights.SalesAndMarketingSpendEfficiency]:
    'Sales and marketing spend efficiency',
  [RequiredInsights.ScenarioBuilding]: 'Scenario building',
  [RequiredInsights.PublicCompanyAndPrivatePeerComparables]:
    'Public company and private peer comparables',
};

export const BUSINESS_TYPE_SELECT_OPTIONS = selectOptionsFromMap(
  BUSINESS_TYPES
);

export const ECONOMIC_TIER_OPTIONS = {
  [ApplicationEconomicTiers.Between0And1M]: 'Less than $1M',
  [ApplicationEconomicTiers.Between1And3M]: '$1M–$3M',
  [ApplicationEconomicTiers.Between3And5M]: '$3M–$5M',
  [ApplicationEconomicTiers.Between5And15M]: '$5M–$15M',
  [ApplicationEconomicTiers.Between15And25M]: '$15M–$25M',
  [ApplicationEconomicTiers.Between25And50M]: '$25M–$50M',
  [ApplicationEconomicTiers.Between50And100M]: '$50M–$100M',
  [ApplicationEconomicTiers.MoreThan100M]: '$100M or more',
};

export const ECONOMIC_TIER_SELECT_OPTIONS = selectOptionsFromMap(
  ECONOMIC_TIER_OPTIONS
);

export const MONTHS_OF_RUNWAY_OPTIONS = {
  [ApplicationRunwayMonths.LessThan6]: 'Less than 6 months',
  [ApplicationRunwayMonths.Between6and12]: 'Between 6 and 12 months',
  [ApplicationRunwayMonths.MoreThan12]: 'More than 12 months',
};

export const MONTHS_OF_RUNWAY_SELECT_OPTIONS = selectOptionsFromMap(
  MONTHS_OF_RUNWAY_OPTIONS
);

export const SYSTEM_RECORD_SELECT_OPTIONS = setToSelectOptions([
  ApplicationRecordSystem.Quickbooks,
  ApplicationRecordSystem.SAP,
  ApplicationRecordSystem.Xero,
  ApplicationRecordSystem.NetSuite,
  ApplicationRecordSystem.Workday,
  ApplicationRecordSystem.Billomat,
  ApplicationRecordSystem.Candis,
  ApplicationRecordSystem.FastBill,
  ApplicationRecordSystem.Enigma,
  ApplicationRecordSystem.Agenda,
  ApplicationRecordSystem.MicrosoftDynamics,
]);

export const MNDA_SELECT_OPTIONS = selectOptionsFromMap({
  [MndaOptions.Default]: 'Default',
  [MndaOptions.Pending]: 'Custom', // https://humcapital.atlassian.net/browse/EXE-359
});

export const PAYMENT_PROCESSOR_SELECT_OPTIONS = setToSelectOptions([
  ApplicationPaymentProcessor.PayPal,
  ApplicationPaymentProcessor.WooCommerce,
  ApplicationPaymentProcessor.Stripe,
  ApplicationPaymentProcessor.Squarespace,
  ApplicationPaymentProcessor.Shopify,
  ApplicationPaymentProcessor.Square,
  ApplicationPaymentProcessor.Braintree,
  ApplicationPaymentProcessor.Klarna,
  ApplicationPaymentProcessor.Afterpay,
  ApplicationPaymentProcessor.AuthorizeNet,
  ApplicationPaymentProcessor.BillCom,
  ApplicationPaymentProcessor.Worldpay,
  ApplicationPaymentProcessor.Affirm,
  ApplicationPaymentProcessor.AmazonPay,
]);

export const COMPANY_TYPE_OPTIONS = {
  [CompanyType.Investor]: 'Portfolio investors',
  [CompanyType.Company]: 'Companies requesting money',
  [CompanyType.SyndicationInvestor]: 'Syndication investor',
  [CompanyType.BalanceSheetPartner]: 'Balance Sheet Partner',
};

// prettier-ignore
export const SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS = selectOptionsFromMap({
  [SyndicationInvestmentRanges.LessThan1M]: 'Less Than 1M',
  [SyndicationInvestmentRanges.Between1And5M]: 'Between 1 and 5M',
  [SyndicationInvestmentRanges.Between5And10M]: 'Between 5 and 10M',
  [SyndicationInvestmentRanges.Between10And25M]: 'Between 10 and 25M',
  [SyndicationInvestmentRanges.Between25And50M]: 'Between 25 and 50M',
  [SyndicationInvestmentRanges.Between50And100M]: 'Between 50 and 100M',
  [SyndicationInvestmentRanges.MoreThan100M]: 'More Than 100M',
});

// prettier-ignore
export const SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS = selectOptionsFromMap({
  [SyndicationInvestmentTypes.Angel]: 'Angel',
  [SyndicationInvestmentTypes.GrowthEquity]: 'Growth Equity',
  [SyndicationInvestmentTypes.LeveragedBuyOut]: 'Leveraged Buyout (LBO)',
  [SyndicationInvestmentTypes.MezzDebt]: 'Mezz Debt',
  [SyndicationInvestmentTypes.PreIpo]: 'Pre IPO',
  [SyndicationInvestmentTypes.Seed]: 'Seed',
  [SyndicationInvestmentTypes.SeniorBankDebt]: 'Senior Bank Debt',
  [SyndicationInvestmentTypes.SeriesA]: 'Series A',
  [SyndicationInvestmentTypes.SeriesBPlus]: 'Series B or later',
  [SyndicationInvestmentTypes.SpecialSituations]: 'Special Situations',
  [SyndicationInvestmentTypes.VentureDebt]: 'Venture Debt',
  [SyndicationInvestmentTypes.SmallCapPublic]: 'Small cap public',
  [SyndicationInvestmentTypes.ReceivablesFinance]: 'Receivables Finance',
});

// prettier-ignore
export const SYNDICATION_INVESTOR_INVESTMENT_PRIORITY_OPTIONS = selectOptionsFromMap({
  [SyndicationInvestmentPriority.p1]: 'Low – 1',
  [SyndicationInvestmentPriority.p2]: 'Medium – 2',
  [SyndicationInvestmentPriority.p3]: 'High – 3',
  [SyndicationInvestmentPriority.p4]: 'Highest – 4',
});

// prettier-ignore
export const PORTFOLIO_MONITORING_STATUS_OPTIONS = selectOptionsFromMap({
  [PorfolioMonitoringInviteStatus.Created]: '01 – Created',
  [PorfolioMonitoringInviteStatus.Pending]: '02 – Pending',
  [PorfolioMonitoringInviteStatus.Sent]: '03 – Sent',
  [PorfolioMonitoringInviteStatus.Viewed]: '04 – Viewed',
  [PorfolioMonitoringInviteStatus.Accepted]: '05 – Accepted',
});

export const EQUITY_RAISED = {
  [CompanyEquityRaised.Bootstrapped]:
    'Bootstrapped (No external capital taken)',
  [CompanyEquityRaised.Between0And5M]: '$0–$5M',
  [CompanyEquityRaised.Between5And15M]: '$5–$15M',
  [CompanyEquityRaised.Between15And25M]: '$15–$25M',
  [CompanyEquityRaised.Between25And50M]: '$25–$50M',
  [CompanyEquityRaised.Between50And100M]: '$50–$100M',
  [CompanyEquityRaised.MoreThan100M]: '$100M+',
};

export const EQUITY_RAISED_SELECT_OPTIONS = selectOptionsFromMap(EQUITY_RAISED);

export const YEAR_OVER_YEAR_GROWTH_RATE = {
  [CompanyYearOverYearGrowthRate.LessThan0Percentage]: 'Less than 0%',
  [CompanyYearOverYearGrowthRate.Between0And25Percentage]: '0%–25%',
  [CompanyYearOverYearGrowthRate.Between25And50Percentage]: '25%–50%',
  [CompanyYearOverYearGrowthRate.Between50And100Percentage]: '50%–100%',
  [CompanyYearOverYearGrowthRate.Between100And200Percentage]: '100%–200%',
  [CompanyYearOverYearGrowthRate.Between200And300Percentage]: '200%–300%',
  [CompanyYearOverYearGrowthRate.Between300And400Percentage]: '300%–400%',
  [CompanyYearOverYearGrowthRate.Between400And500Percentage]: '400%–500%',
  [CompanyYearOverYearGrowthRate.MoreThan500Percentage]: '500% or more',
};

export const YEAR_OVER_YEAR_GROWTH_RATE_SELECT_OPTIONS = selectOptionsFromMap(
  YEAR_OVER_YEAR_GROWTH_RATE
);

export const MARGINS_VALUES = {
  [MarginValues.LessThan0Percent]: 'Less than 0%',
  [MarginValues.Between0And25Percentage]: '0%-25%',
  [MarginValues.Between25And50Percentage]: '25%–50%',
  [MarginValues.Between50And75Percentage]: '50%–75%',
  [MarginValues.Between75And100Percentage]: '75%-100%',
};

export const MARGINS_OPTIONS = selectOptionsFromMap(MARGINS_VALUES);

export const EBITDA_BURN = {
  [EbitdaBurn.BetweenMinus50AndMinus25M]: '-$50M to -$25M',
  [EbitdaBurn.BetweenMinus25AndMinus15M]: '-$25M to -$15M',
  [EbitdaBurn.BetweenMinus15AndMinus5M]: '-$15M to -$5M',
  [EbitdaBurn.BetweenMinus5And0M]: '-$5M to $0M',
  [EbitdaBurn.Between0And5M]: '$0–$5M',
  [EbitdaBurn.Between5And15M]: '$5M–$15M',
  [EbitdaBurn.Between15And25M]: '$15M–$25M',
  [EbitdaBurn.Between25And50M]: '$25M–$50M',
  [EbitdaBurn.MoreThan50M]: '$50M or more',
};

export const EBITDA_BURN_OPTIONS = selectOptionsFromMap(EBITDA_BURN);

export const EXISTING_DEBT = {
  [CompanyExistingDebt.None]: 'None',
  [CompanyExistingDebt.Between0And3M]: 'Less than $3M',
  [CompanyExistingDebt.Between3And5M]: '$3M–$5M',
  [CompanyExistingDebt.Between5And10M]: '$5M–$10M',
  [CompanyExistingDebt.Between10And25M]: '$10M–$25M',
  [CompanyExistingDebt.Between25And50M]: '$25M–$50M',
  [CompanyExistingDebt.MoreThan50M]: '$50M or more',
};

export const EXISTING_DEBT_SELECT_OPTIONS = selectOptionsFromMap(EXISTING_DEBT);

export const TARGET_DEBT_RAISE = {
  [CompanyTargetDebtRaise.Between0And3M]: 'Less than $3M',
  [CompanyTargetDebtRaise.Between3And5M]: '$3M–$5M',
  [CompanyTargetDebtRaise.Between5And10M]: '$5M–$10M',
  [CompanyTargetDebtRaise.Between10And25M]: '$10M–$25M',
  [CompanyTargetDebtRaise.Between25And50M]: '$25M–$50M',
  [CompanyTargetDebtRaise.Between50And100M]: '$50M–$100M',
  [CompanyTargetDebtRaise.MoreThan100M]: '$100M or more',
};

export const TARGET_DEBT_RAISE_SELECT_OPTIONS = selectOptionsFromMap(
  TARGET_DEBT_RAISE
);

export const COMPANY_HEADCOUNT = {
  [CompanyHeadcount.Between0and10]: 'Less than 10',
  [CompanyHeadcount.Between10and50]: '10–50',
  [CompanyHeadcount.Between50and100]: '50–100',
  [CompanyHeadcount.Between100and250]: '100–250',
  [CompanyHeadcount.Between250and500]: '250–500',
  [CompanyHeadcount.MoreThan500]: '500 or more',
};

export const COMPANY_HEADCOUNT_SELECT_OPTIONS = selectOptionsFromMap(
  COMPANY_HEADCOUNT
);

export const LEVERAGEABLE_ASSETS = {
  [LeverageableAssets.NotApplicable]: 'N/A',
  [LeverageableAssets.AccountsReceivable]: 'Accounts Receivable',
  [LeverageableAssets.Inventory]: 'Inventory',
  [LeverageableAssets.RealEstate]: 'Real Estate',
  [LeverageableAssets.CapitalEquipment]: 'Capital Equipment',
};

export const LEVERAGEABLE_ASSETS_SELECT_OPTIONS = selectOptionsFromMap(
  LEVERAGEABLE_ASSETS
);

export const REQUIRED_INSIGHTS_SELECT_OPTIONS = selectOptionsFromMap(
  REQUIRED_INSIGHTS
);

export const FUNDRAISING_TIME_FRAME_MONTHS = {
  [FundraisingTimeFrameMonths.Between0and3]: 'Less than 3 months',
  [FundraisingTimeFrameMonths.Between3and6]: 'Between 3 and 6 months',
  [FundraisingTimeFrameMonths.Between6and12]: 'Between 6 and 12 months',
  [FundraisingTimeFrameMonths.MoreThan12]: '12 months or more',
};

export const FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS = selectOptionsFromMap(
  FUNDRAISING_TIME_FRAME_MONTHS
);

export const US_STATES = {
  [USStates.Alabama]: 'Alabama',
  [USStates.Alaska]: 'Alaska',
  [USStates.AmericanSamoa]: 'American Samoa',
  [USStates.Arizona]: 'Arizona',
  [USStates.Arkansas]: 'Arkansas',
  [USStates.California]: 'California',
  [USStates.Colorado]: 'Colorado',
  [USStates.Connecticut]: 'Connecticut',
  [USStates.Delaware]: 'Delaware',
  [USStates.DistrictOfColumbia]: 'District of Columbia',
  [USStates.FederatedStatesOfMicronesia]: 'Federated States of Micronesia',
  [USStates.Florida]: 'Florida',
  [USStates.Georgia]: 'Georgia',
  [USStates.Guam]: 'Guam',
  [USStates.Hawaii]: 'Hawaii',
  [USStates.Idaho]: 'Idaho',
  [USStates.Illinois]: 'Illinois',
  [USStates.Indiana]: 'Indiana',
  [USStates.Iowa]: 'Iowa',
  [USStates.Kansas]: 'Kansas',
  [USStates.Kentucky]: 'Kentucky',
  [USStates.Louisiana]: 'Louisiana',
  [USStates.Maine]: 'Maine',
  [USStates.MarshallIslands]: 'Marshall Islands',
  [USStates.Maryland]: 'Maryland',
  [USStates.Massachusetts]: 'Massachusetts',
  [USStates.Michigan]: 'Michigan',
  [USStates.Minnesota]: 'Minnesota',
  [USStates.Mississippi]: 'Mississippi',
  [USStates.Missouri]: 'Missouri',
  [USStates.Montana]: 'Montana',
  [USStates.Nebraska]: 'Nebraska',
  [USStates.Nevada]: 'Nevada',
  [USStates.NewHampshire]: 'New Hampshire',
  [USStates.NewJersey]: 'New Jersey',
  [USStates.NewMexico]: 'New Mexico',
  [USStates.NewYork]: 'New York',
  [USStates.NorthCarolina]: 'North Carolina',
  [USStates.NorthDakota]: 'North Dakota',
  [USStates.NorthernMarianaIslands]: 'Northern Mariana Islands',
  [USStates.Ohio]: 'Ohio',
  [USStates.Oklahoma]: 'Oklahoma',
  [USStates.Oregon]: 'Oregon',
  [USStates.Palau]: 'Palau',
  [USStates.Pennsylvania]: 'Pennsylvania',
  [USStates.PuertoRico]: 'Puerto Rico',
  [USStates.RhodeIsland]: 'Rhode Island',
  [USStates.SouthCarolina]: 'South Carolina',
  [USStates.SouthDakota]: 'South Dakota',
  [USStates.Tennessee]: 'Tennessee',
  [USStates.Texas]: 'Texas',
  [USStates.Utah]: 'Utah',
  [USStates.Vermont]: 'Vermont',
  [USStates.VirginIslands]: 'Virgin Islands',
  [USStates.Virginia]: 'Virginia',
  [USStates.Washington]: 'Washington',
  [USStates.WestVirginia]: 'West Virginia',
  [USStates.Wisconsin]: 'Wisconsin',
  [USStates.Wyoming]: 'Wyoming',
};

export const US_STATES_SELECT_OPTIONS = selectOptionsFromMap(US_STATES);

export const COMPANY_TAG_OPTIONS: SelectOption[] = selectOptionsFromMap({
  [CompanyTag.InsightsReady]: CompanyTag.InsightsReady,
  [CompanyTag.HideProcessingDataAlert]: CompanyTag.HideProcessingDataAlert,
  [CompanyTag.DataComplete]: CompanyTag.DataComplete,
  [CompanyTag.DataLabeled]: CompanyTag.DataLabeled,
  [CompanyTag.FunnelPortfolio]: CompanyTag.FunnelPortfolio,
  [CompanyTag.FunnelSyndication]: CompanyTag.FunnelSyndication,
  [CompanyTag.FunnelPrincipal]: CompanyTag.FunnelPrincipal,
  [CompanyTag.FunnelSortingHat]: CompanyTag.FunnelSortingHat,
  [CompanyTag.WaitingForData]: CompanyTag.WaitingForData,
  [CompanyTag.ScPortfolio]: CompanyTag.ScPortfolio,
});

export const LAST_FUNDING_ROUND = {
  [LastFundingRound.Bootstrapped]: 'Bootstrapped (No external capital taken)',
  [LastFundingRound.PreSeed]: 'Pre-seed',
  [LastFundingRound.Seed]: 'Seed',
  [LastFundingRound.SeriesA]: 'Series A',
  [LastFundingRound.SeriesB]: 'Series B',
  [LastFundingRound.SeriesC]: 'Series C',
  [LastFundingRound.SeriesD]: 'Series D',
  [LastFundingRound.SeriesE]: 'Series E',
};

export const LAST_FUNDING_ROUND_OPTIONS = selectOptionsFromMap(
  LAST_FUNDING_ROUND
);

export const FUNDING_TYPE = {
  [FundingType.Debt]: 'Debt',
  [FundingType.Equity]: 'Equity',
  [FundingType.Either]: 'Either',
  [FundingType.IDontKnow]: "I don't know",
};

export const FUNDING_TYPE_OPTIONS = selectOptionsFromMap(FUNDING_TYPE);
