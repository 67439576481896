import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { CompanyType } from '@hum/types';

export const useEntityType = () => {
  const { data: company } = useCurrentCompany();

  const isPortfolioInvestor =
    company?.type.includes(CompanyType.Investor) || false;
  const isSyndicationInvestor =
    company?.type.includes(CompanyType.SyndicationInvestor) || false;
  const isBalanceSheetPartner =
    company?.type.includes(CompanyType.BalanceSheetPartner) || false;
  const isCompany = company?.type.includes(CompanyType.Company) || false;
  const isAdmin = company?.type.includes(CompanyType.Admin) || false;

  return {
    isPortfolioInvestor,
    isSyndicationInvestor,
    isBalanceSheetPartner,
    isCompany,
    isAdmin,
  };
};
