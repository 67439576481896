import React from "react";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Progress = React.memo(React.forwardRef(function Progress(props, ref) {
  return React.createElement("div", {
    "className": "_c14e4206 _f37f1587 _pub-f37f1587" + (props["active"] ? " " + "_f37f1587_active _pub-f37f1587_active active" : "") + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "style": castStyle("--progress: " + props["value"] + "; --radius: " + props["radius"] + "; --stroke-width: " + props["stroke"] + ";"),
  }, 
    React.createElement("div", {
      "className": "_fea50e5e _f37f1587 _pub-f37f1587",
    }, 
      props["children"]
    ),
    React.createElement("svg", {
      "className": "_67ac5fe4 _f37f1587 _pub-f37f1587",
    }, 
      React.createElement("circle", {
        "className": "_86ec659d _f37f1587 _pub-f37f1587" + " " + "_f37f1587_progress-bg _pub-f37f1587_progress-bg progress-bg",
        "stroke": "var(--gray-gray-50)",
        "fill": "transparent",
      }, null),
      React.createElement("circle", {
        "className": "_f1eb550b _f37f1587 _pub-f37f1587" + " " + "_f37f1587_progress _pub-f37f1587_progress progress",
        "fill": "transparent",
      }, null)
    )
  )
}));
export { Progress };


