import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { companyDataAssetsHookUsed } from '@hum/icm-app/src/actions';
import { useEffect } from 'react';

export const useCompanyDataAssets = (companyId: number) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    dispatch(companyDataAssetsHookUsed({ companyId }));
  }, [companyId]);

  return state.currentCompanyDataAssets;
};
