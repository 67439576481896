import React from 'react';
import { CriteriaProps } from '../../FinancialInsights/FinancialInsightsDocument/IcmEvaluation/Criteria';

export const Criteria = ({
  fill,
  stroke,
  backgroundColor,
  r = '10',
}: CriteriaProps) => {
  return (
    <svg viewBox="0 0 100 100" style={{ backgroundColor }}>
      <circle cx="50" cy="50" r={r} fill={fill} stroke={stroke} />
    </svg>
  );
};
