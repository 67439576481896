import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state';
import { companyQuickbooksRawDataVisibilityHookUsed } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { useEffect } from 'react';

// Note: rename this, this is not showing the visibility but rather downloading the file
export const useQuickBooksRawDataVisibility = (companyId: number) => {
  const dispatch = useDispatch();
  const showQuickBooksRawData = useSelector(
    (state: AppState) => state.showQuickBooksRawData
  );

  useEffect(() => {
    dispatch(
      companyQuickbooksRawDataVisibilityHookUsed({
        companyId,
      })
    );
  }, [companyId]);

  return showQuickBooksRawData;
};
