import React from 'react';
import { Modal } from '@hum/design-system';
import { Box } from '@hum/legacy-ui';
import { Button } from '@hum/ui-library';
import ReactMarkdown from 'react-markdown';

type TocModalProps = {
  isOpen: boolean;
  'data-testid': string;
  title: string;
  content: string;
  buttonText: string;
  onClose: () => void;
  onBtnClick?: () => void;
  mustReachBottom?: boolean;
};

const TocModal: React.FC<TocModalProps> = ({
  isOpen,
  'data-testid': dataTestid,
  title,
  content,
  buttonText,
  onClose,
  onBtnClick,
  mustReachBottom,
}: TocModalProps) => {
  const [hasReachedBottom, setHasReachedBottom] = React.useState(false);
  const handleBtnClick = () => {
    if (onBtnClick) {
      onBtnClick();
    }
    onClose();
  };
  const userReachedBottom = () => {
    if (!hasReachedBottom) setHasReachedBottom(true);
  };

  return (
    <Modal
      visible={isOpen}
      veryWide
      scrollable
      fullHeight
      onClose={onClose}
      header={title}
      testId={dataTestid}
      onReachBottom={userReachedBottom}
      footer={
        <Box fill alignEnd>
          <Button
            testId={`${dataTestid}-close-button`}
            onClick={handleBtnClick}
            {...(mustReachBottom && { disabled: !hasReachedBottom })}
            disabled={mustReachBottom && !hasReachedBottom}
          >
            {buttonText}
          </Button>
        </Box>
      }
    >
      <ReactMarkdown children={content} />
    </Modal>
  );
};

export { TocModal };
