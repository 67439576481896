import { useAppStore } from '../../hooks/useAppStore';

export const useIsWhitelabeled = () => {
  const {
    state: { whitelabeling },
  } = useAppStore();

  return (
    Boolean(whitelabeling.data?.logoFileUrl) || !!whitelabeling.data?.subdomain
  );
};
