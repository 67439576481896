import React from 'react';

import { Svg, Circle } from '@react-pdf/renderer';

export type CriteriaProps = {
  backgroundColor: string;
  fill: string;
  stroke: string;
  r?: string;
};

export const Criteria = ({
  fill,
  stroke,
  backgroundColor,
  r = '10',
}: CriteriaProps) => {
  return (
    <Svg viewBox="0 0 100 100" style={{ backgroundColor }}>
      <Circle cx="50" cy="50" r={r} fill={fill} stroke={stroke} />
    </Svg>
  );
};
