import React from 'react';
import { TR, TD } from '@ag-media/react-pdf-table';

import { CreditBox } from '@hum/types';

import { Criteria } from './Criteria';
import { tw } from '../utils';

export const CRITERIA_VALUE_STYLES = {
  true: tw('fill-tea-green', 'stroke-tea-green', 'bg-white'),
  false: tw('fill-dust-storm', 'stroke-dust-storm', 'bg-white'),
  undefined: tw('fill-platinum', 'stroke-platinum', 'bg-platinum'),
  null: tw('fill-white', 'stroke-platinum', 'bg-white'),
};

export const NAME_TO_LABEL = {
  ablFromBank: 'ABL from bank',
  ablFromNonbank: 'ABL from non-bank',
  directLendingFromBank: 'Direct lending from bank',
  directLendingFromBankSponsorBacked:
    'Direct lending from bank (Sponsor backed)',
  directLendingFromNonbank: 'Direct lending from non-bank',
  directLendingFromNonbankSponsorBacked:
    'Direct lending from non-bank (Sponsor backed)',
  growthFinancingFromBankSponsorBacked:
    'Growth financing from bank (Sponsor backed)',
  growthFinancingFromNonbank: 'Growth financing from non-bank',
  growthFinancingFromNonbankSponsorBacked:
    'Growth financing from non-bank (Sponsor backed)',
};

export type CreditBoxProps = {
  label: string;
  creditBox: Partial<CreditBox>;
};

export const CreditBoxRow = ({ label, creditBox }: CreditBoxProps) => {
  const {
    revenue,
    ebitda,
    growth,
    ebitdaMargin,
    ebitdaMarginExpansion,
    runway,
    industry,
  } = creditBox;

  return (
    <TR>
      <TD style={tw('border-none', 'bg-alice-blue', 'text-label')}>
        {NAME_TO_LABEL[label]}
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(revenue)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(revenue)]} />
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(ebitda)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(ebitda)]} />
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(growth)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(growth)]} />
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(ebitdaMargin)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(ebitdaMargin)]} />
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(ebitdaMarginExpansion)]
              .backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(ebitdaMarginExpansion)]} />
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(runway)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(runway)]} />
      </TD>
      <TD
        style={{
          backgroundColor:
            CRITERIA_VALUE_STYLES[String(industry)].backgroundColor,
        }}
      >
        <Criteria {...CRITERIA_VALUE_STYLES[String(industry)]} />
      </TD>
    </TR>
  );
};
