import React from 'react';
import { FileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { ChecklistItem } from '@hum/legacy-ui';

import {
  useUploadSection,
  FileDropZone,
  UploadItem,
  ImportDocumentSection,
  csvAndExcelOnlyAcceptString,
  csvAndExcelOnlyMimeTypes,
} from '@hum/icm-app/src/pages/onboarding/ImportDocuments';
import * as importDocumentsStyles from '@hum/icm-app/src/pages/onboarding/ImportDocuments/index.pc';
import * as styles from './index.pc';
import { DownloadModelFile } from '@hum/icm-app/src/pages/syndication-v2/MatchDataRoom';
import { Company, CompanyFileDocumentType } from '@hum/types';
import { Result } from '@hum/common/src/ducks/state';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

export type DocumentSection = {
  type: CompanyFileDocumentType;
  label: string;
  description?: string;
  open?: boolean;
  required: boolean;
  checklistTitle?: string;
  checklistItems?: string[];
  csvAndExcelOnly: boolean;
};

type DocumentUploadProps = {
  section: DocumentSection;
  fileManager: FileManager;
  largeBorderRadius?: boolean;
  noGap?: boolean;
  hideDates?: boolean;
  fileLimit?: number;
  fileMeta?: Record<string, any>;
  hideDownload?: boolean;
  disableToggle?: boolean;
};

export const DocumentUpload = ({
  section: info,
  fileManager,
  largeBorderRadius,
  noGap,
  hideDates,
  fileLimit,
  fileMeta,
  hideDownload,
}: DocumentUploadProps) => {
  const {
    label,
    description,
    required,
    checklistTitle,
    checklistItems,
    type: documentType,
    csvAndExcelOnly,
  } = info;

  // generate section so it's compatible with useUploadSection
  const section: ImportDocumentSection = {
    label,
    documentType,
    meta: fileMeta,
    description,
    checklist: [],
  };

  const {
    onFileUploadClick,
    onFileUploadChange,
    // accept,
  } = useUploadSection(section, fileManager);

  const uploadItems = fileManager.files.filter((file) => {
    let check = file.documentType === section.documentType;
    if (fileMeta?.target_company_id) {
      check =
        check && fileMeta.target_company_id === file.meta.target_company_id;
    }
    return check;
  });

  return (
    <styles.CompanyDocumentsSection
      testId={`company-activation:document-data-room:${documentType}`}
      largeBorderRadius={largeBorderRadius}
      disableToggle
      expanded
      description={description}
      noSpace={noGap}
      label={label}
      checklistTitle={checklistTitle}
      checklistItems={
        (checklistItems?.length &&
          checklistItems.map((checklistItem, i) => (
            <ChecklistItem key={i}>{checklistItem}</ChecklistItem>
          ))) ||
        null
      }
      optional={!required}
      fileDropZone={
        fileLimit && fileLimit == uploadItems.length ? null : (
          <FileDropZone
            onFileUploadClick={onFileUploadClick}
            onFileUploadChange={onFileUploadChange}
            accept={csvAndExcelOnly ? csvAndExcelOnlyAcceptString : '*'}
            style={{ flex: 1 }}
            acceptedFileTypesLabel={
              csvAndExcelOnly
                ? 'Excel and CSV files only'
                : 'All formats accepted'
            }
            acceptedFileMimeTypes={
              csvAndExcelOnly ? csvAndExcelOnlyMimeTypes : []
            }
            invalidFileMimeTypeError={
              csvAndExcelOnly ? 'Only Excel and CSV files are allowed' : ''
            }
          />
        )
      }
      uploadItems={
        <importDocumentsStyles.UploadItems>
          {uploadItems.map((file) => {
            return (
              <UploadItem
                key={file.id}
                file={file}
                fileManager={fileManager}
                hideDownload={hideDownload}
                hideDates={hideDates}
              />
            );
          })}
        </importDocumentsStyles.UploadItems>
      }
    />
  );
};

type CompanyDocumentsProps = {
  sections: DocumentSection[];
  fileManager: FileManager;
  largeBorderRadius?: boolean;
  noGap?: boolean;
  hideDates?: boolean;
  fileMeta?: Record<string, any>;
  company?: Result<Company>;
};

export const CompanyDocuments = ({
  sections,
  fileManager,
  largeBorderRadius,
  noGap,
  hideDates,
  fileMeta,
  company,
}: CompanyDocumentsProps) => {
  const flags = useFlags();
  return (
    <>
      {sections.map((section) => (
        <DocumentUpload
          noGap={noGap}
          hideDates={hideDates}
          key={section.type}
          section={section}
          fileManager={fileManager}
          largeBorderRadius={largeBorderRadius}
          fileMeta={fileMeta}
        />
      ))}
      {flags.enabled('show-hum-enhanced-files-company') && (
        <styles.CompanyDocumentsSection
          label="Hum standardized data"
          description="Financial files Hum generates from your accounting software or uploaded files to provide a standardized format to share with investors."
          testId={`company-activation:document-data-room:hum-enhanced-files`}
          expanded
          disableToggle
          locked
          uploadItems={
            company && (
              <DownloadModelFile companyId={Number(company.data?.id)} />
            )
          }
        />
      )}
    </>
  );
};
