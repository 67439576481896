import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { routerUrlPushed } from '@hum/icm-app/src/actions';
import { UrlObject, parse } from 'url';
import { HistoryState } from '@hum/common';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
type Url = UrlObject | string;

/**
 * _light_ wrapper for ../../hooks/useRouter so that we have the ability to listen for history changes
 */

export const useRouter = <
  TQuery extends Record<string, undefined | string | string[]>
>() => {
  const { dispatch } = useAppStore();
  const history = useHistory();

  return {
    asPath: history.location.pathname + history.location.search,
    pathname: history.location.pathname,
    query: qs.parse(history.location.search) as TQuery,
    push(url: Url) {
      const parts = typeof url === 'string' ? parse(url, true) : url;

      // TODO - may need to remove
      dispatch(routerUrlPushed(parts as Partial<HistoryState>));
    },
  };
};
