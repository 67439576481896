import React from 'react';
import { Icon, Text, TextType } from '@hum/ui-library';
import { CompanyProfileProps } from '../FinancialInsights/FinancialInsightsDocument/CompanyProfile';
import {
  currency,
  decimal,
  titleCase,
} from '../FinancialInsights/FinancialInsightsDocument/utils';

export const CompanyProfile = ({
  companyInformation,
  currentDealDetails,
  historicalFundingInformation,
}: CompanyProfileProps) => {
  const investors =
    historicalFundingInformation.investors.length > 3
      ? historicalFundingInformation.investors
          .slice(0, 3)
          .map(titleCase)
          .join(', ') + '...'
      : historicalFundingInformation.investors.map(titleCase).join(', ');

  return (
    <section className="grid grid-cols-1 container mx-auto md:grid-cols-6 gap-4 p-4 md:py-4">
      <div className="bg-[#F9F9F9] md:col-span-2 p-4">
        <Icon
          width="104px"
          className="mb-6"
          src="https://assets.humcapital.com/hum-logo-rgb.png"
        />
        <Text type={TextType.HEADING_L} className="text-black mb-6">
          Thanks you for connecting to Hum Capital!
        </Text>
        <Text type={TextType.BODY_M} className="text-black mb-4">
          By connecting your financial data to Hum Capital’s Intelligent Capital
          Market (ICM) you get access to how Hum Capital and the investors on
          the ICM evaluate at your business. You can leverage this data to
          understand how your company stacks up against your industry peers,
          which areas you excel, and which areas would increase your company’s
          potential to receive a non-dilutive funding opportunity.
        </Text>
        <Text type={TextType.BODY_M} className="text-black mb-4">
          For more information on the terms within this document, see the
          glossary
        </Text>
      </div>
      <div className="md:col-span-4">
        <div>
          <Text type={TextType.HEADING_L} className="text-black mb-4">
            {companyInformation.companyName}
          </Text>
          <a
            href={companyInformation.website}
            className="ui-link text-[#0A168E]"
          >
            {companyInformation.website}
          </a>
          <Text type={TextType.BODY_M} className="text-black mb-4">
            ICM ID: {companyInformation.icmId}
          </Text>
        </div>
        <div className="bg-[#0A168E] p-4">
          <Text
            type={TextType.BODY_M}
            className="text-white text-left uppercase"
          >
            Company Information
          </Text>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="md:col-span-1 bg-[#ECF1FE] p-2">
            <Text type={TextType.HEADING_M} className="text-black">
              Industry
            </Text>
          </div>
          <div className="md:col-span-2 p-2">
            <Text type={TextType.BODY_M} className="text-black">
              {companyInformation.industry}
            </Text>
          </div>
          <div className="md:col-span-1 bg-[#ECF1FE] p-2">
            <Text type={TextType.HEADING_M} className="text-black">
              Has recurring revenue
            </Text>
          </div>
          <div className="md:col-span-2 p-2">
            <Text type={TextType.BODY_M} className="text-black">
              {companyInformation.hasRecurringRevenu ? 'True' : 'False'}
            </Text>
          </div>
          <div className="md:col-span-1 bg-[#ECF1FE] p-2">
            <Text type={TextType.HEADING_M} className="text-black">
              Runway (Num of quarters)
            </Text>
          </div>
          <div className="md:col-span-2 p-2">
            <Text type={TextType.BODY_M} className="text-black">
              {decimal.format(companyInformation.runwayInQuarters)}
            </Text>
          </div>
        </div>
        {Object.values(historicalFundingInformation).some((v) =>
          Array.isArray(v) ? v.length > 0 : !!v
        ) && (
          <>
            <div className="bg-[#0A168E] p-4">
              <Text
                type={TextType.BODY_M}
                className="text-white text-left uppercase"
              >
                Historical Funding Information
              </Text>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3">
              {!!investors && (
                <>
                  <div className="col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Investors
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {investors}
                    </Text>
                  </div>
                </>
              )}
              {!!historicalFundingInformation.historicalRaiseTypes.length && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Historical raise types
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    {historicalFundingInformation.historicalRaiseTypes.length >
                      0 && (
                      <Text type={TextType.BODY_M} className="text-black">
                        {historicalFundingInformation.historicalRaiseTypes.join(
                          ', '
                        )}
                      </Text>
                    )}
                  </div>
                </>
              )}
              {historicalFundingInformation.totalCapitalRaised !== null && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Total capital raised
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {currency.format(
                        historicalFundingInformation.totalCapitalRaised
                      )}
                    </Text>
                  </div>
                </>
              )}
              {historicalFundingInformation.totalEquityRaised !== null && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Total equity raised
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {currency.format(
                        historicalFundingInformation.totalEquityRaised
                      )}
                    </Text>
                  </div>
                </>
              )}
              {historicalFundingInformation.totalDebtRaised !== null && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Total debt raised
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {currency.format(
                        historicalFundingInformation.totalDebtRaised
                      )}
                    </Text>
                  </div>
                </>
              )}
              {historicalFundingInformation.latestRaiseDate !== null && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Latest raise date
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {historicalFundingInformation.latestRaiseDate}
                    </Text>
                  </div>
                </>
              )}
              {historicalFundingInformation.latestRaiseType &&
                historicalFundingInformation.latestRaiseType !== null && (
                  <>
                    <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                      <Text type={TextType.HEADING_M} className="text-black">
                        Latest raise type
                      </Text>
                    </div>
                    <div className="md:col-span-2 p-2">
                      <Text type={TextType.BODY_M} className="text-black">
                        {historicalFundingInformation.latestRaiseType}
                      </Text>
                    </div>
                  </>
                )}
              {historicalFundingInformation.latestRaiseAmount !== null && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Latest raise amount
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {currency.format(
                        historicalFundingInformation.latestRaiseAmount
                      )}
                    </Text>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {Object.values(currentDealDetails).some((v) => !!v) && (
          <>
            <div className="bg-[#0A168E] p-4">
              <Text
                type={TextType.BODY_M}
                className="text-white text-left uppercase"
              >
                Current Deals Details
              </Text>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3">
              {!!currentDealDetails.dealType && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Deal type
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {currentDealDetails.dealType}
                    </Text>
                  </div>
                </>
              )}
              {!!currentDealDetails.useOfProceeds && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Use of proceeds
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {currentDealDetails.useOfProceeds}
                    </Text>
                  </div>
                </>
              )}
              {currentDealDetails.expectedRaiseAmount !== null && (
                <>
                  <div className="md:col-span-1 bg-[#ECF1FE] p-2">
                    <Text type={TextType.HEADING_M} className="text-black">
                      Expected raise amount
                    </Text>
                  </div>
                  <div className="md:col-span-2 p-2">
                    <Text type={TextType.BODY_M} className="text-black">
                      {typeof currentDealDetails.expectedRaiseAmount ===
                      'number'
                        ? currency.format(
                            currentDealDetails.expectedRaiseAmount
                          )
                        : currentDealDetails.expectedRaiseAmount}
                    </Text>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};
