import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { CreditBoxTable } from './CreditBoxTable';
import {
  CREDIT_BOX_ORDER,
  IcmEvaluationProps,
} from '../../FinancialInsights/FinancialInsightsDocument/IcmEvaluation';

export const IcmEvaluation = ({ header, creditBox }: IcmEvaluationProps) => {
  const { strategicCapital, ...creditBoxes } = creditBox;
  const { companyMetrics } = header;

  return (
    <>
      <section className="p-4 md:py-8 container mx-auto">
        <div className="w-full md:w-2/3">
          <Text type={TextType.PAGE_HEADING} className="text-black mb-4">
            Hum’s Intelligent Capital Marketplace Evaluation
          </Text>
          <Text type={TextType.BODY_M} className="text-black mb-4">
            Hum utilizes your company’s connected accounting data to assess your
            financials against various non-dilutive capital opportunities
            available through Hum’s ICM, today. This evaluation is not a
            representation of your ability to receive funding in the broader
            Investor market. Learn more about the investors on Hum’s ICM and how
            we evaluate your business
          </Text>
          <Text type={TextType.BODY_M} className="text-black mb-4">
            If any of the criteria are not met within any of the categories,
            stay connected to Hum. As your business continues to grow, Hum will
            automatically track your progress towards accessing nondilutive
            capital on our platform and notify you of new opportunities.
          </Text>
        </div>
      </section>
      <CreditBoxTable
        metrics={companyMetrics}
        creditBoxes={CREDIT_BOX_ORDER.reduce(
          (boxes, name) => ({
            ...boxes,
            [name]: creditBoxes[name],
          }),
          {}
        )}
      />
    </>
  );
};
