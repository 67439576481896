import { syndicationInvestorDetailsHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';

export const useGetInvestorDetails = (companyId: number, matchUuid: string) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    dispatch(syndicationInvestorDetailsHookUsed({ companyId, matchUuid }));
  }, [companyId]);

  return {
    ...state.syndicationInvestorDetails,
  };
};
