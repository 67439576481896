import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

declare type Props = {
  to: string;
  label: string;
  className?: string;
  back?: boolean;
};

/**
 * @deprecated
 */

export const Link: React.FC<Props> = ({
  to,
  label,
  className,
  back,
  ...props
}) => (
  <a
    className="ui-link"
    href={to}
    {...(className ? { className } : '')}
    {...props}
  >
    {back && <ChevronLeftIcon />}
    {label}
  </a>
);
