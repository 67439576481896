import React from 'react';

import { Text, Image, View } from '@react-pdf/renderer';

import {
  FinancingProps,
  CAPITAL_STRUCTURE_VARIABLES,
  CAPITAL_STRUCTURE_LABELS,
  CAPITAL_STRUCTURE_COLORs,
} from '@hum/types';

import { tw } from './utils';
import { BookmarkedPage, PageHeader } from './common';
import { usePieDataUrl } from './hooks';

export const Financing = ({ companyName, benchmarking }: FinancingProps) => {
  const { data, company } = benchmarking;

  const capitalStructureUrl = usePieDataUrl({
    id: 'capitalStructure',
    title: `${companyName}'s Capital Structure`,
    width: 900,
    height: 675,
    values: CAPITAL_STRUCTURE_VARIABLES.map((variable) =>
      Math.max(0, company.capitalStructure[variable])
    ),
    labels: CAPITAL_STRUCTURE_LABELS,
    colors: CAPITAL_STRUCTURE_COLORs,
  });

  const capitalStructureBenchmarkUrl = usePieDataUrl({
    id: 'capitalStructureBenchmark',
    title: 'Capital Structure Benchmark',
    width: 900,
    height: 675,
    values: CAPITAL_STRUCTURE_VARIABLES.map((variable) =>
      Math.max(0, data[variable]?.topQuartile)
    ),
    labels: CAPITAL_STRUCTURE_LABELS,
    colors: CAPITAL_STRUCTURE_COLORs,
  });

  return (
    <BookmarkedPage title="Financing" break>
      <PageHeader title="Financing" />
      <View style={tw('border-b-4', 'border-b-palatinate-blue', 'm-1', 'mt-0')}>
        <Text style={tw('text-body', 'mb-1', 'w-1/2')}>
          Striking the right balance between debt and equity allows your company
          to optimize its cost of capital, manage financial risk, and maintain
          flexibility for future growth and investment opportunities. See how
          your business compares with your industry peers.
        </Text>
      </View>
      <View style={[tw('flex-row', 'm-1', 'pt-1.5', 'justify-between')]}>
        {capitalStructureUrl && (
          <View style={[tw('mr-1'), { width: 1400, height: 1050 }]}>
            <Image src={capitalStructureUrl} />
          </View>
        )}
        {capitalStructureBenchmarkUrl && (
          <View style={{ width: 1400, height: 1050 }}>
            <Image src={capitalStructureBenchmarkUrl} />
          </View>
        )}
      </View>
    </BookmarkedPage>
  );
};
