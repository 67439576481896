import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import _pubFa41B678, {Icon as _pubFa41B678_Icon} from "../../../../design-system/src/Icon.pc";
import _pubFe0274B0, {TooltipContainer as _pubFe0274B0_TooltipContainer} from "../../../../design-system/src/Tooltip.pc";
import _pub4435Fe58 from "../../../../design-system/src/Pill.pc";
import _pub9A961Ec6, {AccordionContent as _pub9A961Ec6_AccordionContent} from "../../../../design-system/src/Accordion.pc";
import _pubB415F4B5 from "../../../../design-system/src/FileDropZone.pc";
import _pub74092D51, {ChecklistItem as _pub74092D51_ChecklistItem} from "../../../../design-system/src/Checklist.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var CompanyDocumentsSection = React.memo(React.forwardRef(function CompanyDocumentsSection(props, ref) {
  return React.createElement(_pub9A961Ec6, {
    "class": "_c22e29fa _1fe26f90 _pub-1fe26f90 _pub-a97d53f3" + (props["noSpace"] ? " " + "_1fe26f90_nospace _pub-1fe26f90_nospace _pub-a97d53f3_nospace nospace" : ""),
    "ref": ref,
    "expanded": props["expanded"],
    "largeBorderRadius": props["largeBorderRadius"],
    "onExpandClick": props["onExpandClick"],
    "onHeaderClick": props["onHeaderClick"],
    "testId": props["testId"],
    "disableToggle": props["disableToggle"],
    "label": React.createElement(React.Fragment, {
    }, 
      React.createElement("div", {
        "className": "_eb61ee17 _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
      }, 
        React.createElement("img", {
          "className": "_721ce8a2 _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
          "src": getDefault(require("@hum/figma/spot-icons/symbols/files-folder-solid@1.svg")),
        }, null),
        React.createElement(_pubFe0274B0_TooltipContainer, {
          "class": "_eb15b918 _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
        }, 
          props["description"]&&React.createElement(_pubFe0274B0, {
            "class": "_7ca409c7 _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
          }, 
            props["description"]
          ),
          React.createElement("span", {
            "className": "_8b304e _1fe26f90 _pub-1fe26f90 _pub-a97d53f3" + (props["description"] ? " " + "_1fe26f90_has-description _pub-1fe26f90_has-description _pub-a97d53f3_has-description has-description" : ""),
          }, 
            props["label"]
          )
        ),
        React.createElement("div", {
          "className": "_9c12898e _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
        }, 
          React.createElement(_pubFe0274B0_TooltipContainer, {
            "class": "_764e6aef _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
          }, 
            props["locked"]&&React.createElement("img", {
              "className": "_9a8372af _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
              "src": getDefault(require("@hum/figma/spot-icons/symbols/general-lock@1.svg")),
            }, null),
            props["lockedDescription"]&&React.createElement(_pubFe0274B0, {
              "class": "_839843ee _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
            }, 
              props["lockedDescription"]
            )
          )
        ),
        React.createElement("div", {
          "className": "_2761c2d _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
        }, 
          props["optional"]&&React.createElement(_pub4435Fe58, {
            "class": "_59cf561b _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
          }, 
            "Optional"
            
          )
        )
      )
    ),
  }, 
    props["expanded"]&&React.createElement(_pub9A961Ec6_AccordionContent, {
      "class": "_f935f5f _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_f6da4a47 _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
      }, 
        props["fileDropZone"],
        props["checklistTitle"]&&React.createElement(_pub74092D51, {
          "class": "_1d386f8a _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
          "icon": React.createElement(_pubFa41B678_Icon, {
            "class": "_f9ab8d70 _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
            "masked": true,
            "src": "@hum/assets/feather/file.svg",
          }, null),
          "slim": true,
          "description": props["checklistTitle"],
        }, 
          props["checklistItems"]
        )
      ),
      props["uploadItems"]
    )
  )
}));
export { CompanyDocumentsSection };

var CompanyDocumentsSectionPreview = React.memo(React.forwardRef(function CompanyDocumentsSectionPreview(props, ref) {
  return React.createElement(CompanyDocumentsSection, {
    "class": "_16af5094",
    "ref": ref,
    "noSpace": props["noSpace"],
    "expanded": props["expanded"],
    "description": props["description"],
    "label": props["label"],
    "optional": true,
    "fileDropZone": React.createElement(_pubB415F4B5, {
      "class": "_d74af1e _1fe26f90 _pub-1fe26f90 _pub-a97d53f3",
    }, null),
  }, null)
}));
export { CompanyDocumentsSectionPreview };


