import React, { memo } from 'react';
import { useSession } from '@hum/icm-app/src/hooks/useSession';
import { InvestorTopbar } from './InvestorTopbar';
import { StandardTopbar } from './StandardTopbar';

export type TopbarProps = {
  title?: string;
  fixedBar?: boolean;
  links?: any[];
  background?: string;
  flow?: boolean;
};

// TODO: Re-evalute architecture during tech debt sprint
export const Topbar = memo(
  ({
    title,
    fixedBar,
    links,
    background = 'bg-gray-900',
    flow,
  }: TopbarProps) => {
    const {
      isAdmin,
      isCompany,
      isPortfolioInvestor,
      isSyndicationInvestor,
    } = useSession();

    if (isAdmin || isCompany)
      return (
        <StandardTopbar
          title={title}
          fixedBar={fixedBar}
          links={links}
          background={background}
          flow={flow}
        />
      );

    if (isPortfolioInvestor || isSyndicationInvestor)
      return (
        <InvestorTopbar
          title={title}
          fixedBar={fixedBar}
          links={links}
          background={background}
          flow={flow}
        />
      );

    return null;
  }
);
Topbar.displayName = 'Topbar';
