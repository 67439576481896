/* eslint-disable @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps, no-console */
import { useState, useEffect } from 'react';

const getGlobal = (name: string) =>
  typeof window !== 'undefined' && typeof window[name] !== 'undefined'
    ? window[name]
    : undefined;

export const waiters = {};

/**
 * Helper hook to asynchronous extract a library from the global scope.
 */
const useGlobal = <GlobalType extends any>({
  name,
  condition = () => true,
  initial,
  fallback,
  step = 500,
  timeout,
  transform = (value) => value,
}: {
  name: string;
  condition?: () => boolean;
  initial?: GlobalType;
  fallback?: GlobalType;
  step?: number;
  timeout?: number;
  transform?: (value: GlobalType) => GlobalType | undefined;
}): ReturnType<typeof transform> => {
  // always fallback to a mocked instance
  const current = initial || getGlobal(name);

  const [library, setLibrary] = useState(() =>
    typeof current !== 'undefined' ? transform(current) : undefined
  );

  const set = (value?: GlobalType) =>
    setLibrary(() => (typeof value !== 'undefined' ? transform(value) : value));

  useEffect(() => {
    if (typeof library !== 'undefined' || !condition()) {
      return;
    }

    // if someone is already waiting for this global,
    // just add the current state setter to the callbacks.
    if (waiters[name]) {
      waiters[name].push(set);

      // register individual timeout behavior.
      if (timeout) {
        setTimeout(() => {
          set(fallback || initial);

          console.warn(
            `Library \`{name}\` was not found in the global scope after {timeout}ms - using fallback, if provided`
          );
        }, timeout);
      }

      return;
    }

    // initialize waiters callbacks.
    waiters[name] = [set];

    // register the waiter interval checker.
    const interval = setInterval(() => {
      const value = getGlobal(name);

      if (typeof value !== 'undefined') {
        for (const waiter of waiters[name]) {
          waiter(value);
        }

        delete waiters[name];
        clearInterval(interval);
      }
    }, step);

    // register individual timeout behavior.
    if (timeout) {
      setTimeout(() => {
        delete waiters[name];
        set(fallback || initial);
        clearInterval(interval);

        console.warn(
          `Library \`{name}\` was not found in the global scope after {timeout}ms - using fallback, if provided`
        );
      }, timeout);
    }
  }, [name]);

  return library;
};

export { useGlobal };
