import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { ProprietaryScoringModelsProps } from '../FinancialInsights/FinancialInsightsDocument/ProprietaryScoringModels';
import {
  currency,
  percentage,
} from '../FinancialInsights/FinancialInsightsDocument/utils';

export const ProprietaryScoringModels = ({
  humsProprietaryScoringModels,
}: ProprietaryScoringModelsProps) => {
  const { models } = humsProprietaryScoringModels;
  const { maxDebt, maxNewDebt } = models;

  return (
    <section className="container mx-auto p-4 md:py-4">
      <Text type={TextType.PAGE_HEADING} className="text-black mb-6">
        How much non-dilutive capital can you raise?
      </Text>
      <Text type={TextType.BODY_M} className="text-black mb-4">
        The Max Debt and Max New Debt values are calculated using Hum’s
        proprietary models, which consider company performance indicators such
        as revenue, EBITDA, and existing debt. For more details, please refer to
        this{' '}
        <a
          className="ui-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://info.humcapital.com/hum-help-center/what-is-the-max-raise-calculator"
        >
          support article.
        </a>
      </Text>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-6 items-center border-b border-t border-cta-primary-default">
        <div>
          <Text type={TextType.HEADING_L} className="text-black mb-4">
            Maximum Debt
          </Text>
          <Text type={TextType.BODY_M} className="text-black">
            Hum calculates your company’s estimated maximum debt capacity.
          </Text>
          <Text type={TextType.BODY_M} className="text-black">
            Year-over-year (YoY) growth reflects the additional or reduced debt
            your company can handle compared to the previous year.
          </Text>
        </div>
        <div className="text-center">
          <Text type={TextType.DISPLAY_L} className="text-cta-primary-default">
            {maxDebt.value === null ? '-' : currency.format(maxDebt.value)}
          </Text>
          <Text
            type={TextType.BODY_M}
            forceCapitalization
            className="text-black"
          >
            Est. Max Debt
          </Text>
        </div>
        <div className="text-center">
          <Text type={TextType.DISPLAY_L} className="text-cta-primary-default">
            {maxDebt.yoyGrowth === null
              ? '-'
              : percentage.format(maxDebt.yoyGrowth)}
          </Text>
          <Text
            type={TextType.BODY_M}
            forceCapitalization
            className="text-black"
          >
            YoY Growth%
          </Text>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-6 items-center border-b border-cta-primary-default">
        <div>
          <Text type={TextType.HEADING_L} className="text-black mb-4">
            Maximum New Debt
          </Text>
          <Text type={TextType.BODY_M} className="text-black">
            Based on your current financial performance, we estimated the total
            amount of new debt that your company can comfortably handle. This
            calculation takes into account any existing debt.
          </Text>
          <Text type={TextType.BODY_M} className="text-black">
            Year-over-year (YoY) growth reflects the additional or reduced new
            debt your company can handle compared to the previous year.
          </Text>
        </div>
        <div className="text-center">
          <Text type={TextType.DISPLAY_L} className="text-cta-primary-default">
            {maxNewDebt.value === null
              ? '-'
              : currency.format(maxNewDebt.value)}
          </Text>
          <Text
            type={TextType.BODY_M}
            forceCapitalization
            className="text-black"
          >
            Est. Max New Debt
          </Text>
        </div>
        <div className="text-center">
          <Text type={TextType.DISPLAY_L} className="text-cta-primary-default">
            {maxNewDebt.yoyGrowth === null
              ? '-'
              : percentage.format(maxNewDebt.yoyGrowth)}
          </Text>
          <Text
            type={TextType.BODY_M}
            forceCapitalization
            className="text-black"
          >
            YoY Growth%
          </Text>
        </div>
      </div>
    </section>
  );
};
