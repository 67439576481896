import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Routes, AppState } from '@hum/icm-app/src/state';
import { getAuthRedirectUrl } from '@hum/icm-app/src/sagas/auth';
import { useSession } from '@hum/icm-app/src/hooks/useSession';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useIsWhitelabeled } from '@hum/icm-app/src/hooks/useIsWhitelabeled';
import TopbarProfileMenu from '@hum/icm-app/src/components/TopbarProfileMenu';
import { TopbarProps } from './Topbar';
import { Icon, Topbar, Text, TextType } from '@hum/ui-library';

// TODO: Re-evalute architecture and DRY during tech debt sprint
export const InvestorTopbar = memo(
  ({
    title,
    fixedBar,
    links,
    background = 'bg-gray-900',
    flow,
  }: TopbarProps) => {
    const history = useHistory();
    const isBranded = useIsWhitelabeled();
    const flags = useFlags();
    const {
      isCompany,
      isPortfolioInvestor,
      isSyndicationInvestor,
    } = useSession();
    const { session, whitelabeling } = useSelector((state: AppState) => state);

    const currentUser = session.data;

    const navigationLinks: any[] = [];

    const isInAdminPage = history.location.pathname.includes(Routes.ADMIN);

    const getRootRedirectUrl = () => {
      if (flags.enabled('remove-get-started') && isCompany) {
        return Routes.COMPANY_ACTIVATION_GET_STARTED;
      }
      if (currentUser) {
        return getAuthRedirectUrl(currentUser, Routes.ROOT) || Routes.ROOT;
      }

      return Routes.ROOT;
    };

    const rootRedirectUrl = getRootRedirectUrl();

    if (!isInAdminPage && !links) {
      if (isSyndicationInvestor) {
        navigationLinks.push(
          <a className="navlink-item" href={`${process.env.V2_BASE_URL}/`}>
            <Text type={TextType.BODY_S} className="cursor-pointer" subdued>
              Deals
            </Text>
          </a>
        );
      }
    }

    if (isPortfolioInvestor && !links) {
      navigationLinks.push(
        <NavLink
          className="navlink-item"
          activeClassName="active"
          to={Routes.PORTFOLIO_MONITORING + history.location.search}
        >
          <Text type={TextType.BODY_S} className="cursor-pointer" subdued>
            Portfolio Monitoring
          </Text>
        </NavLink>
      );
    }

    const logo = isBranded ? (
      <a href={rootRedirectUrl}>
        <Icon.Hum />
        {whitelabeling?.data?.editedLogoFileUrl && (
          <>
            <div className="bg-[#393939] w-[1px] h-full my-0 mx-[28px]"></div>
            <img
              className="object-contain"
              src={whitelabeling.data?.editedLogoFileUrl}
            />
          </>
        )}
      </a>
    ) : (
      <a href={rootRedirectUrl}>
        <Icon.Hum />
      </a>
    );

    return (
      <Topbar
        title={title}
        navigationLinks={navigationLinks}
        userLinks={<TopbarProfileMenu />}
        logo={logo}
        fixedBar={fixedBar}
        background={background}
        flow={flow}
      />
    );
  }
);

InvestorTopbar.displayName = 'InvestorTopbar';
