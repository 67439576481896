import React from 'react';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';

import { CompanyMetrics, CreditBox } from '@hum/types';

import { tw, currency, decimal, percentage } from '../utils';
import { CreditBoxRow } from './CreditBoxRow';

export type CreditBoxTableProps = {
  creditBoxes: Record<string, CreditBox>;
  metrics: CompanyMetrics;
};

export const CreditBoxTable = ({
  creditBoxes,
  metrics,
}: CreditBoxTableProps) => {
  const {
    arr,
    ebitda,
    revenueGrowth,
    ebitdaMargin,
    ebitdaMarginGrowth,
    runway,
    zoominfoIndustry,
  } = metrics;

  return (
    <Table
      wrap
      weightings={[0.125]}
      style={[tw('m-1', 'pr-1'), { transform: 'translateY(-40px)' }]}
      tdStyle={tw('px-1', 'py-0.5', 'text-body', 'border-2', 'border-platinum')}
    >
      <TH style={tw('bg-indigo-dye', 'text-h4', 'text-white', 'border-none')}>
        <TD style={tw('border-none')} />
        <TD style={tw('border-none', 'text-white')}>Revenue</TD>
        <TD style={tw('border-none', 'text-white')}>EBITDA</TD>
        <TD style={tw('border-none', 'text-white')}>Growth</TD>
        <TD style={tw('border-none', 'text-white')}>EBITDA margin</TD>
        <TD style={tw('border-none', 'text-white')}>EBITDA margin expansion</TD>
        <TD style={tw('border-none', 'text-white')}>Runway</TD>
        <TD style={tw('border-none', 'text-white')}>Industry</TD>
      </TH>
      <TR>
        <TD style={tw('border-none', 'bg-alice-blue', 'text-label')}>
          Company Data
        </TD>
        <TD style={tw('text-body')}>
          {arr === null || isNaN(arr) ? '-' : currency.format(arr)}
        </TD>
        <TD style={tw('text-body')}>
          {ebitda === null || isNaN(ebitda) ? '-' : currency.format(ebitda)}
        </TD>
        <TD style={tw('text-body')}>{percentage.format(revenueGrowth)}</TD>
        <TD style={tw('text-body')}>
          {ebitdaMargin === null || isNaN(ebitdaMargin)
            ? '-'
            : percentage.format(ebitdaMargin)}
        </TD>
        <TD style={tw('text-body')}>
          {ebitdaMarginGrowth === null || isNaN(ebitdaMarginGrowth)
            ? '-'
            : percentage.format(ebitdaMarginGrowth)}
        </TD>
        <TD style={tw('text-body')}>
          {runway === null || isNaN(runway) ? '-' : decimal.format(runway)}
        </TD>
        <TD style={tw('text-body')}>{zoominfoIndustry}</TD>
      </TR>
      {/* split credit boxes into two pages */}
      {Object.entries(creditBoxes).map(([name, creditBox]) => {
        return <CreditBoxRow key={name} label={name} creditBox={creditBox} />;
      })}
    </Table>
  );
};
