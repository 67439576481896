import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import {
  CAPITAL_STRUCTURE_COLORs,
  CAPITAL_STRUCTURE_LABELS,
  CAPITAL_STRUCTURE_VARIABLES,
  FinancingProps,
} from '@hum/types';
import { ChartBase } from '@hum/icm-app/src/components/ChartBase';

export const Financing = ({ companyName, benchmarking }: FinancingProps) => {
  const { data, company } = benchmarking;

  return (
    <>
      <section className="p-4 md:py-8 container mx-auto">
        <div className="w-full md:w-2/3">
          <Text type={TextType.PAGE_HEADING} className="text-black mb-4">
            Financing
          </Text>
          <Text type={TextType.BODY_M} className="text-black mb-4">
            Striking the right balance between debt and equity allows your
            company to optimize its cost of capital, manage financial risk, and
            maintain flexibility for future growth and investment opportunities.
            See how your business compares with your industry peers.
          </Text>
        </div>
      </section>
      <section className="p-4 md:py-8 container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 my-2 gap-2">
          <div>
            <ChartBase
              data={[
                {
                  type: 'pie',
                  values: CAPITAL_STRUCTURE_VARIABLES.map((variable) =>
                    Math.max(0, company.capitalStructure[variable])
                  ),
                  hole: 0.6,
                  marker: {
                    colors: CAPITAL_STRUCTURE_COLORs,
                  },
                  labels: CAPITAL_STRUCTURE_LABELS,
                },
              ]}
              layout={{
                title: `${companyName}'s Capital Structure`,
                height: 450,
                font: {
                  family: "'Inter', sans-serif",
                },
                yaxis: {
                  separatethousands: true,
                },
              }}
              config={{
                displayModeBar: false,
                responsive: true,
              }}
            />
          </div>
          <div>
            <ChartBase
              data={[
                {
                  type: 'pie',
                  values: CAPITAL_STRUCTURE_VARIABLES.map((variable) =>
                    Math.max(0, data[variable]?.topQuartile)
                  ),
                  hole: 0.6,
                  marker: {
                    colors: CAPITAL_STRUCTURE_COLORs,
                  },
                  labels: CAPITAL_STRUCTURE_LABELS,
                },
              ]}
              layout={{
                title: 'Capital Structure Benchmark',
                height: 450,
                font: {
                  family: "'Inter', sans-serif",
                },
                yaxis: {
                  separatethousands: true,
                },
              }}
              config={{
                displayModeBar: false,
                responsive: true,
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};
