import * as _ from 'lodash';

export const valueToScale = (value: number): string => `${value * 40}`;

// names determiend by using https://www.color-name.com/hex
export enum Color {
  Black = '#000000',
  IndigoDye = '#0A168E',
  BlueOrchid = '#4A66F4',
  AliceBlue = '#ECF1FE',
  GhostWhite = '#FCFCFC',
  LightPeriwinkle = '#E2E8F0',
  Platinum = '#E6E6E6',
  PalatinateBlue = '#2746ED',
  White = '#FFFFFF',
  TeaGreen = '#D4EDD2',
  DustStorm = '#EFCBCE',
  BlackPearl = '#151C23',
  Charcoal = '#3E4758',
  TealDeer = '#97EDA8',
  MustardYellow = '#E0BE04',
  GoldenrodYellow = '#DFBF03',
}

// PDF is based on a 40-pixel grid
export const TOKENS = {
  'm-1': {
    margin: valueToScale(1),
  },
  'm-0.5': {
    margin: valueToScale(0.5),
  },
  'my-0.5': {
    marginTop: valueToScale(0.5),
    marginBottom: valueToScale(0.5),
  },
  'my-1': {
    marginTop: valueToScale(1),
    marginBottom: valueToScale(1),
  },
  'mx-0.5': {
    marginLeft: valueToScale(0.5),
    marginRight: valueToScale(0.5),
  },
  'mx-1': {
    marginLeft: valueToScale(1),
    marginRight: valueToScale(1),
  },
  'mx-2': {
    marginLeft: valueToScale(2),
    marginRight: valueToScale(2),
  },
  'mt-2': {
    marginTop: valueToScale(2),
  },
  'mt-0': {
    marginTop: 0,
  },
  'ml-0': {
    marginLeft: 0,
  },
  'ml-1': {
    marginLeft: valueToScale(1),
  },
  'ml-2': {
    marginLeft: valueToScale(2),
  },
  'mr-1': {
    marginRight: valueToScale(1),
  },
  'mr-4': {
    marginRight: valueToScale(4),
  },
  'mr-8': {
    marginRight: valueToScale(8),
  },
  'mb-0.5': {
    marginBottom: valueToScale(0.5),
  },
  'mb-1': {
    marginBottom: valueToScale(1),
  },
  'mb-2': {
    marginBottom: valueToScale(2),
  },
  'mb-4': {
    marginBottom: valueToScale(4),
  },
  'p-0': {
    padding: valueToScale(0),
  },
  'p-0.5': {
    padding: valueToScale(0.5),
  },
  'p-1': {
    padding: valueToScale(1),
  },
  'px-0.5': {
    paddingLeft: valueToScale(0.5),
    paddingRight: valueToScale(0.5),
  },
  'px-1': {
    paddingLeft: valueToScale(1),
    paddingRight: valueToScale(1),
  },
  'py-0.5': {
    paddingTop: valueToScale(0.5),
    paddingBottom: valueToScale(0.5),
  },
  'pt-1': {
    paddingTop: valueToScale(1),
  },
  'pt-1.5': {
    paddingTop: valueToScale(1.5),
  },
  'pb-1': {
    paddingBottom: valueToScale(1),
  },
  'pb-2': {
    paddingBottom: valueToScale(2),
  },
  'pl-0': {
    paddingLeft: valueToScale(0),
  },
  'pr-1': {
    paddingRight: valueToScale(1),
  },
  'p-2': {
    padding: valueToScale(2),
  },
  'border-none': {
    border: 'none',
  },
  'border-1': {
    borderWidth: 1,
  },
  'border-2': {
    borderWidth: 2,
  },
  'border-b-2': {
    borderBottomWidth: 2,
  },
  'border-b-4': {
    borderBottomWidth: 4,
  },
  'border-b-palatinate-blue': {
    borderBottomColor: Color.PalatinateBlue,
  },
  'border-platinum': {
    borderColor: Color.Platinum,
  },
  'h-1/5': {
    height: '20%',
  },
  'h-1/4': {
    height: '25%',
  },
  'h-1/2': {
    height: '50%',
  },
  'w-1/2': {
    width: '50%',
  },
  'w-1/3': {
    width: '33%',
  },
  'w-2/3': {
    width: '67%',
  },
  'w-1/4': {
    width: '25%',
  },
  'w-3/4': {
    width: '75%',
  },
  'w-3/5': {
    width: '60%',
  },
  'w-full': {
    width: '100%',
  },
  'flex-row': {
    flexDirection: 'row',
  },
  'flex-row-reverse': {
    flexDirection: 'row-reverse',
  },
  'flex-col': {
    flexDirection: 'column',
  },
  'flex-wrap': {
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  'justify-center': {
    justifyContent: 'center',
  },
  'justify-between': {
    justifyContent: 'space-between',
  },
  'align-center': {
    alignItems: 'center',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  'font-supera-gothic': {
    fontFamily: 'Supera Gothic',
  },
  'font-inter': {
    fontFamily: 'Inter',
  },
  'font-normal': {
    fontWeight: 400,
  },
  'font-medium': {
    fontWeight: 500,
  },
  'font-semibold': {
    fontWeight: 600,
  },
  'font-black': {
    fontWeight: 900,
  },
  'text-white': {
    color: Color.White,
  },
  'text-primary': {
    color: Color.BlackPearl,
  },
  'text-subdued': {
    color: Color.Charcoal,
  },
  'text-action-cta-default': {
    color: Color.PalatinateBlue,
  },
  'text-platinum': {
    color: Color.Platinum,
  },
  'bg-ghost-white': {
    backgroundColor: Color.GhostWhite,
  },
  'bg-indigo-dye': {
    backgroundColor: Color.IndigoDye,
  },
  'bg-alice-blue': {
    backgroundColor: Color.AliceBlue,
  },
  'bg-palatinate-blue': {
    backgroundColor: Color.PalatinateBlue,
  },
  'bg-platinum': {
    backgroundColor: Color.Platinum,
  },
  'bg-white': {
    backgroundColor: Color.White,
  },
  'fill-tea-green': {
    fill: Color.TeaGreen,
  },
  'fill-dust-storm': {
    fill: Color.DustStorm,
  },
  'fill-platinum': {
    fill: Color.Platinum,
  },
  'fill-white': {
    fill: Color.White,
  },
  'stroke-tea-green': {
    stroke: Color.TeaGreen,
  },
  'stroke-dust-storm': {
    stroke: Color.DustStorm,
  },
  'stroke-platinum': {
    stroke: Color.Platinum,
  },
  'stroke-white': {
    stroke: Color.White,
  },
};

export const tw = (...tokenKeys: string[]): Record<string, any> =>
  tokenKeys.reduce(
    (styles, token) => ({
      ...styles,
      ...(TOKENS[token] || {}),
    }),
    {}
  );

// derived styles
Object.assign(TOKENS, {
  'text-link': {
    ...tw('font-inter', 'font-medium', 'text-action-cta-default'),
    fontSize: '30px',
  },
  'text-body': {
    ...tw('font-inter', 'font-regular', 'text-primary'),
    fontSize: '40px',
  },
  'text-body-small': {
    ...tw('font-inter', 'font-medium', 'text-subdued'),
    fontSize: '32px',
  },
  'text-body-xsmall': {
    ...tw('font-inter', 'font-medium', 'text-subdued'),
    fontSize: '28px',
  },
  'text-h1': {
    ...tw('font-inter', 'font-black', 'text-primary'),
    fontSize: '70px',
  },
  'text-h2': {
    ...tw('font-inter', 'font-black', 'text-subdued'),
    fontSize: '60px',
  },
  'text-h3': {
    ...tw('font-inter', 'font-regular', 'text-subdued'),
    fontSize: '60px',
  },
  'text-h4': {
    ...tw('font-inter', 'font-semibold', 'text-primary'),
    fontSize: '40px',
    letterSpacing: 1.6,
  },
  'text-label': {
    ...tw('font-inter', 'font-semibold', 'text-primary'),
    fontSize: '40px',
  },
  'text-display': {
    ...tw('font-inter', 'font-black', 'text-action-cta-default'),
    fontSize: '80px',
  },
});

export const titleCase = (str: string): string => _.startCase(_.toLower(str));

export const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const percentage = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const decimal = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
