// Libraries
import { useEffect } from 'react';

// Components
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { principalInvestmentAvailableBalanceSheetPartnerHookUsed } from '@hum/icm-app/src/actions';

export const useAvailableBalanceSheetPartners = (companyId: number) => {
  const {
    state: { principalInvestmentAvailableBalanceSheetPartners },
    dispatch,
  } = useAppStore();

  useEffect(() => {
    dispatch(
      principalInvestmentAvailableBalanceSheetPartnerHookUsed({ companyId })
    );
  }, [companyId]);

  if (principalInvestmentAvailableBalanceSheetPartners.data) {
    const companies = principalInvestmentAvailableBalanceSheetPartners.data.map(
      ({ company }) => ({
        label: company.name,
        value: company.id,
      })
    );

    return {
      loaded: principalInvestmentAvailableBalanceSheetPartners.loaded,
      companies: companies,
    };
  }

  return {
    loaded: principalInvestmentAvailableBalanceSheetPartners.loaded,
    companies: [],
  };
};
