import { docusignEnvelopeHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';

export const useEnvelope = (companyId: number) => {
  const { state, dispatch } = useAppStore();
  useEffect(() => {
    dispatch(docusignEnvelopeHookUsed({ companyId }));
  }, [companyId]);

  return state.envelope;
};
