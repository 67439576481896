import { useEffect } from 'react';
import { PM_portfolioCompanyInviteUrlHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useFlags } from '../useFlags';
import { getPortfolioCompanyInviteUrl, useApiQuery } from '@hum/api';
import { Result } from '@hum/common';
import { PortfolioMonitoringCompanyInviteUrl } from '@hum/types';

export const usePortfolioCompanyInviteUrl = (companyId: number) => {
  const flags = useFlags();
  const { data: portfolioCompanyInviteUrl, isLoading } = useApiQuery(
    getPortfolioCompanyInviteUrl,
    {
      params: { companyId },
    }
  );
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(PM_portfolioCompanyInviteUrlHookUsed({ companyId }));
    }
  }, [companyId]);

  return flags.enabled('use-react-query')
    ? ({
        data: portfolioCompanyInviteUrl,
        loaded: !isLoading,
      } as Result<PortfolioMonitoringCompanyInviteUrl>)
    : state.portfolioCompanyInviteUrl;
};
