import { useEffect } from 'react';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { availableConnectorsHookUsed } from '@hum/icm-app/src/actions';

export const useAvailableConnectors = () => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    dispatch(availableConnectorsHookUsed());
  }, []);

  return state.availableConnectors;
};
