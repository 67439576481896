import { useSelector } from 'react-redux';

import { CompanyType } from '@hum/types';
import { AppState } from '@hum/icm-app/src/state';

export const useSession = () => {
  const { data: { roles } = { roles: [] as CompanyType[] } } = useSelector(
    (state: AppState) => state.session
  );

  const isPortfolioInvestor = roles.includes(CompanyType.Investor);
  const isSyndicationInvestor = roles.includes(CompanyType.SyndicationInvestor);
  const isBalanceSheetPartner = roles.includes(CompanyType.BalanceSheetPartner);
  const isCompany = roles.includes(CompanyType.Company);
  const isAdmin = roles.includes(CompanyType.Admin);

  return {
    isPortfolioInvestor,
    isSyndicationInvestor,
    isBalanceSheetPartner,
    isCompany,
    isAdmin,
  };
};
