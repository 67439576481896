import React from 'react';
import { Text, TextType } from '@hum/ui-library';
import { BenchmarkRow } from './BenchmarkRow';
import {
  IndustryBenchmarksProps,
  SCALE_OF_BUSINESS_VARIABLES,
  GROWTH_VARIABLES,
  MARGIN_IMPROVEMENT_VARIABLES,
  MARGIN_PROFILE_VARIABLES,
  BENCHMARKING_VARIABLE_NAMES,
} from '@hum/types';
import {
  currency,
  percentage,
} from '../../FinancialInsights/FinancialInsightsDocument/utils';

export const IndustryBenchmarks = ({
  companyName,
  industry,
  benchmarking,
}: IndustryBenchmarksProps) => {
  const { header, data, company } = benchmarking;

  const scaleOfBusinessChartData = {
    id: 'scaleOfBusiness',
    title: 'Scale of Business',
    population: SCALE_OF_BUSINESS_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: SCALE_OF_BUSINESS_VARIABLES,
      y: SCALE_OF_BUSINESS_VARIABLES.map(
        (v) => company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: SCALE_OF_BUSINESS_VARIABLES.filter(
      (v) =>
        typeof company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]] ===
        'number'
    ).map((v) => ({
      x: v,
      y: company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]],
      text: currency.format(
        company.scaleOfBusiness[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    })),
  };

  const growthChartData = {
    id: 'growth',
    title: 'Growth %',
    population: GROWTH_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: GROWTH_VARIABLES,
      y: GROWTH_VARIABLES.map(
        (v) => company.growth[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: GROWTH_VARIABLES.filter(
      (v) => typeof company.growth[BENCHMARKING_VARIABLE_NAMES[v]] === 'number'
    ).map((v) => ({
      x: v,
      y: company.growth[BENCHMARKING_VARIABLE_NAMES[v]],
      text: percentage.format(company.growth[BENCHMARKING_VARIABLE_NAMES[v]]),
    })),
  };

  const marginProfileChartData = {
    id: 'marginProfile',
    title: 'Margin Profile %',
    population: MARGIN_PROFILE_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: MARGIN_PROFILE_VARIABLES,
      y: MARGIN_PROFILE_VARIABLES.map(
        (v) => company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: MARGIN_PROFILE_VARIABLES.filter(
      (v) =>
        typeof company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]] ===
        'number'
    ).map((v) => ({
      x: v,
      y: company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]],
      text: percentage.format(
        company.marginProfile[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    })),
  };

  const marginImprovementChartData = {
    id: 'marginImprovement',
    title: 'Margin Improvement %',
    population: MARGIN_IMPROVEMENT_VARIABLES.map((variable) => ({
      name: variable,
      y: data[BENCHMARKING_VARIABLE_NAMES[variable]]?.distribution ?? [],
    })),
    sample: {
      name: companyName,
      x: MARGIN_IMPROVEMENT_VARIABLES,
      y: MARGIN_IMPROVEMENT_VARIABLES.map(
        (v) => company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    },
    annotations: MARGIN_IMPROVEMENT_VARIABLES.filter(
      (v) =>
        typeof company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]] ===
        'number'
    ).map((v) => ({
      x: v,
      y: company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]],
      text: percentage.format(
        company.marginImprovement[BENCHMARKING_VARIABLE_NAMES[v]]
      ),
    })),
  };

  return (
    <>
      <section className="p-4 md:py-8 container mx-auto">
        <div className="w-full md:w-2/3">
          <Text type={TextType.PAGE_HEADING} className="text-black mb-4">
            Industry Benchmarks
          </Text>
          <Text type={TextType.BODY_M} className="text-black mb-4">
            Your financial performance indicators are benchmarked against
            industry peers on Hum’s ICM over the same trailing-twelve-month(TTM)
            period.
          </Text>
          <Text type={TextType.HEADING_M} className="text-black mb-4">
            The following charts demonstrate how your business compares to{' '}
            {header.numberOfSimilarCompanies} peers in your industry, {industry}
            , on Hum’s ICM.
          </Text>
          <Text type={TextType.BODY_S} className="text-black">
            1. The percentile rank is determined by comparing your company to
            similar businesses (based on ZoomInfo Industry) in ICM using
            available data for the specific analysis date.
          </Text>
          <Text type={TextType.BODY_S} className="text-black mb-4">
            2. The percentile rank will only be calculated if there are more
            than 10 peers available for the given time period.
          </Text>
        </div>
      </section>
      {scaleOfBusinessChartData && (
        <BenchmarkRow
          title="Scale of Business"
          description="Gain valuable insights into how your profitability (revenue, pre-marketing EBITDA, EBITDA), as well as the scale of your operations and investments (inventory, receivables, PPE), compare to your industry peers."
          chart={scaleOfBusinessChartData}
        />
      )}
      {growthChartData && (
        <BenchmarkRow
          title="Growth %"
          description="This chart provides a view of your growth over (TTM / the past Quarter). It will help you to identify operational strengths and weaknesses. "
          chart={growthChartData}
        />
      )}
      {marginProfileChartData && (
        <BenchmarkRow
          title="Margin Profile"
          description="This compares the profitability of your business relative to peers. It provides a clear understanding of how efficiently your company operates compared the industry. Note: industry grouping does not consider the size of the business. "
          chart={marginProfileChartData}
        />
      )}
      {marginImprovementChartData && (
        <BenchmarkRow
          title="Margin Improvement"
          description="This chart compares your company’s ability to improve margins relative to your peers. It provides insights into your competitive position and highlights opportunities for enhancing profitability. "
          chart={marginImprovementChartData}
        />
      )}
    </>
  );
};
