import { useEffect } from 'react';
import { adminCompanyUsersHookUsed } from '@hum/icm-app/src/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@hum/icm-app/src/state';

// Return users for company with companyId
export const useCompanyUsers = (companyId: number) => {
  const companyUsers = useSelector((state: AppState) => state.companyUsers);
  const dispatch = useDispatch();
  ``;
  useEffect(() => {
    if (companyId) {
      dispatch(adminCompanyUsersHookUsed({ companyId }));
    }
  }, [companyId]);

  const refetch = () => dispatch(adminCompanyUsersHookUsed({ companyId }));

  return {
    ...companyUsers,
    refetch,
  };
};
