import { useEffect } from 'react';
import { chartsHookUsed } from '@hum/icm-app/src/actions';
import { Company } from '@hum/types';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

export const useCharts = (
  { id: companyId }: Pick<Company, 'id'>,
  tabName: string
) => {
  const { state, dispatch } = useAppStore();
  const companyAnalysisId = state.selectedCompanyAnalysisId;
  useEffect(() => {
    dispatch(chartsHookUsed({ companyId, tabName, companyAnalysisId }));
  }, [companyId, tabName, companyAnalysisId]);

  return state.charts;
};
