import { PM_invitedCompaniesUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';
import { useFlags } from '../useFlags';
import { getPortfolioInvites, useApiQuery } from '@hum/api';
import { Result } from '@hum/common';
import { PortfolioMonitoringInvite } from '@hum/types';

export const useInvestorInvites = (companyId: number) => {
  const flags = useFlags();
  const { data: investorPortfolioInvites } = useApiQuery(getPortfolioInvites, {
    params: { companyId },
  });
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(PM_invitedCompaniesUsed({ companyId }));
    }
  }, [companyId]);

  return flags.enabled('use-react-query')
    ? ({ data: investorPortfolioInvites } as Result<
        PortfolioMonitoringInvite[]
      >)
    : state.investorPortfolioInvites;
};
