import { PM_portfolioCompaniesHookUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useEffect } from 'react';

export const useGetPortfolio = (companyId: number) => {
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    dispatch(PM_portfolioCompaniesHookUsed({ companyId }));
  }, [companyId]);

  return state.portfolioCompanies;
};
